// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';

// const Profile = () => {
//   // **State Variables for Profile Fields**
//   const [profileData, setProfileData] = useState({
//     firstName: '',
//     lastName: '',
//     email: '',
//     mid: '',
//     salt: '',
//     settlement: '',
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '',
//     payinCallbackURL: '',
//   });

//   /**
//    * useEffect Hook
//    *
//    * This hook runs when the component mounts.
//    * Backend Developer: Replace the code inside this hook with an API call to fetch profile data.
//    */
//   useEffect(() => {
//     // TODO: Fetch profile data from the backend API and update the 'profileData' state.
//     // Example:
//     // fetch('/api/profile')
//     //   .then(response => response.json())
//     //   .then(data => {
//     //     setProfileData(data);
//     //   })
//     //   .catch(error => {
//     //     console.error('Error fetching profile data:', error);
//     //     // Handle error state if needed
//     //   });

//     // Simulate fetching data with placeholder values
//     const simulatedData = {
//       firstName: 'John',
//       lastName: 'Doe',
//       email: 'john.doe@example.com',
//       mid: 'MID123456',
//       salt: 'abc123salt',
//       settlement: 'T + 2 days',
//       payoutSecretKey: 'payout_secret_key',
//       payoutCallbackURL: 'https://example.com/payout-callback',
//       payinSecretKey: 'payin_secret_key',
//       payinCallbackURL: 'https://example.com/payin-callback',
//     };

//     setProfileData(simulatedData);
//   }, []);

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="firstName">First Name:</label>
//             <input
//               type="text"
//               id="firstName"
//               name="firstName"
//               value={profileData.firstName}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="lastName">Last Name:</label>
//             <input
//               type="text"
//               id="lastName"
//               name="lastName"
//               value={profileData.lastName}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input
//               type="email"
//               id="email"
//               name="email"
//               value={profileData.email}
//               readOnly
//             />
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input
//               type="text"
//               id="mid"
//               name="mid"
//               value={profileData.mid}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input
//               type="text"
//               id="salt"
//               name="salt"
//               value={profileData.salt}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="settlement">Settlement (T + n days):</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={profileData.settlement}
//               readOnly
//             />
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <input
//               type="text"
//               id="payinCallbackURL"
//               name="payinCallbackURL"
//               value={profileData.payinCallbackURL}
//               readOnly
//             />
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Profile;



// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';

// const Profile = () => {
//   // **State Variables for Profile Fields**
//   const [profileData, setProfileData] = useState({
//     name: '', // Full name of the user
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '', // This will store the settlement period value
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '', // PayIn Secret Key to be populated from login response
//     payinCallbackURL: '',
//   });

//   /**
//    * useEffect Hook
//    *
//    * This hook runs when the component mounts and fetches data from localStorage
//    * (which was set during the login process).
//    */
//   useEffect(() => {
//     // Retrieve profile data from localStorage
//     const storedProfileData = {
//       name: localStorage.getItem('merchantName') || '', // Full name from login response
//       email: localStorage.getItem('merchantEmail') || '',
//       mid: localStorage.getItem('merchantId') || '',
//       salt: localStorage.getItem('salt') || '', // Salt from login response
//       settlementPeriod: localStorage.getItem('settlement_period') || '', // Settlement period from login response
//       payoutSecretKey: localStorage.getItem('payoutSecretKey') || 'payout_secret_key',
//       payoutCallbackURL: localStorage.getItem('payoutCallbackURL') || 'https://example.com/payout-callback',
//       payinSecretKey: localStorage.getItem('payinSecretKey') || '', // PayIn Secret Key from login response
//       payinCallbackURL: localStorage.getItem('payinCallbackURL') || 'https://example.com/payin-callback',
//     };

//     setProfileData(storedProfileData);
//   }, []);

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input
//               type="text"
//               id="name"
//               name="name"
//               value={profileData.name}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input
//               type="email"
//               id="email"
//               name="email"
//               value={profileData.email}
//               readOnly
//             />
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input
//               type="text"
//               id="mid"
//               name="mid"
//               value={profileData.mid}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input
//               type="text"
//               id="salt"
//               name="salt"
//               value={profileData.salt}
//               readOnly
//             />
//           </div>

//           {/* Updated Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={profileData.settlementPeriod ? `T + ${profileData.settlementPeriod} days` : 'N/A'} // Correctly format settlement period
//               readOnly
//             />
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <input
//               type="text"
//               id="payinCallbackURL"
//               name="payinCallbackURL"
//               value={profileData.payinCallbackURL}
//               readOnly
//             />
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Profile;



// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';

// const Profile = () => {
//   // **State Variables for Profile Fields**
//   const [profileData, setProfileData] = useState({
//     name: '', // Full name of the user
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '', // This will store the settlement period value
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '', // PayIn Secret Key to be populated from login response
//     payinCallbackURL: '',
//   });

//   /**
//    * useEffect Hook
//    *
//    * This hook runs when the component mounts and fetches data from localStorage
//    * (which was set during the login process).
//    */
//   useEffect(() => {
//     // Retrieve profile data from localStorage
//     const settlementPeriod = localStorage.getItem('settlement_period');  // Fetch settlement period
//     const storedProfileData = {
//       name: localStorage.getItem('merchantName') || '', // Full name from login response
//       email: localStorage.getItem('merchantEmail') || '',
//       mid: localStorage.getItem('merchantId') || '',
//       salt: localStorage.getItem('salt') || '', // Salt from login response
//       settlementPeriod: settlementPeriod || '', // Retrieve settlement period
//       payoutSecretKey: localStorage.getItem('payoutSecretKey') || 'payout_secret_key',
//       payoutCallbackURL: localStorage.getItem('payoutCallbackURL') || 'https://example.com/payout-callback',
//       payinSecretKey: localStorage.getItem('payinSecretKey') || '', // PayIn Secret Key from login response
//       payinCallbackURL: localStorage.getItem('payinCallbackURL') || 'https://example.com/payin-callback',
//     };

//     setProfileData(storedProfileData);
//   }, []);

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input
//               type="text"
//               id="name"
//               name="name"
//               value={profileData.name}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input
//               type="email"
//               id="email"
//               name="email"
//               value={profileData.email}
//               readOnly
//             />
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input
//               type="text"
//               id="mid"
//               name="mid"
//               value={profileData.mid}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input
//               type="text"
//               id="salt"
//               name="salt"
//               value={profileData.salt}
//               readOnly
//             />
//           </div>

//           {/* Updated Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={profileData.settlementPeriod ? `T + ${profileData.settlementPeriod} days` : 'N/A'}  // Display settlement period
//               readOnly
//             />
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <input
//               type="text"
//               id="payinCallbackURL"
//               name="payinCallbackURL"
//               value={profileData.payinCallbackURL}
//               readOnly
//             />
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Profile;



// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa'; // Importing icons

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '', // Full name of the user
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '', // This will store the settlement period value
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '', // PayIn Secret Key to be populated from login response
//     payinCallbackURL: '',
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // useEffect Hook to fetch data from localStorage
//   useEffect(() => {
//     // Retrieve profile data from localStorage
//     const settlementPeriod = localStorage.getItem('settlement_period'); // Fetch settlement period
//     const storedProfileData = {
//       name: localStorage.getItem('merchantName') || '', // Full name from login response
//       email: localStorage.getItem('merchantEmail') || '',
//       mid: localStorage.getItem('merchantId') || '',
//       salt: localStorage.getItem('salt') || '', // Salt from login response
//       settlementPeriod: settlementPeriod || '', // Retrieve settlement period
//       payoutSecretKey: localStorage.getItem('payoutSecretKey') || 'payout_secret_key',
//       payoutCallbackURL: localStorage.getItem('payoutCallbackURL') || 'https://example.com/payout-callback',
//       payinSecretKey: localStorage.getItem('payinSecretKey') || '', // PayIn Secret Key from login response
//       payinCallbackURL: localStorage.getItem('payinCallbackURL') || 'https://example.com/payin-callback',
//     };

//     setProfileData(storedProfileData);
//   }, []);

//   // Function to handle clicking the edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the modal
//   const handleCloseModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(`/change-password/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           old_password: oldPassword,
//           new_password: newPassword,
//         }),
//       });

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleCloseModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod ? `T + ${profileData.settlementPeriod} days` : 'N/A'
//               } // Display settlement period
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <input
//               type="text"
//               id="payinCallbackURL"
//               name="payinCallbackURL"
//               value={profileData.payinCallbackURL}
//               readOnly
//             />
//           </div>
//         </form>
//       </div>

//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;

// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa'; // Importing icons

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '', // Full name of the user
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '', // This will store the settlement period value
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '', // PayIn Secret Key to be populated from login response
//     payinCallbackURL: '',
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // State variables for PayIn Callback URL modal
//   const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
//   const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
//   const [payinCallbackError, setPayinCallbackError] = useState('');

//   // useEffect Hook to fetch data from localStorage
//   useEffect(() => {
//     // Retrieve profile data from localStorage
//     const settlementPeriod = localStorage.getItem('settlement_period'); // Fetch settlement period
//     const storedProfileData = {
//       name: localStorage.getItem('merchantName') || '', // Full name from login response
//       email: localStorage.getItem('merchantEmail') || '',
//       mid: localStorage.getItem('merchantId') || '',
//       salt: localStorage.getItem('salt') || '', // Salt from login response
//       settlementPeriod: settlementPeriod || '', // Retrieve settlement period
//       payoutSecretKey: localStorage.getItem('payoutSecretKey') || 'payout_secret_key',
//       payoutCallbackURL: localStorage.getItem('payoutCallbackURL') || 'https://example.com/payout-callback',
//       payinSecretKey: localStorage.getItem('payinSecretKey') || '', // PayIn Secret Key from login response
//       payinCallbackURL: localStorage.getItem('payinCallbackURL') || 'https://example.com/payin-callback',
//     };

//     setProfileData(storedProfileData);
//   }, []);

//   // Function to handle clicking the password edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the password modal
//   const handleClosePasswordModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(`https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           old_password: oldPassword,
//           new_password: newPassword,
//         }),
//       });

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleClosePasswordModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   // Function to handle clicking the PayIn Callback URL edit icon
//   const handlePayinCallbackEditClick = () => {
//     setShowPayinCallbackModal(true);
//     setNewPayinCallbackURL(profileData.payinCallbackURL); // Initialize with current value
//   };

//   // Function to handle closing the PayIn Callback URL modal
//   const handleClosePayinCallbackModal = () => {
//     setShowPayinCallbackModal(false);
//     setNewPayinCallbackURL('');
//     setPayinCallbackError('');
//   };

//   // Function to submit the PayIn Callback URL change
//   const handleSubmitPayinCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-payin-callback-url/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payin_callback_url: newPayinCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('PayIn Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payinCallbackURL: newPayinCallbackURL,
//         }));
//         localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
//         handleClosePayinCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
//       }
//     } catch (error) {
//       setPayinCallbackError('An error occurred');
//     }
//   };

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod ? `T + ${profileData.settlementPeriod} days` : 'N/A'
//               } // Display settlement period
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group callback-url-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payinCallbackURL"
//                 name="payinCallbackURL"
//                 value={profileData.payinCallbackURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
//             </div>
//           </div>
//         </form>
//       </div>

//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}

//       {/* PayIn Callback URL Edit Modal */}
//       {showPayinCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
//             <h3>Edit PayIn Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayinCallbackURL"
//                 value={newPayinCallbackURL}
//                 onChange={(e) => setNewPayinCallbackURL(e.target.value)}
//               />
//             </div>
//             {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;



// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa'; // Importing icons

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '', // Full name of the user
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '', // This will store the settlement period value
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '', // PayIn Secret Key to be populated from login response
//     payinCallbackURL: '',
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // State variables for PayIn Callback URL modal
//   const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
//   const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
//   const [payinCallbackError, setPayinCallbackError] = useState('');

//   // useEffect Hook to fetch data from localStorage
//   useEffect(() => {
//     // Retrieve profile data from localStorage
//     const settlementPeriod = localStorage.getItem('settlement_period'); // Fetch settlement period
//     const storedProfileData = {
//       name: localStorage.getItem('merchantName') || '', // Full name from login response
//       email: localStorage.getItem('merchantEmail') || '',
//       mid: localStorage.getItem('merchantId') || '',
//       salt: localStorage.getItem('salt') || '', // Salt from login response
//       settlementPeriod: settlementPeriod !== null ? settlementPeriod : '', // Retrieve settlement period
//       payoutSecretKey: localStorage.getItem('payoutSecretKey') || 'payout_secret_key',
//       payoutCallbackURL: localStorage.getItem('payoutCallbackURL') || 'https://example.com/payout-callback',
//       payinSecretKey: localStorage.getItem('payinSecretKey') || '', // PayIn Secret Key from login response
//       payinCallbackURL: localStorage.getItem('payinCallbackURL') || 'https://example.com/payin-callback',
//     };

//     setProfileData(storedProfileData);
//   }, []);

//   // Function to handle clicking the password edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the password modal
//   const handleClosePasswordModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(`https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           old_password: oldPassword,
//           new_password: newPassword,
//         }),
//       });

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleClosePasswordModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   // Function to handle clicking the PayIn Callback URL edit icon
//   const handlePayinCallbackEditClick = () => {
//     setShowPayinCallbackModal(true);
//     setNewPayinCallbackURL(profileData.payinCallbackURL); // Initialize with current value
//   };

//   // Function to handle closing the PayIn Callback URL modal
//   const handleClosePayinCallbackModal = () => {
//     setShowPayinCallbackModal(false);
//     setNewPayinCallbackURL('');
//     setPayinCallbackError('');
//   };

//   // Function to submit the PayIn Callback URL change
//   const handleSubmitPayinCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-payin-callback-url/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payin_callback_url: newPayinCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('PayIn Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payinCallbackURL: newPayinCallbackURL,
//         }));
//         localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
//         handleClosePayinCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
//       }
//     } catch (error) {
//       setPayinCallbackError('An error occurred');
//     }
//   };

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod !== '' ? `T + ${profileData.settlementPeriod} days` : 'N/A'
//               } // Display settlement period
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group callback-url-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payinCallbackURL"
//                 name="payinCallbackURL"
//                 value={profileData.payinCallbackURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
//             </div>
//           </div>
//         </form>
//       </div>

//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}

//       {/* PayIn Callback URL Edit Modal */}
//       {showPayinCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
//             <h3>Edit PayIn Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayinCallbackURL"
//                 value={newPayinCallbackURL}
//                 onChange={(e) => setNewPayinCallbackURL(e.target.value)}
//               />
//             </div>
//             {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;



// File: src/components/Profile.jsx

// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa'; // Importing icons

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '', // Full name of the user
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '', // This will store the settlement period value
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '', // PayIn Secret Key to be populated from login response
//     payinCallbackURL: '',
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // State variables for PayIn Callback URL modal
//   const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
//   const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
//   const [payinCallbackError, setPayinCallbackError] = useState('');

//   // useEffect Hook to fetch data from localStorage
//   useEffect(() => {
//     // Retrieve profile data from localStorage
//     const settlementPeriod = localStorage.getItem('settlement_period'); // Fetch settlement period
//     const storedProfileData = {
//       name: localStorage.getItem('merchantName') || '', // Full name from login response
//       email: localStorage.getItem('merchantEmail') || '',
//       mid: localStorage.getItem('merchantId') || '',
//       salt: localStorage.getItem('salt') || '', // Salt from login response
//       settlementPeriod: settlementPeriod !== null ? settlementPeriod : '', // Retrieve settlement period
//       payoutSecretKey: localStorage.getItem('payoutSecretKey') || 'payout_secret_key',
//       payoutCallbackURL: localStorage.getItem('payoutCallbackURL') || 'https://example.com/payout-callback',
//       payinSecretKey: localStorage.getItem('payinSecretKey') || '', // PayIn Secret Key from login response
//       payinCallbackURL: localStorage.getItem('payinCallbackURL') || 'https://example.com/payin-callback',
//     };

//     setProfileData(storedProfileData);
//   }, []);

//   // Function to handle clicking the password edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the password modal
//   const handleClosePasswordModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(`https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           old_password: oldPassword,
//           new_password: newPassword,
//         }),
//       });

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleClosePasswordModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   // Function to handle clicking the PayIn Callback URL edit icon
//   const handlePayinCallbackEditClick = () => {
//     setShowPayinCallbackModal(true);
//     setNewPayinCallbackURL(profileData.payinCallbackURL); // Initialize with current value
//   };

//   // Function to handle closing the PayIn Callback URL modal
//   const handleClosePayinCallbackModal = () => {
//     setShowPayinCallbackModal(false);
//     setNewPayinCallbackURL('');
//     setPayinCallbackError('');
//   };

//   // Function to submit the PayIn Callback URL change
//   const handleSubmitPayinCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-payin-callback-url/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payin_callback_url: newPayinCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('PayIn Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payinCallbackURL: newPayinCallbackURL,
//         }));
//         localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
//         handleClosePayinCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
//       }
//     } catch (error) {
//       setPayinCallbackError('An error occurred');
//     }
//   };

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod !== '' ? `T + ${profileData.settlementPeriod} days` : 'N/A'
//               } // Display settlement period
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group callback-url-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payinCallbackURL"
//                 name="payinCallbackURL"
//                 value={profileData.payinCallbackURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
//             </div>
//           </div>
//         </form>
//       </div>

//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}

//       {/* PayIn Callback URL Edit Modal */}
//       {showPayinCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
//             <h3>Edit PayIn Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayinCallbackURL"
//                 value={newPayinCallbackURL}
//                 onChange={(e) => setNewPayinCallbackURL(e.target.value)}
//               />
//             </div>
//             {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;



// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa'; // Importing icons

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '', // Full name of the user
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '', // This will store the settlement period value
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '', // PayIn Secret Key to be populated from login response
//     payinCallbackURL: '',
//     successRedirectURL: '', // Added for success redirection URL
//     failureRedirectURL: '', // Added for failure redirection URL
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // State variables for PayIn Callback URL modal
//   const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
//   const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
//   const [payinCallbackError, setPayinCallbackError] = useState('');

//   // State variables for Success Redirect URL modal
//   const [showSuccessRedirectModal, setShowSuccessRedirectModal] = useState(false);
//   const [newSuccessRedirectURL, setNewSuccessRedirectURL] = useState('');
//   const [successRedirectError, setSuccessRedirectError] = useState('');

//   // State variables for Failure Redirect URL modal
//   const [showFailureRedirectModal, setShowFailureRedirectModal] = useState(false);
//   const [newFailureRedirectURL, setNewFailureRedirectURL] = useState('');
//   const [failureRedirectError, setFailureRedirectError] = useState('');

//   // useEffect Hook to fetch data from localStorage
//   useEffect(() => {
//     // Retrieve profile data from localStorage
//     const settlementPeriod = localStorage.getItem('settlement_period'); // Fetch settlement period
//     const storedProfileData = {
//       name: localStorage.getItem('merchantName') || '', // Full name from login response
//       email: localStorage.getItem('merchantEmail') || '',
//       mid: localStorage.getItem('merchantId') || '',
//       salt: localStorage.getItem('salt') || '', // Salt from login response
//       settlementPeriod: settlementPeriod !== null ? settlementPeriod : '', // Retrieve settlement period
//       payoutSecretKey: localStorage.getItem('payoutSecretKey') || 'payout_secret_key',
//       payoutCallbackURL: localStorage.getItem('payoutCallbackURL') || 'https://example.com/payout-callback',
//       payinSecretKey: localStorage.getItem('payinSecretKey') || '', // PayIn Secret Key from login response
//       payinCallbackURL: localStorage.getItem('payinCallbackURL') || 'https://example.com/payin-callback',
//       successRedirectURL: localStorage.getItem('successRedirectURL') || '', // Added
//       failureRedirectURL: localStorage.getItem('failureRedirectURL') || '', // Added
//     };

//     setProfileData(storedProfileData);
//   }, []);

//   // Function to handle clicking the password edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the password modal
//   const handleClosePasswordModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(`https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           old_password: oldPassword,
//           new_password: newPassword,
//         }),
//       });

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleClosePasswordModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   // Function to handle clicking the PayIn Callback URL edit icon
//   const handlePayinCallbackEditClick = () => {
//     setShowPayinCallbackModal(true);
//     setNewPayinCallbackURL(profileData.payinCallbackURL); // Initialize with current value
//   };

//   // Function to handle closing the PayIn Callback URL modal
//   const handleClosePayinCallbackModal = () => {
//     setShowPayinCallbackModal(false);
//     setNewPayinCallbackURL('');
//     setPayinCallbackError('');
//   };

//   // Function to submit the PayIn Callback URL change
//   const handleSubmitPayinCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payin_callback_url: newPayinCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('PayIn Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payinCallbackURL: newPayinCallbackURL,
//         }));
//         localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
//         handleClosePayinCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
//       }
//     } catch (error) {
//       setPayinCallbackError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Success Redirect URL edit icon
//   const handleSuccessRedirectEditClick = () => {
//     setShowSuccessRedirectModal(true);
//     setNewSuccessRedirectURL(profileData.successRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Success Redirect URL modal
//   const handleCloseSuccessRedirectModal = () => {
//     setShowSuccessRedirectModal(false);
//     setNewSuccessRedirectURL('');
//     setSuccessRedirectError('');
//   };

//   // Function to submit the Success Redirect URL change
//   const handleSubmitSuccessRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           success_redirect_url: newSuccessRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Success Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           successRedirectURL: newSuccessRedirectURL,
//         }));
//         localStorage.setItem('successRedirectURL', newSuccessRedirectURL);
//         handleCloseSuccessRedirectModal();
//       } else {
//         const result = await response.json();
//         setSuccessRedirectError(result.message || 'Failed to update Success Redirect URL');
//       }
//     } catch (error) {
//       setSuccessRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Failure Redirect URL edit icon
//   const handleFailureRedirectEditClick = () => {
//     setShowFailureRedirectModal(true);
//     setNewFailureRedirectURL(profileData.failureRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Failure Redirect URL modal
//   const handleCloseFailureRedirectModal = () => {
//     setShowFailureRedirectModal(false);
//     setNewFailureRedirectURL('');
//     setFailureRedirectError('');
//   };

//   // Function to submit the Failure Redirect URL change
//   const handleSubmitFailureRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           failure_redirect_url: newFailureRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Failure Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           failureRedirectURL: newFailureRedirectURL,
//         }));
//         localStorage.setItem('failureRedirectURL', newFailureRedirectURL);
//         handleCloseFailureRedirectModal();
//       } else {
//         const result = await response.json();
//         setFailureRedirectError(result.message || 'Failed to update Failure Redirect URL');
//       }
//     } catch (error) {
//       setFailureRedirectError('An error occurred');
//     }
//   };

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod !== '' ? `T + ${profileData.settlementPeriod} days` : 'N/A'
//               } // Display settlement period
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group callback-url-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payinCallbackURL"
//                 name="payinCallbackURL"
//                 value={profileData.payinCallbackURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
//             </div>
//           </div>

//           {/* Success Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="successRedirectURL">Success Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="successRedirectURL"
//                 name="successRedirectURL"
//                 value={profileData.successRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleSuccessRedirectEditClick} />
//             </div>
//           </div>

//           {/* Failure Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="failureRedirectURL">Failure Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="failureRedirectURL"
//                 name="failureRedirectURL"
//                 value={profileData.failureRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleFailureRedirectEditClick} />
//             </div>
//           </div>
//         </form>
//       </div>

//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}

//       {/* PayIn Callback URL Edit Modal */}
//       {showPayinCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
//             <h3>Edit PayIn Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayinCallbackURL"
//                 value={newPayinCallbackURL}
//                 onChange={(e) => setNewPayinCallbackURL(e.target.value)}
//               />
//             </div>
//             {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Success Redirect URL Edit Modal */}
//       {showSuccessRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseSuccessRedirectModal} />
//             <h3>Edit Success Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newSuccessRedirectURL">New Success Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newSuccessRedirectURL"
//                 value={newSuccessRedirectURL}
//                 onChange={(e) => setNewSuccessRedirectURL(e.target.value)}
//               />
//             </div>
//             {successRedirectError && <p className="error-message">{successRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitSuccessRedirectChange}>
//               Update Success Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Failure Redirect URL Edit Modal */}
//       {showFailureRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseFailureRedirectModal} />
//             <h3>Edit Failure Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newFailureRedirectURL">New Failure Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newFailureRedirectURL"
//                 value={newFailureRedirectURL}
//                 onChange={(e) => setNewFailureRedirectURL(e.target.value)}
//               />
//             </div>
//             {failureRedirectError && <p className="error-message">{failureRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitFailureRedirectChange}>
//               Update Failure Redirect URL
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;



// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa'; // Importing icons

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '', // Full name of the user
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '', // This will store the settlement period value
//     refundFee: '',         // Added refundFee state variable
//     chargebackFee: '',     // Added chargebackFee state variable
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '', // PayIn Secret Key to be populated from login response
//     payinCallbackURL: '',
//     successRedirectURL: '', // Added for success redirection URL
//     failureRedirectURL: '', // Added for failure redirection URL
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // State variables for PayIn Callback URL modal
//   const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
//   const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
//   const [payinCallbackError, setPayinCallbackError] = useState('');

//   // State variables for Success Redirect URL modal
//   const [showSuccessRedirectModal, setShowSuccessRedirectModal] = useState(false);
//   const [newSuccessRedirectURL, setNewSuccessRedirectURL] = useState('');
//   const [successRedirectError, setSuccessRedirectError] = useState('');

//   // State variables for Failure Redirect URL modal
//   const [showFailureRedirectModal, setShowFailureRedirectModal] = useState(false);
//   const [newFailureRedirectURL, setNewFailureRedirectURL] = useState('');
//   const [failureRedirectError, setFailureRedirectError] = useState('');

//   // useEffect Hook to fetch data from localStorage
//   useEffect(() => {
//     // Retrieve profile data from localStorage
//     const settlementPeriod = localStorage.getItem('settlement_period'); // Fetch settlement period
//     const storedProfileData = {
//       name: localStorage.getItem('merchantName') || '', // Full name from login response
//       email: localStorage.getItem('merchantEmail') || '',
//       mid: localStorage.getItem('merchantId') || '',
//       salt: localStorage.getItem('salt') || '', // Salt from login response
//       settlementPeriod: settlementPeriod !== null ? settlementPeriod : '', // Retrieve settlement period
//       refundFee: localStorage.getItem('refundFee') || '', // Fetch refundFee from localStorage
//       chargebackFee: localStorage.getItem('chargebackFee') || '', // Fetch chargebackFee from localStorage
//       payoutSecretKey: localStorage.getItem('payoutSecretKey') || 'payout_secret_key',
//       payoutCallbackURL: localStorage.getItem('payoutCallbackURL') || 'https://example.com/payout-callback',
//       payinSecretKey: localStorage.getItem('payinSecretKey') || '', // PayIn Secret Key from login response
//       payinCallbackURL: localStorage.getItem('payinCallbackURL') || 'https://example.com/payin-callback',
//       successRedirectURL: localStorage.getItem('successRedirectURL') || '', // Added
//       failureRedirectURL: localStorage.getItem('failureRedirectURL') || '', // Added
//     };

//     setProfileData(storedProfileData);
//   }, []);

//   // Function to handle clicking the password edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the password modal
//   const handleClosePasswordModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(`https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           old_password: oldPassword,
//           new_password: newPassword,
//         }),
//       });

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleClosePasswordModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   // Function to handle clicking the PayIn Callback URL edit icon
//   const handlePayinCallbackEditClick = () => {
//     setShowPayinCallbackModal(true);
//     setNewPayinCallbackURL(profileData.payinCallbackURL); // Initialize with current value
//   };

//   // Function to handle closing the PayIn Callback URL modal
//   const handleClosePayinCallbackModal = () => {
//     setShowPayinCallbackModal(false);
//     setNewPayinCallbackURL('');
//     setPayinCallbackError('');
//   };

//   // Function to submit the PayIn Callback URL change
//   const handleSubmitPayinCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payin_callback_url: newPayinCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('PayIn Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payinCallbackURL: newPayinCallbackURL,
//         }));
//         localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
//         handleClosePayinCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
//       }
//     } catch (error) {
//       setPayinCallbackError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Success Redirect URL edit icon
//   const handleSuccessRedirectEditClick = () => {
//     setShowSuccessRedirectModal(true);
//     setNewSuccessRedirectURL(profileData.successRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Success Redirect URL modal
//   const handleCloseSuccessRedirectModal = () => {
//     setShowSuccessRedirectModal(false);
//     setNewSuccessRedirectURL('');
//     setSuccessRedirectError('');
//   };

//   // Function to submit the Success Redirect URL change
//   const handleSubmitSuccessRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           success_redirect_url: newSuccessRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Success Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           successRedirectURL: newSuccessRedirectURL,
//         }));
//         localStorage.setItem('successRedirectURL', newSuccessRedirectURL);
//         handleCloseSuccessRedirectModal();
//       } else {
//         const result = await response.json();
//         setSuccessRedirectError(result.message || 'Failed to update Success Redirect URL');
//       }
//     } catch (error) {
//       setSuccessRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Failure Redirect URL edit icon
//   const handleFailureRedirectEditClick = () => {
//     setShowFailureRedirectModal(true);
//     setNewFailureRedirectURL(profileData.failureRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Failure Redirect URL modal
//   const handleCloseFailureRedirectModal = () => {
//     setShowFailureRedirectModal(false);
//     setNewFailureRedirectURL('');
//     setFailureRedirectError('');
//   };

//   // Function to submit the Failure Redirect URL change
//   const handleSubmitFailureRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           failure_redirect_url: newFailureRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Failure Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           failureRedirectURL: newFailureRedirectURL,
//         }));
//         localStorage.setItem('failureRedirectURL', newFailureRedirectURL);
//         handleCloseFailureRedirectModal();
//       } else {
//         const result = await response.json();
//         setFailureRedirectError(result.message || 'Failed to update Failure Redirect URL');
//       }
//     } catch (error) {
//       setFailureRedirectError('An error occurred');
//     }
//   };

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod !== '' ? `T + ${profileData.settlementPeriod} days` : 'N/A'
//               } // Display settlement period
//               readOnly
//             />
//           </div>

//           {/* Refund Fee Field */}
//           <div className="form-group">
//             <label htmlFor="refundFee">Refund Fee:</label>
//             <input
//               type="text"
//               id="refundFee"
//               name="refundFee"
//               value={
//                 profileData.refundFee !== '' ? `${profileData.refundFee}` : 'N/A'
//               }
//               readOnly
//             />
//           </div>

//           {/* Chargeback Fee Field */}
//           <div className="form-group">
//             <label htmlFor="chargebackFee">Chargeback Fee:</label>
//             <input
//               type="text"
//               id="chargebackFee"
//               name="chargebackFee"
//               value={
//                 profileData.chargebackFee !== '' ? `${profileData.chargebackFee}` : 'N/A'
//               }
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group callback-url-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payinCallbackURL"
//                 name="payinCallbackURL"
//                 value={profileData.payinCallbackURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
//             </div>
//           </div>

//           {/* Success Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="successRedirectURL">Success Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="successRedirectURL"
//                 name="successRedirectURL"
//                 value={profileData.successRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleSuccessRedirectEditClick} />
//             </div>
//           </div>

//           {/* Failure Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="failureRedirectURL">Failure Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="failureRedirectURL"
//                 name="failureRedirectURL"
//                 value={profileData.failureRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleFailureRedirectEditClick} />
//             </div>
//           </div>
//         </form>
//       </div>

//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}

//       {/* PayIn Callback URL Edit Modal */}
//       {showPayinCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
//             <h3>Edit PayIn Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayinCallbackURL"
//                 value={newPayinCallbackURL}
//                 onChange={(e) => setNewPayinCallbackURL(e.target.value)}
//               />
//             </div>
//             {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Success Redirect URL Edit Modal */}
//       {showSuccessRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseSuccessRedirectModal} />
//             <h3>Edit Success Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newSuccessRedirectURL">New Success Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newSuccessRedirectURL"
//                 value={newSuccessRedirectURL}
//                 onChange={(e) => setNewSuccessRedirectURL(e.target.value)}
//               />
//             </div>
//             {successRedirectError && <p className="error-message">{successRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitSuccessRedirectChange}>
//               Update Success Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Failure Redirect URL Edit Modal */}
//       {showFailureRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseFailureRedirectModal} />
//             <h3>Edit Failure Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newFailureRedirectURL">New Failure Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newFailureRedirectURL"
//                 value={newFailureRedirectURL}
//                 onChange={(e) => setNewFailureRedirectURL(e.target.value)}
//               />
//             </div>
//             {failureRedirectError && <p className="error-message">{failureRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitFailureRedirectChange}>
//               Update Failure Redirect URL
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;







// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa';

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '',
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '',
//     refundFee: '',
//     chargebackFee: '',
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '',
//     payinCallbackURL: '',
//     successRedirectURL: '',
//     failureRedirectURL: '',
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // State variables for PayIn Callback URL modal
//   const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
//   const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
//   const [payinCallbackError, setPayinCallbackError] = useState('');

//   // State variables for Success Redirect URL modal
//   const [showSuccessRedirectModal, setShowSuccessRedirectModal] = useState(false);
//   const [newSuccessRedirectURL, setNewSuccessRedirectURL] = useState('');
//   const [successRedirectError, setSuccessRedirectError] = useState('');

//   // State variables for Failure Redirect URL modal
//   const [showFailureRedirectModal, setShowFailureRedirectModal] = useState(false);
//   const [newFailureRedirectURL, setNewFailureRedirectURL] = useState('');
//   const [failureRedirectError, setFailureRedirectError] = useState('');

//   // Fetch profile data from the server
//   useEffect(() => {
//     const fetchProfileData = async () => {
//       try {
//         const mid = localStorage.getItem('merchantId');
//         const response = await fetch(`https://payin.payinfy.com/api/merchant-profile/${mid}/`);
//         if (response.ok) {
//           const result = await response.json();
//           const settlementPeriod = result.settlement_period;
//           const updatedProfileData = {
//             name: result.merchant_name || '',
//             email: result.merchant_email || '',
//             mid: result.merchant_id || '',
//             salt: localStorage.getItem('salt') || '',
//             settlementPeriod: settlementPeriod !== null ? settlementPeriod : '',
//             refundFee: result.refund_fee || '',
//             chargebackFee: result.chargeback_fee || '',
//             payoutSecretKey: result.payout_secret_key || '',
//             payoutCallbackURL: result.payout_callback_url || '',
//             payinSecretKey: result.payin_secret_key || '',
//             payinCallbackURL: result.payin_callback_url || '',
//             successRedirectURL: result.success_redirect_url || '',
//             failureRedirectURL: result.failure_redirect_url || '',
//           };
//           setProfileData(updatedProfileData);
//           // Update localStorage
//           for (const [key, value] of Object.entries(updatedProfileData)) {
//             localStorage.setItem(key, value);
//           }
//         } else {
//           console.error('Failed to fetch profile data');
//         }
//       } catch (error) {
//         console.error('Error fetching profile data:', error);
//       }
//     };

//     fetchProfileData();
//   }, []);

//   // Function to handle clicking the password edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the password modal
//   const handleClosePasswordModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(`https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           old_password: oldPassword,
//           new_password: newPassword,
//         }),
//       });

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleClosePasswordModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   // Function to handle clicking the PayIn Callback URL edit icon
//   const handlePayinCallbackEditClick = () => {
//     setShowPayinCallbackModal(true);
//     setNewPayinCallbackURL(profileData.payinCallbackURL); // Initialize with current value
//   };

//   // Function to handle closing the PayIn Callback URL modal
//   const handleClosePayinCallbackModal = () => {
//     setShowPayinCallbackModal(false);
//     setNewPayinCallbackURL('');
//     setPayinCallbackError('');
//   };

//   // Function to submit the PayIn Callback URL change
//   const handleSubmitPayinCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payin_callback_url: newPayinCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('PayIn Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payinCallbackURL: newPayinCallbackURL,
//         }));
//         localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
//         handleClosePayinCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
//       }
//     } catch (error) {
//       setPayinCallbackError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Success Redirect URL edit icon
//   const handleSuccessRedirectEditClick = () => {
//     setShowSuccessRedirectModal(true);
//     setNewSuccessRedirectURL(profileData.successRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Success Redirect URL modal
//   const handleCloseSuccessRedirectModal = () => {
//     setShowSuccessRedirectModal(false);
//     setNewSuccessRedirectURL('');
//     setSuccessRedirectError('');
//   };

//   // Function to submit the Success Redirect URL change
//   const handleSubmitSuccessRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           success_redirect_url: newSuccessRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Success Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           successRedirectURL: newSuccessRedirectURL,
//         }));
//         localStorage.setItem('successRedirectURL', newSuccessRedirectURL);
//         handleCloseSuccessRedirectModal();
//       } else {
//         const result = await response.json();
//         setSuccessRedirectError(result.message || 'Failed to update Success Redirect URL');
//       }
//     } catch (error) {
//       setSuccessRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Failure Redirect URL edit icon
//   const handleFailureRedirectEditClick = () => {
//     setShowFailureRedirectModal(true);
//     setNewFailureRedirectURL(profileData.failureRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Failure Redirect URL modal
//   const handleCloseFailureRedirectModal = () => {
//     setShowFailureRedirectModal(false);
//     setNewFailureRedirectURL('');
//     setFailureRedirectError('');
//   };

//   // Function to submit the Failure Redirect URL change
//   const handleSubmitFailureRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           failure_redirect_url: newFailureRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Failure Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           failureRedirectURL: newFailureRedirectURL,
//         }));
//         localStorage.setItem('failureRedirectURL', newFailureRedirectURL);
//         handleCloseFailureRedirectModal();
//       } else {
//         const result = await response.json();
//         setFailureRedirectError(result.message || 'Failed to update Failure Redirect URL');
//       }
//     } catch (error) {
//       setFailureRedirectError('An error occurred');
//     }
//   };

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod !== '' ? `T + ${profileData.settlementPeriod} days` : 'N/A'
//               }
//               readOnly
//             />
//           </div>

//           {/* Refund Fee Field */}
//           <div className="form-group">
//             <label htmlFor="refundFee">Refund Fee:</label>
//             <input
//               type="text"
//               id="refundFee"
//               name="refundFee"
//               value={
//                 profileData.refundFee !== '' ? `${profileData.refundFee}` : 'N/A'
//               }
//               readOnly
//             />
//           </div>

//           {/* Chargeback Fee Field */}
//           <div className="form-group">
//             <label htmlFor="chargebackFee">Chargeback Fee:</label>
//             <input
//               type="text"
//               id="chargebackFee"
//               name="chargebackFee"
//               value={
//                 profileData.chargebackFee !== '' ? `${profileData.chargebackFee}` : 'N/A'
//               }
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group callback-url-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payinCallbackURL"
//                 name="payinCallbackURL"
//                 value={profileData.payinCallbackURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
//             </div>
//           </div>

//           {/* Success Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="successRedirectURL">Success Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="successRedirectURL"
//                 name="successRedirectURL"
//                 value={profileData.successRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleSuccessRedirectEditClick} />
//             </div>
//           </div>

//           {/* Failure Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="failureRedirectURL">Failure Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="failureRedirectURL"
//                 name="failureRedirectURL"
//                 value={profileData.failureRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleFailureRedirectEditClick} />
//             </div>
//           </div>
//         </form>
//       </div>

//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}

//       {/* PayIn Callback URL Edit Modal */}
//       {showPayinCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
//             <h3>Edit PayIn Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayinCallbackURL"
//                 value={newPayinCallbackURL}
//                 onChange={(e) => setNewPayinCallbackURL(e.target.value)}
//               />
//             </div>
//             {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Success Redirect URL Edit Modal */}
//       {showSuccessRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseSuccessRedirectModal} />
//             <h3>Edit Success Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newSuccessRedirectURL">New Success Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newSuccessRedirectURL"
//                 value={newSuccessRedirectURL}
//                 onChange={(e) => setNewSuccessRedirectURL(e.target.value)}
//               />
//             </div>
//             {successRedirectError && <p className="error-message">{successRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitSuccessRedirectChange}>
//               Update Success Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Failure Redirect URL Edit Modal */}
//       {showFailureRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseFailureRedirectModal} />
//             <h3>Edit Failure Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newFailureRedirectURL">New Failure Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newFailureRedirectURL"
//                 value={newFailureRedirectURL}
//                 onChange={(e) => setNewFailureRedirectURL(e.target.value)}
//               />
//             </div>
//             {failureRedirectError && <p className="error-message">{failureRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitFailureRedirectChange}>
//               Update Failure Redirect URL
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;



// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa';

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '',
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '',
//     refundFee: '',
//     chargebackFee: '',
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '',
//     payinCallbackURL: '',
//     successRedirectURL: '',
//     failureRedirectURL: '',
//     bankName: '',
//     accountNumber: '',
//     ifscCode: '',
//     accountName: '',
//     walletAddress: '',
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // State variables for PayIn Callback URL modal
//   const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
//   const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
//   const [payinCallbackError, setPayinCallbackError] = useState('');

//   // State variables for Success Redirect URL modal
//   const [showSuccessRedirectModal, setShowSuccessRedirectModal] = useState(false);
//   const [newSuccessRedirectURL, setNewSuccessRedirectURL] = useState('');
//   const [successRedirectError, setSuccessRedirectError] = useState('');

//   // State variables for Failure Redirect URL modal
//   const [showFailureRedirectModal, setShowFailureRedirectModal] = useState(false);
//   const [newFailureRedirectURL, setNewFailureRedirectURL] = useState('');
//   const [failureRedirectError, setFailureRedirectError] = useState('');

//   // State variables for Bank Details modal
//   const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
//   const [newBankDetails, setNewBankDetails] = useState({
//     bankName: '',
//     accountNumber: '',
//     ifscCode: '',
//     accountName: '',
//   });
//   const [bankDetailsError, setBankDetailsError] = useState('');

//   // State variables for Wallet Address modal
//   const [showWalletAddressModal, setShowWalletAddressModal] = useState(false);
//   const [newWalletAddress, setNewWalletAddress] = useState('');
//   const [walletAddressError, setWalletAddressError] = useState('');

//   // Fetch profile data from the server
//   useEffect(() => {
//     const fetchProfileData = async () => {
//       try {
//         const mid = localStorage.getItem('merchantId');
//         const response = await fetch(`https://payin.payinfy.com/api/merchant-profile/${mid}/`);
//         if (response.ok) {
//           const result = await response.json();
//           const settlementPeriod = result.settlement_period;
//           const updatedProfileData = {
//             name: result.merchant_name || '',
//             email: result.merchant_email || '',
//             mid: result.merchant_id || '',
//             salt: localStorage.getItem('salt') || '',
//             settlementPeriod: settlementPeriod !== null ? settlementPeriod : '',
//             refundFee: result.refund_fee || '',
//             chargebackFee: result.chargeback_fee || '',
//             payoutSecretKey: result.payout_secret_key || '',
//             payoutCallbackURL: result.payout_callback_url || '',
//             payinSecretKey: result.payin_secret_key || '',
//             payinCallbackURL: result.payin_callback_url || '',
//             successRedirectURL: result.success_redirect_url || '',
//             failureRedirectURL: result.failure_redirect_url || '',
//             bankName: result.bank_name || '',
//             accountNumber: result.account_number || '',
//             ifscCode: result.ifsc_code || '',
//             accountName: result.account_name || '',
//             walletAddress: result.wallet_address || '',
//           };
//           setProfileData(updatedProfileData);
//           // Update localStorage
//           for (const [key, value] of Object.entries(updatedProfileData)) {
//             localStorage.setItem(key, value);
//           }
//         } else {
//           console.error('Failed to fetch profile data');
//         }
//       } catch (error) {
//         console.error('Error fetching profile data:', error);
//       }
//     };

//     fetchProfileData();
//   }, []);

//   // Function to handle clicking the password edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the password modal
//   const handleClosePasswordModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(`https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           old_password: oldPassword,
//           new_password: newPassword,
//         }),
//       });

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleClosePasswordModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   // Function to handle clicking the PayIn Callback URL edit icon
//   const handlePayinCallbackEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowPayinCallbackModal(true);
//     setNewPayinCallbackURL(profileData.payinCallbackURL); // Initialize with current value
//   };

//   // Function to handle closing the PayIn Callback URL modal
//   const handleClosePayinCallbackModal = () => {
//     setShowPayinCallbackModal(false);
//     setNewPayinCallbackURL('');
//     setPayinCallbackError('');
//   };

//   // Function to submit the PayIn Callback URL change
//   const handleSubmitPayinCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payin_callback_url: newPayinCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('PayIn Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payinCallbackURL: newPayinCallbackURL,
//         }));
//         localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
//         handleClosePayinCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
//       }
//     } catch (error) {
//       setPayinCallbackError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Success Redirect URL edit icon
//   const handleSuccessRedirectEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowSuccessRedirectModal(true);
//     setNewSuccessRedirectURL(profileData.successRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Success Redirect URL modal
//   const handleCloseSuccessRedirectModal = () => {
//     setShowSuccessRedirectModal(false);
//     setNewSuccessRedirectURL('');
//     setSuccessRedirectError('');
//   };

//   // Function to submit the Success Redirect URL change
//   const handleSubmitSuccessRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           success_redirect_url: newSuccessRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Success Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           successRedirectURL: newSuccessRedirectURL,
//         }));
//         localStorage.setItem('successRedirectURL', newSuccessRedirectURL);
//         handleCloseSuccessRedirectModal();
//       } else {
//         const result = await response.json();
//         setSuccessRedirectError(result.message || 'Failed to update Success Redirect URL');
//       }
//     } catch (error) {
//       setSuccessRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Failure Redirect URL edit icon
//   const handleFailureRedirectEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowFailureRedirectModal(true);
//     setNewFailureRedirectURL(profileData.failureRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Failure Redirect URL modal
//   const handleCloseFailureRedirectModal = () => {
//     setShowFailureRedirectModal(false);
//     setNewFailureRedirectURL('');
//     setFailureRedirectError('');
//   };

//   // Function to submit the Failure Redirect URL change
//   const handleSubmitFailureRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           failure_redirect_url: newFailureRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Failure Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           failureRedirectURL: newFailureRedirectURL,
//         }));
//         localStorage.setItem('failureRedirectURL', newFailureRedirectURL);
//         handleCloseFailureRedirectModal();
//       } else {
//         const result = await response.json();
//         setFailureRedirectError(result.message || 'Failed to update Failure Redirect URL');
//       }
//     } catch (error) {
//       setFailureRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Bank Details edit icon
//   const handleBankDetailsEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowBankDetailsModal(true);
//     setNewBankDetails({
//       bankName: profileData.bankName || '',
//       accountNumber: profileData.accountNumber || '',
//       ifscCode: profileData.ifscCode || '',
//       accountName: profileData.accountName || '',
//     });
//   };

//   // Function to handle closing the Bank Details modal
//   const handleCloseBankDetailsModal = () => {
//     setShowBankDetailsModal(false);
//     setNewBankDetails({
//       bankName: '',
//       accountNumber: '',
//       ifscCode: '',
//       accountName: '',
//     });
//     setBankDetailsError('');
//   };

//   // Function to submit the Bank Details change
//   const handleSubmitBankDetailsChange = async () => {
//     try {
//       const { bankName, accountNumber, ifscCode, accountName } = newBankDetails;
//       if (!bankName || !accountNumber || !ifscCode || !accountName) {
//         setBankDetailsError('All fields are required');
//         return;
//       }

//       const response = await fetch(`https://payin.payinfy.com/api/update-bank-details/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           bank_name: bankName,
//           account_number: accountNumber,
//           ifsc_code: ifscCode,
//           account_name: accountName,
//         }),
//       });

//       if (response.ok) {
//         alert('Bank details updated successfully');
//         setProfileData((prevData) => ({
//           ...prevData,
//           bankName,
//           accountNumber,
//           ifscCode,
//           accountName,
//         }));
//         localStorage.setItem('bankName', bankName);
//         localStorage.setItem('accountNumber', accountNumber);
//         localStorage.setItem('ifscCode', ifscCode);
//         localStorage.setItem('accountName', accountName);
//         handleCloseBankDetailsModal();
//       } else {
//         const result = await response.json();
//         setBankDetailsError(result.message || 'Failed to update bank details');
//       }
//     } catch (error) {
//       setBankDetailsError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Wallet Address edit icon
//   const handleWalletAddressEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowWalletAddressModal(true);
//     setNewWalletAddress(profileData.walletAddress || '');
//   };

//   // Function to handle closing the Wallet Address modal
//   const handleCloseWalletAddressModal = () => {
//     setShowWalletAddressModal(false);
//     setNewWalletAddress('');
//     setWalletAddressError('');
//   };

//   // Function to submit the Wallet Address change
//   const handleSubmitWalletAddressChange = async () => {
//     try {
//       if (!newWalletAddress) {
//         setWalletAddressError('Wallet address is required');
//         return;
//       }

//       const response = await fetch(`https://payin.payinfy.com/api/update-wallet-address/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ wallet_address: newWalletAddress }),
//       });

//       if (response.ok) {
//         alert('Wallet address updated successfully');
//         setProfileData((prevData) => ({
//           ...prevData,
//           walletAddress: newWalletAddress,
//         }));
//         localStorage.setItem('walletAddress', newWalletAddress);
//         handleCloseWalletAddressModal();
//       } else {
//         const result = await response.json();
//         setWalletAddressError(result.message || 'Failed to update wallet address');
//       }
//     } catch (error) {
//       setWalletAddressError('An error occurred');
//     }
//   };

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Bank Details Field with Edit Icon */}
//           <div className="form-group bank-details-group">
//             <label htmlFor="bankDetails">Bank Details:</label>
//             <div className="bank-details-field">
//               <input
//                 type="text"
//                 id="bankDetails"
//                 name="bankDetails"
//                 value={
//                   profileData.bankName
//                     ? `${profileData.bankName}, ${profileData.accountNumber}`
//                     : 'Not provided'
//                 }
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleBankDetailsEditClick} />
//             </div>
//           </div>

//           {/* Wallet Address Field with Edit Icon */}
//           <div className="form-group wallet-address-group">
//             <label htmlFor="walletAddress">Wallet Address:</label>
//             <div className="wallet-address-field">
//               <input
//                 type="text"
//                 id="walletAddress"
//                 name="walletAddress"
//                 value={profileData.walletAddress || 'Not provided'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleWalletAddressEditClick} />
//             </div>
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod !== '' ? `T + ${profileData.settlementPeriod} days` : 'N/A'
//               }
//               readOnly
//             />
//           </div>

//           {/* Refund Fee Field */}
//           <div className="form-group">
//             <label htmlFor="refundFee">Refund Fee:</label>
//             <input
//               type="text"
//               id="refundFee"
//               name="refundFee"
//               value={
//                 profileData.refundFee !== '' ? `${profileData.refundFee}` : 'N/A'
//               }
//               readOnly
//             />
//           </div>

//           {/* Chargeback Fee Field */}
//           <div className="form-group">
//             <label htmlFor="chargebackFee">Chargeback Fee:</label>
//             <input
//               type="text"
//               id="chargebackFee"
//               name="chargebackFee"
//               value={
//                 profileData.chargebackFee !== '' ? `${profileData.chargebackFee}` : 'N/A'
//               }
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key and Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey}
//               readOnly
//             />
//           </div>

//           <div className="form-group callback-url-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payinCallbackURL"
//                 name="payinCallbackURL"
//                 value={profileData.payinCallbackURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
//             </div>
//           </div>

//           {/* Success Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="successRedirectURL">Success Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="successRedirectURL"
//                 name="successRedirectURL"
//                 value={profileData.successRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleSuccessRedirectEditClick} />
//             </div>
//           </div>

//           {/* Failure Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="failureRedirectURL">Failure Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="failureRedirectURL"
//                 name="failureRedirectURL"
//                 value={profileData.failureRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleFailureRedirectEditClick} />
//             </div>
//           </div>
//         </form>
//       </div>

//       {/* Modals */}
//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}

//       {/* PayIn Callback URL Edit Modal */}
//       {showPayinCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
//             <h3>Edit PayIn Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayinCallbackURL"
//                 value={newPayinCallbackURL}
//                 onChange={(e) => setNewPayinCallbackURL(e.target.value)}
//               />
//             </div>
//             {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Success Redirect URL Edit Modal */}
//       {showSuccessRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseSuccessRedirectModal} />
//             <h3>Edit Success Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newSuccessRedirectURL">New Success Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newSuccessRedirectURL"
//                 value={newSuccessRedirectURL}
//                 onChange={(e) => setNewSuccessRedirectURL(e.target.value)}
//               />
//             </div>
//             {successRedirectError && <p className="error-message">{successRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitSuccessRedirectChange}>
//               Update Success Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Failure Redirect URL Edit Modal */}
//       {showFailureRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseFailureRedirectModal} />
//             <h3>Edit Failure Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newFailureRedirectURL">New Failure Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newFailureRedirectURL"
//                 value={newFailureRedirectURL}
//                 onChange={(e) => setNewFailureRedirectURL(e.target.value)}
//               />
//             </div>
//             {failureRedirectError && <p className="error-message">{failureRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitFailureRedirectChange}>
//               Update Failure Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Bank Details Edit Modal */}
//       {showBankDetailsModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseBankDetailsModal} />
//             <h3>Edit Bank Details</h3>
//             <div className="form-group">
//               <label htmlFor="bankName">Bank Name:</label>
//               <input
//                 type="text"
//                 id="bankName"
//                 value={newBankDetails.bankName}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, bankName: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="accountNumber">Account Number:</label>
//               <input
//                 type="text"
//                 id="accountNumber"
//                 value={newBankDetails.accountNumber}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, accountNumber: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="ifscCode">IFSC Code:</label>
//               <input
//                 type="text"
//                 id="ifscCode"
//                 value={newBankDetails.ifscCode}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, ifscCode: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="accountName">Account Name:</label>
//               <input
//                 type="text"
//                 id="accountName"
//                 value={newBankDetails.accountName}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, accountName: e.target.value }))}
//               />
//             </div>
//             {bankDetailsError && <p className="error-message">{bankDetailsError}</p>}
//             <button className="submit-btn" onClick={handleSubmitBankDetailsChange}>
//               Update Bank Details
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Wallet Address Edit Modal */}
//       {showWalletAddressModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseWalletAddressModal} />
//             <h3>Edit Wallet Address</h3>
//             <div className="form-group">
//               <label htmlFor="newWalletAddress">New Wallet Address:</label>
//               <input
//                 type="text"
//                 id="newWalletAddress"
//                 value={newWalletAddress}
//                 onChange={(e) => setNewWalletAddress(e.target.value)}
//               />
//             </div>
//             {walletAddressError && <p className="error-message">{walletAddressError}</p>}
//             <button className="submit-btn" onClick={handleSubmitWalletAddressChange}>
//               Update Wallet Address
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;



// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa';

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '',
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '',
//     refundFee: '',
//     chargebackFee: '',
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '',
//     payinCallbackURL: '',
//     successRedirectURL: '',
//     failureRedirectURL: '',
//     bankName: '',
//     accountNumber: '',
//     ifscCode: '',
//     accountName: '',
//     walletAddress: '',
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // State variables for PayIn Callback URL modal
//   const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
//   const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
//   const [payinCallbackError, setPayinCallbackError] = useState('');

//   // State variables for Success Redirect URL modal
//   const [showSuccessRedirectModal, setShowSuccessRedirectModal] = useState(false);
//   const [newSuccessRedirectURL, setNewSuccessRedirectURL] = useState('');
//   const [successRedirectError, setSuccessRedirectError] = useState('');

//   // State variables for Failure Redirect URL modal
//   const [showFailureRedirectModal, setShowFailureRedirectModal] = useState(false);
//   const [newFailureRedirectURL, setNewFailureRedirectURL] = useState('');
//   const [failureRedirectError, setFailureRedirectError] = useState('');

//   // State variables for Bank Details modal
//   const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
//   const [newBankDetails, setNewBankDetails] = useState({
//     bankName: '',
//     accountNumber: '',
//     ifscCode: '',
//     accountName: '',
//   });
//   const [bankDetailsError, setBankDetailsError] = useState('');

//   // State variables for Wallet Address modal
//   const [showWalletAddressModal, setShowWalletAddressModal] = useState(false);
//   const [newWalletAddress, setNewWalletAddress] = useState('');
//   const [walletAddressError, setWalletAddressError] = useState('');

//   // Fetch profile data from the server
//   useEffect(() => {
//     const fetchProfileData = async () => {
//       try {
//         const mid = localStorage.getItem('merchantId');
//         const response = await fetch(`https://payin.payinfy.com/api/merchant-profile/${mid}/`);
//         if (response.ok) {
//           const result = await response.json();
//           const settlementPeriod = result.settlement_period;
//           const updatedProfileData = {
//             name: result.merchant_name || '',
//             email: result.merchant_email || '',
//             mid: result.merchant_id || '',
//             salt: result.salt || '',
//             settlementPeriod: settlementPeriod !== null ? settlementPeriod : '',
//             refundFee: result.refund_fee || '',
//             chargebackFee: result.chargeback_fee || '',
//             payoutSecretKey: result.payout_secret_key || '',
//             payoutCallbackURL: result.payout_callback_url || '',
//             payinSecretKey: result.payin_secret_key || '',
//             payinCallbackURL: result.payin_callback_url || '',
//             successRedirectURL: result.success_redirect_url || '',
//             failureRedirectURL: result.failure_redirect_url || '',
//             bankName: result.bank_name || '',
//             accountNumber: result.account_number || '',
//             ifscCode: result.ifsc_code || '',
//             accountName: result.account_name || '',
//             walletAddress: result.wallet_address || '',
//           };
//           setProfileData(updatedProfileData);
//           // Update localStorage
//           for (const [key, value] of Object.entries(updatedProfileData)) {
//             localStorage.setItem(key, value);
//           }
//         } else {
//           console.error('Failed to fetch profile data');
//         }
//       } catch (error) {
//         console.error('Error fetching profile data:', error);
//       }
//     };

//     fetchProfileData();
//   }, []);

//   // Fetch Payout Secret Key from the payout project
//   // Fetch Payout Secret Key from the payout project
//   useEffect(() => {
//     const fetchPayoutSecretKey = async () => {
//       try {
//         const mid = localStorage.getItem('merchantId');
//         if (!mid) {
//           console.error('Merchant ID is missing');
//           return;
//         }

//         const payoutSecretKeyResponse = await fetch(`https://payouts.payinfy.com/payouts/get-payout-secret-key/?merchant_id=${mid}`, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });

//         if (payoutSecretKeyResponse.ok) {
//           const result = await payoutSecretKeyResponse.json();
//           setProfileData((prevData) => ({
//             ...prevData,
//             payoutSecretKey: result.payout_secret_key || '',
//           }));
//           localStorage.setItem('payoutSecretKey', result.payout_secret_key || '');
//         } else {
//           console.error('Failed to fetch payout secret key');
//           const errorResult = await payoutSecretKeyResponse.json();
//           console.error('Error response:', errorResult);
//         }
//       } catch (error) {
//         console.error('Error fetching payout secret key:', error);
//       }
//     };

//     if (!profileData.payoutSecretKey) {
//       fetchPayoutSecretKey();
//     }
//   }, [profileData.payoutSecretKey]);

//   // Function to handle clicking the password edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the password modal
//   const handleClosePasswordModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(`https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           old_password: oldPassword,
//           new_password: newPassword,
//         }),
//       });

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleClosePasswordModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   // Function to handle clicking the PayIn Callback URL edit icon
//   const handlePayinCallbackEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowPayinCallbackModal(true);
//     setNewPayinCallbackURL(profileData.payinCallbackURL); // Initialize with current value
//   };

//   // Function to handle closing the PayIn Callback URL modal
//   const handleClosePayinCallbackModal = () => {
//     setShowPayinCallbackModal(false);
//     setNewPayinCallbackURL('');
//     setPayinCallbackError('');
//   };

//   // Function to submit the PayIn Callback URL change
//   const handleSubmitPayinCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payin_callback_url: newPayinCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('PayIn Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payinCallbackURL: newPayinCallbackURL,
//         }));
//         localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
//         handleClosePayinCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
//       }
//     } catch (error) {
//       setPayinCallbackError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Success Redirect URL edit icon
//   const handleSuccessRedirectEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowSuccessRedirectModal(true);
//     setNewSuccessRedirectURL(profileData.successRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Success Redirect URL modal
//   const handleCloseSuccessRedirectModal = () => {
//     setShowSuccessRedirectModal(false);
//     setNewSuccessRedirectURL('');
//     setSuccessRedirectError('');
//   };

//   // Function to submit the Success Redirect URL change
//   const handleSubmitSuccessRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           success_redirect_url: newSuccessRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Success Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           successRedirectURL: newSuccessRedirectURL,
//         }));
//         localStorage.setItem('successRedirectURL', newSuccessRedirectURL);
//         handleCloseSuccessRedirectModal();
//       } else {
//         const result = await response.json();
//         setSuccessRedirectError(result.message || 'Failed to update Success Redirect URL');
//       }
//     } catch (error) {
//       setSuccessRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Failure Redirect URL edit icon
//   const handleFailureRedirectEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowFailureRedirectModal(true);
//     setNewFailureRedirectURL(profileData.failureRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Failure Redirect URL modal
//   const handleCloseFailureRedirectModal = () => {
//     setShowFailureRedirectModal(false);
//     setNewFailureRedirectURL('');
//     setFailureRedirectError('');
//   };

//   // Function to submit the Failure Redirect URL change
//   const handleSubmitFailureRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           failure_redirect_url: newFailureRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Failure Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           failureRedirectURL: newFailureRedirectURL,
//         }));
//         localStorage.setItem('failureRedirectURL', newFailureRedirectURL);
//         handleCloseFailureRedirectModal();
//       } else {
//         const result = await response.json();
//         setFailureRedirectError(result.message || 'Failed to update Failure Redirect URL');
//       }
//     } catch (error) {
//       setFailureRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Bank Details edit icon
//   const handleBankDetailsEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowBankDetailsModal(true);
//     setNewBankDetails({
//       bankName: profileData.bankName || '',
//       accountNumber: profileData.accountNumber || '',
//       ifscCode: profileData.ifscCode || '',
//       accountName: profileData.accountName || '',
//     });
//   };

//   // Function to handle closing the Bank Details modal
//   const handleCloseBankDetailsModal = () => {
//     setShowBankDetailsModal(false);
//     setNewBankDetails({
//       bankName: '',
//       accountNumber: '',
//       ifscCode: '',
//       accountName: '',
//     });
//     setBankDetailsError('');
//   };

//   // Function to submit the Bank Details change
//   const handleSubmitBankDetailsChange = async () => {
//     try {
//       const { bankName, accountNumber, ifscCode, accountName } = newBankDetails;
//       if (!bankName || !accountNumber || !ifscCode || !accountName) {
//         setBankDetailsError('All fields are required');
//         return;
//       }

//       const response = await fetch(`https://payin.payinfy.com/api/update-bank-details/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           bank_name: bankName,
//           account_number: accountNumber,
//           ifsc_code: ifscCode,
//           account_name: accountName,
//         }),
//       });

//       if (response.ok) {
//         alert('Bank details updated successfully');
//         setProfileData((prevData) => ({
//           ...prevData,
//           bankName,
//           accountNumber,
//           ifscCode,
//           accountName,
//         }));
//         localStorage.setItem('bankName', bankName);
//         localStorage.setItem('accountNumber', accountNumber);
//         localStorage.setItem('ifscCode', ifscCode);
//         localStorage.setItem('accountName', accountName);
//         handleCloseBankDetailsModal();
//       } else {
//         const result = await response.json();
//         setBankDetailsError(result.message || 'Failed to update bank details');
//       }
//     } catch (error) {
//       setBankDetailsError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Wallet Address edit icon
//   const handleWalletAddressEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowWalletAddressModal(true);
//     setNewWalletAddress(profileData.walletAddress || '');
//   };

//   // Function to handle closing the Wallet Address modal
//   const handleCloseWalletAddressModal = () => {
//     setShowWalletAddressModal(false);
//     setNewWalletAddress('');
//     setWalletAddressError('');
//   };

//   // Function to submit the Wallet Address change
//   const handleSubmitWalletAddressChange = async () => {
//     try {
//       if (!newWalletAddress) {
//         setWalletAddressError('Wallet address is required');
//         return;
//       }

//       const response = await fetch(`https://payin.payinfy.com/api/update-wallet-address/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ wallet_address: newWalletAddress }),
//       });

//       if (response.ok) {
//         alert('Wallet address updated successfully');
//         setProfileData((prevData) => ({
//           ...prevData,
//           walletAddress: newWalletAddress,
//         }));
//         localStorage.setItem('walletAddress', newWalletAddress);
//         handleCloseWalletAddressModal();
//       } else {
//         const result = await response.json();
//         setWalletAddressError(result.message || 'Failed to update wallet address');
//       }
//     } catch (error) {
//       setWalletAddressError('An error occurred');
//     }
//   };

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Bank Details Field with Edit Icon */}
//           <div className="form-group bank-details-group">
//             <label htmlFor="bankDetails">Bank Details:</label>
//             <div className="bank-details-field">
//               <input
//                 type="text"
//                 id="bankDetails"
//                 name="bankDetails"
//                 value={
//                   profileData.bankName
//                     ? `${profileData.bankName}, ${profileData.accountNumber}`
//                     : 'Not provided'
//                 }
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleBankDetailsEditClick} />
//             </div>
//           </div>

//           {/* Wallet Address Field with Edit Icon */}
//           <div className="form-group wallet-address-group">
//             <label htmlFor="walletAddress">Wallet Address:</label>
//             <div className="wallet-address-field">
//               <input
//                 type="text"
//                 id="walletAddress"
//                 name="walletAddress"
//                 value={profileData.walletAddress || 'Not provided'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleWalletAddressEditClick} />
//             </div>
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod !== '' ? `T + ${profileData.settlementPeriod} days` : 'N/A'
//               }
//               readOnly
//             />
//           </div>

//           {/* Refund Fee Field */}
//           <div className="form-group">
//             <label htmlFor="refundFee">Refund Fee:</label>
//             <input
//               type="text"
//               id="refundFee"
//               name="refundFee"
//               value={
//                 profileData.refundFee !== '' ? `${profileData.refundFee}` : 'N/A'
//               }
//               readOnly
//             />
//           </div>

//           {/* Chargeback Fee Field */}
//           <div className="form-group">
//             <label htmlFor="chargebackFee">Chargeback Fee:</label>
//             <input
//               type="text"
//               id="chargebackFee"
//               name="chargebackFee"
//               value={
//                 profileData.chargebackFee !== '' ? `${profileData.chargebackFee}` : 'N/A'
//               }
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayOut Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayIn Callback URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payinCallbackURL"
//                 name="payinCallbackURL"
//                 value={profileData.payinCallbackURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
//             </div>
//           </div>

//           {/* Success Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="successRedirectURL">Success Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="successRedirectURL"
//                 name="successRedirectURL"
//                 value={profileData.successRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleSuccessRedirectEditClick} />
//             </div>
//           </div>

//           {/* Failure Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="failureRedirectURL">Failure Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="failureRedirectURL"
//                 name="failureRedirectURL"
//                 value={profileData.failureRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleFailureRedirectEditClick} />
//             </div>
//           </div>
//         </form>
//       </div>

//       {/* Modals */}
//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}

//       {/* PayIn Callback URL Edit Modal */}
//       {showPayinCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
//             <h3>Edit PayIn Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayinCallbackURL"
//                 value={newPayinCallbackURL}
//                 onChange={(e) => setNewPayinCallbackURL(e.target.value)}
//               />
//             </div>
//             {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Success Redirect URL Edit Modal */}
//       {showSuccessRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseSuccessRedirectModal} />
//             <h3>Edit Success Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newSuccessRedirectURL">New Success Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newSuccessRedirectURL"
//                 value={newSuccessRedirectURL}
//                 onChange={(e) => setNewSuccessRedirectURL(e.target.value)}
//               />
//             </div>
//             {successRedirectError && <p className="error-message">{successRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitSuccessRedirectChange}>
//               Update Success Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Failure Redirect URL Edit Modal */}
//       {showFailureRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseFailureRedirectModal} />
//             <h3>Edit Failure Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newFailureRedirectURL">New Failure Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newFailureRedirectURL"
//                 value={newFailureRedirectURL}
//                 onChange={(e) => setNewFailureRedirectURL(e.target.value)}
//               />
//             </div>
//             {failureRedirectError && <p className="error-message">{failureRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitFailureRedirectChange}>
//               Update Failure Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Bank Details Edit Modal */}
//       {showBankDetailsModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseBankDetailsModal} />
//             <h3>Edit Bank Details</h3>
//             <div className="form-group">
//               <label htmlFor="bankName">Bank Name:</label>
//               <input
//                 type="text"
//                 id="bankName"
//                 value={newBankDetails.bankName}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, bankName: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="accountNumber">Account Number:</label>
//               <input
//                 type="text"
//                 id="accountNumber"
//                 value={newBankDetails.accountNumber}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, accountNumber: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="ifscCode">IFSC Code:</label>
//               <input
//                 type="text"
//                 id="ifscCode"
//                 value={newBankDetails.ifscCode}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, ifscCode: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="accountName">Account Name:</label>
//               <input
//                 type="text"
//                 id="accountName"
//                 value={newBankDetails.accountName}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, accountName: e.target.value }))}
//               />
//             </div>
//             {bankDetailsError && <p className="error-message">{bankDetailsError}</p>}
//             <button className="submit-btn" onClick={handleSubmitBankDetailsChange}>
//               Update Bank Details
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Wallet Address Edit Modal */}
//       {showWalletAddressModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseWalletAddressModal} />
//             <h3>Edit Wallet Address</h3>
//             <div className="form-group">
//               <label htmlFor="newWalletAddress">New Wallet Address:</label>
//               <input
//                 type="text"
//                 id="newWalletAddress"
//                 value={newWalletAddress}
//                 onChange={(e) => setNewWalletAddress(e.target.value)}
//               />
//             </div>
//             {walletAddressError && <p className="error-message">{walletAddressError}</p>}
//             <button className="submit-btn" onClick={handleSubmitWalletAddressChange}>
//               Update Wallet Address
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;



// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa';

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '',
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '',
//     refundFee: '',
//     chargebackFee: '',
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '',
//     payinCallbackURL: '',
//     successRedirectURL: '',
//     failureRedirectURL: '',
//     bankName: '',
//     accountNumber: '',
//     ifscCode: '',
//     accountName: '',
//     walletAddress: '',
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // State variables for PayIn Callback URL modal
//   const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
//   const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
//   const [payinCallbackError, setPayinCallbackError] = useState('');

//   // State variables for Success Redirect URL modal
//   const [showSuccessRedirectModal, setShowSuccessRedirectModal] = useState(false);
//   const [newSuccessRedirectURL, setNewSuccessRedirectURL] = useState('');
//   const [successRedirectError, setSuccessRedirectError] = useState('');

//   // State variables for Failure Redirect URL modal
//   const [showFailureRedirectModal, setShowFailureRedirectModal] = useState(false);
//   const [newFailureRedirectURL, setNewFailureRedirectURL] = useState('');
//   const [failureRedirectError, setFailureRedirectError] = useState('');

//   // State variables for Bank Details modal
//   const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
//   const [newBankDetails, setNewBankDetails] = useState({
//     bankName: '',
//     accountNumber: '',
//     ifscCode: '',
//     accountName: '',
//   });
//   const [bankDetailsError, setBankDetailsError] = useState('');

//   // State variables for Wallet Address modal
//   const [showWalletAddressModal, setShowWalletAddressModal] = useState(false);
//   const [newWalletAddress, setNewWalletAddress] = useState('');
//   const [walletAddressError, setWalletAddressError] = useState('');

//   // Fetch profile data from the merchants project
//   useEffect(() => {
//     const fetchProfileData = async () => {
//       try {
//         const mid = localStorage.getItem('merchantId');
//         const response = await fetch(`https://merchant.payinfy.com/merchants/merchant-profile/${mid}/`);
//         if (response.ok) {
//           const result = await response.json();
//           const settlementPeriod = result.settlement_period;
//           const updatedProfileData = {
//             name: result.merchant_name || '',
//             email: result.email || '',
//             mid: result.merchant_id || '',
//             salt: result.salt || '',
//             settlementPeriod: settlementPeriod !== null ? settlementPeriod : '',
//             refundFee: result.refund_fee || '',
//             chargebackFee: result.chargeback_fee || '',
//             payoutSecretKey: result.payout_secret_key || '',
//             payoutCallbackURL: result.payout_callback_url || '',
//             payinSecretKey: result.secret_key || '',
//             payinCallbackURL: result.payin_callback_url || '',
//             successRedirectURL: result.success_redirect_url || '',
//             failureRedirectURL: result.failure_redirect_url || '',
//             bankName: result.bank_name || '',
//             accountNumber: result.account_number || '',
//             ifscCode: result.ifsc_code || '',
//             accountName: result.account_name || '',
//             walletAddress: result.wallet_address || '',
//           };
//           setProfileData(updatedProfileData);
//           // Update localStorage
//           for (const [key, value] of Object.entries(updatedProfileData)) {
//             localStorage.setItem(key, value);
//           }
//         } else {
//           console.error('Failed to fetch profile data');
//         }
//       } catch (error) {
//         console.error('Error fetching profile data:', error);
//       }
//     };

//     fetchProfileData();
//   }, []);

//   // Remove the useEffect that fetches the payout secret key from the payouts project
//   // since we're now fetching it directly from the merchants project.

//   // Function to handle clicking the password edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the password modal
//   const handleClosePasswordModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(`https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           old_password: oldPassword,
//           new_password: newPassword,
//         }),
//       });

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleClosePasswordModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   // Function to handle clicking the PayIn Callback URL edit icon
//   const handlePayinCallbackEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowPayinCallbackModal(true);
//     setNewPayinCallbackURL(profileData.payinCallbackURL); // Initialize with current value
//   };

//   // Function to handle closing the PayIn Callback URL modal
//   const handleClosePayinCallbackModal = () => {
//     setShowPayinCallbackModal(false);
//     setNewPayinCallbackURL('');
//     setPayinCallbackError('');
//   };

//   // Function to submit the PayIn Callback URL change
//   const handleSubmitPayinCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payin_callback_url: newPayinCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('PayIn Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payinCallbackURL: newPayinCallbackURL,
//         }));
//         localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
//         handleClosePayinCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
//       }
//     } catch (error) {
//       setPayinCallbackError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Success Redirect URL edit icon
//   const handleSuccessRedirectEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowSuccessRedirectModal(true);
//     setNewSuccessRedirectURL(profileData.successRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Success Redirect URL modal
//   const handleCloseSuccessRedirectModal = () => {
//     setShowSuccessRedirectModal(false);
//     setNewSuccessRedirectURL('');
//     setSuccessRedirectError('');
//   };

//   // Function to submit the Success Redirect URL change
//   const handleSubmitSuccessRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           success_redirect_url: newSuccessRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Success Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           successRedirectURL: newSuccessRedirectURL,
//         }));
//         localStorage.setItem('successRedirectURL', newSuccessRedirectURL);
//         handleCloseSuccessRedirectModal();
//       } else {
//         const result = await response.json();
//         setSuccessRedirectError(result.message || 'Failed to update Success Redirect URL');
//       }
//     } catch (error) {
//       setSuccessRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Failure Redirect URL edit icon
//   const handleFailureRedirectEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowFailureRedirectModal(true);
//     setNewFailureRedirectURL(profileData.failureRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Failure Redirect URL modal
//   const handleCloseFailureRedirectModal = () => {
//     setShowFailureRedirectModal(false);
//     setNewFailureRedirectURL('');
//     setFailureRedirectError('');
//   };

//   // Function to submit the Failure Redirect URL change
//   const handleSubmitFailureRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           failure_redirect_url: newFailureRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Failure Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           failureRedirectURL: newFailureRedirectURL,
//         }));
//         localStorage.setItem('failureRedirectURL', newFailureRedirectURL);
//         handleCloseFailureRedirectModal();
//       } else {
//         const result = await response.json();
//         setFailureRedirectError(result.message || 'Failed to update Failure Redirect URL');
//       }
//     } catch (error) {
//       setFailureRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Bank Details edit icon
//   const handleBankDetailsEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowBankDetailsModal(true);
//     setNewBankDetails({
//       bankName: profileData.bankName || '',
//       accountNumber: profileData.accountNumber || '',
//       ifscCode: profileData.ifscCode || '',
//       accountName: profileData.accountName || '',
//     });
//   };

//   // Function to handle closing the Bank Details modal
//   const handleCloseBankDetailsModal = () => {
//     setShowBankDetailsModal(false);
//     setNewBankDetails({
//       bankName: '',
//       accountNumber: '',
//       ifscCode: '',
//       accountName: '',
//     });
//     setBankDetailsError('');
//   };

//   // Function to submit the Bank Details change
//   const handleSubmitBankDetailsChange = async () => {
//     try {
//       const { bankName, accountNumber, ifscCode, accountName } = newBankDetails;
//       if (!bankName || !accountNumber || !ifscCode || !accountName) {
//         setBankDetailsError('All fields are required');
//         return;
//       }

//       const response = await fetch(`https://payin.payinfy.com/api/update-bank-details/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           bank_name: bankName,
//           account_number: accountNumber,
//           ifsc_code: ifscCode,
//           account_name: accountName,
//         }),
//       });

//       if (response.ok) {
//         alert('Bank details updated successfully');
//         setProfileData((prevData) => ({
//           ...prevData,
//           bankName,
//           accountNumber,
//           ifscCode,
//           accountName,
//         }));
//         localStorage.setItem('bankName', bankName);
//         localStorage.setItem('accountNumber', accountNumber);
//         localStorage.setItem('ifscCode', ifscCode);
//         localStorage.setItem('accountName', accountName);
//         handleCloseBankDetailsModal();
//       } else {
//         const result = await response.json();
//         setBankDetailsError(result.message || 'Failed to update bank details');
//       }
//     } catch (error) {
//       setBankDetailsError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Wallet Address edit icon
//   const handleWalletAddressEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowWalletAddressModal(true);
//     setNewWalletAddress(profileData.walletAddress || '');
//   };

//   // Function to handle closing the Wallet Address modal
//   const handleCloseWalletAddressModal = () => {
//     setShowWalletAddressModal(false);
//     setNewWalletAddress('');
//     setWalletAddressError('');
//   };

//   // Function to submit the Wallet Address change
//   const handleSubmitWalletAddressChange = async () => {
//     try {
//       if (!newWalletAddress) {
//         setWalletAddressError('Wallet address is required');
//         return;
//       }

//       const response = await fetch(`https://payin.payinfy.com/api/update-wallet-address/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ wallet_address: newWalletAddress }),
//       });

//       if (response.ok) {
//         alert('Wallet address updated successfully');
//         setProfileData((prevData) => ({
//           ...prevData,
//           walletAddress: newWalletAddress,
//         }));
//         localStorage.setItem('walletAddress', newWalletAddress);
//         handleCloseWalletAddressModal();
//       } else {
//         const result = await response.json();
//         setWalletAddressError(result.message || 'Failed to update wallet address');
//       }
//     } catch (error) {
//       setWalletAddressError('An error occurred');
//     }
//   };

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Bank Details Field with Edit Icon */}
//           <div className="form-group bank-details-group">
//             <label htmlFor="bankDetails">Bank Details:</label>
//             <div className="bank-details-field">
//               <input
//                 type="text"
//                 id="bankDetails"
//                 name="bankDetails"
//                 value={
//                   profileData.bankName
//                     ? `${profileData.bankName}, ${profileData.accountNumber}`
//                     : 'Not provided'
//                 }
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleBankDetailsEditClick} />
//             </div>
//           </div>

//           {/* Wallet Address Field with Edit Icon */}
//           <div className="form-group wallet-address-group">
//             <label htmlFor="walletAddress">Wallet Address:</label>
//             <div className="wallet-address-field">
//               <input
//                 type="text"
//                 id="walletAddress"
//                 name="walletAddress"
//                 value={profileData.walletAddress || 'Not provided'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleWalletAddressEditClick} />
//             </div>
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod !== '' ? `T + ${profileData.settlementPeriod} days` : 'N/A'
//               }
//               readOnly
//             />
//           </div>

//           {/* Refund Fee Field */}
//           <div className="form-group">
//             <label htmlFor="refundFee">Refund Fee:</label>
//             <input
//               type="text"
//               id="refundFee"
//               name="refundFee"
//               value={profileData.refundFee !== '' ? `${profileData.refundFee}` : 'N/A'}
//               readOnly
//             />
//           </div>

//           {/* Chargeback Fee Field */}
//           <div className="form-group">
//             <label htmlFor="chargebackFee">Chargeback Fee:</label>
//             <input
//               type="text"
//               id="chargebackFee"
//               name="chargebackFee"
//               value={profileData.chargebackFee !== '' ? `${profileData.chargebackFee}` : 'N/A'}
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayOut Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayIn Callback URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payinCallbackURL"
//                 name="payinCallbackURL"
//                 value={profileData.payinCallbackURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
//             </div>
//           </div>

//           {/* Success Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="successRedirectURL">Success Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="successRedirectURL"
//                 name="successRedirectURL"
//                 value={profileData.successRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleSuccessRedirectEditClick} />
//             </div>
//           </div>

//           {/* Failure Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="failureRedirectURL">Failure Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="failureRedirectURL"
//                 name="failureRedirectURL"
//                 value={profileData.failureRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleFailureRedirectEditClick} />
//             </div>
//           </div>
//         </form>
//       </div>

//       {/* Modals */}
//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}

//       {/* PayIn Callback URL Edit Modal */}
//       {showPayinCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
//             <h3>Edit PayIn Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayinCallbackURL"
//                 value={newPayinCallbackURL}
//                 onChange={(e) => setNewPayinCallbackURL(e.target.value)}
//               />
//             </div>
//             {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Success Redirect URL Edit Modal */}
//       {showSuccessRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseSuccessRedirectModal} />
//             <h3>Edit Success Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newSuccessRedirectURL">New Success Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newSuccessRedirectURL"
//                 value={newSuccessRedirectURL}
//                 onChange={(e) => setNewSuccessRedirectURL(e.target.value)}
//               />
//             </div>
//             {successRedirectError && <p className="error-message">{successRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitSuccessRedirectChange}>
//               Update Success Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Failure Redirect URL Edit Modal */}
//       {showFailureRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseFailureRedirectModal} />
//             <h3>Edit Failure Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newFailureRedirectURL">New Failure Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newFailureRedirectURL"
//                 value={newFailureRedirectURL}
//                 onChange={(e) => setNewFailureRedirectURL(e.target.value)}
//               />
//             </div>
//             {failureRedirectError && <p className="error-message">{failureRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitFailureRedirectChange}>
//               Update Failure Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Bank Details Edit Modal */}
//       {showBankDetailsModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseBankDetailsModal} />
//             <h3>Edit Bank Details</h3>
//             <div className="form-group">
//               <label htmlFor="bankName">Bank Name:</label>
//               <input
//                 type="text"
//                 id="bankName"
//                 value={newBankDetails.bankName}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, bankName: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="accountNumber">Account Number:</label>
//               <input
//                 type="text"
//                 id="accountNumber"
//                 value={newBankDetails.accountNumber}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, accountNumber: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="ifscCode">IFSC Code:</label>
//               <input
//                 type="text"
//                 id="ifscCode"
//                 value={newBankDetails.ifscCode}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, ifscCode: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="accountName">Account Name:</label>
//               <input
//                 type="text"
//                 id="accountName"
//                 value={newBankDetails.accountName}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, accountName: e.target.value }))}
//               />
//             </div>
//             {bankDetailsError && <p className="error-message">{bankDetailsError}</p>}
//             <button className="submit-btn" onClick={handleSubmitBankDetailsChange}>
//               Update Bank Details
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Wallet Address Edit Modal */}
//       {showWalletAddressModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseWalletAddressModal} />
//             <h3>Edit Wallet Address</h3>
//             <div className="form-group">
//               <label htmlFor="newWalletAddress">New Wallet Address:</label>
//               <input
//                 type="text"
//                 id="newWalletAddress"
//                 value={newWalletAddress}
//                 onChange={(e) => setNewWalletAddress(e.target.value)}
//               />
//             </div>
//             {walletAddressError && <p className="error-message">{walletAddressError}</p>}
//             <button className="submit-btn" onClick={handleSubmitWalletAddressChange}>
//               Update Wallet Address
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;


// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa';

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '',
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '',
//     refundFee: '',
//     chargebackFee: '',
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '',
//     payinCallbackURL: '',
//     successRedirectURL: '',
//     failureRedirectURL: '',
//     bankName: '',
//     accountNumber: '',
//     ifscCode: '',
//     accountName: '',
//     walletAddress: '',
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // State variables for PayIn Callback URL modal
//   const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
//   const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
//   const [payinCallbackError, setPayinCallbackError] = useState('');

//   // State variables for Success Redirect URL modal
//   const [showSuccessRedirectModal, setShowSuccessRedirectModal] = useState(false);
//   const [newSuccessRedirectURL, setNewSuccessRedirectURL] = useState('');
//   const [successRedirectError, setSuccessRedirectError] = useState('');

//   // State variables for Failure Redirect URL modal
//   const [showFailureRedirectModal, setShowFailureRedirectModal] = useState(false);
//   const [newFailureRedirectURL, setNewFailureRedirectURL] = useState('');
//   const [failureRedirectError, setFailureRedirectError] = useState('');

//   // State variables for Bank Details modal
//   const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
//   const [newBankDetails, setNewBankDetails] = useState({
//     bankName: '',
//     accountNumber: '',
//     ifscCode: '',
//     accountName: '',
//   });
//   const [bankDetailsError, setBankDetailsError] = useState('');

//   // State variables for Wallet Address modal
//   const [showWalletAddressModal, setShowWalletAddressModal] = useState(false);
//   const [newWalletAddress, setNewWalletAddress] = useState('');
//   const [walletAddressError, setWalletAddressError] = useState('');

//   // Fetch profile data from the server
//   useEffect(() => {
//     const fetchProfileData = async () => {
//       try {
//         const mid = localStorage.getItem('merchantId');
//         const response = await fetch(`https://payin.payinfy.com/api/merchant-profile/${mid}/`);
//         if (response.ok) {
//           const result = await response.json();
//           const settlementPeriod = result.settlement_period;
//           const updatedProfileData = {
//             name: result.merchant_name || '',
//             email: result.merchant_email || '',
//             mid: result.merchant_id || '',
//             salt: result.salt || '',
//             settlementPeriod: settlementPeriod !== null ? settlementPeriod : '',
//             refundFee: result.refund_fee || '',
//             chargebackFee: result.chargeback_fee || '',
//             payoutSecretKey: '', // Will fetch next
//             payoutCallbackURL: result.payout_callback_url || '',
//             payinSecretKey: result.payin_secret_key || '',
//             payinCallbackURL: result.payin_callback_url || '',
//             successRedirectURL: result.success_redirect_url || '',
//             failureRedirectURL: result.failure_redirect_url || '',
//             bankName: result.bank_name || '',
//             accountNumber: result.account_number || '',
//             ifscCode: result.ifsc_code || '',
//             accountName: result.account_name || '',
//             walletAddress: result.wallet_address || '',
//           };

//           // Fetch payoutSecretKey from merchants project
//           const merchantResponse = await fetch(`https://merchant.payinfy.com/merchants/merchant-profile/${mid}/`);
//           if (merchantResponse.ok) {
//             const merchantResult = await merchantResponse.json();
//             updatedProfileData.payoutSecretKey = merchantResult.payout_secret_key || '';
//           } else {
//             console.error('Failed to fetch payout secret key from merchants project');
//           }

//           setProfileData(updatedProfileData);

//           // Update localStorage
//           for (const [key, value] of Object.entries(updatedProfileData)) {
//             localStorage.setItem(key, value);
//           }
//         } else {
//           console.error('Failed to fetch profile data');
//         }
//       } catch (error) {
//         console.error('Error fetching profile data:', error);
//       }
//     };

//     fetchProfileData();
//   }, []);

//   // Remove the useEffect that fetches the payout secret key from the payouts project
//   // since we're now fetching it directly from the merchants project.

//   // Function to handle clicking the password edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the password modal
//   const handleClosePasswordModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(`https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           old_password: oldPassword,
//           new_password: newPassword,
//         }),
//       });

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleClosePasswordModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   // Function to handle clicking the PayIn Callback URL edit icon
//   const handlePayinCallbackEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowPayinCallbackModal(true);
//     setNewPayinCallbackURL(profileData.payinCallbackURL); // Initialize with current value
//   };

//   // Function to handle closing the PayIn Callback URL modal
//   const handleClosePayinCallbackModal = () => {
//     setShowPayinCallbackModal(false);
//     setNewPayinCallbackURL('');
//     setPayinCallbackError('');
//   };

//   // Function to submit the PayIn Callback URL change
//   const handleSubmitPayinCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payin_callback_url: newPayinCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('PayIn Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payinCallbackURL: newPayinCallbackURL,
//         }));
//         localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
//         handleClosePayinCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
//       }
//     } catch (error) {
//       setPayinCallbackError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Success Redirect URL edit icon
//   const handleSuccessRedirectEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowSuccessRedirectModal(true);
//     setNewSuccessRedirectURL(profileData.successRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Success Redirect URL modal
//   const handleCloseSuccessRedirectModal = () => {
//     setShowSuccessRedirectModal(false);
//     setNewSuccessRedirectURL('');
//     setSuccessRedirectError('');
//   };

//   // Function to submit the Success Redirect URL change
//   const handleSubmitSuccessRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           success_redirect_url: newSuccessRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Success Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           successRedirectURL: newSuccessRedirectURL,
//         }));
//         localStorage.setItem('successRedirectURL', newSuccessRedirectURL);
//         handleCloseSuccessRedirectModal();
//       } else {
//         const result = await response.json();
//         setSuccessRedirectError(result.message || 'Failed to update Success Redirect URL');
//       }
//     } catch (error) {
//       setSuccessRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Failure Redirect URL edit icon
//   const handleFailureRedirectEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowFailureRedirectModal(true);
//     setNewFailureRedirectURL(profileData.failureRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Failure Redirect URL modal
//   const handleCloseFailureRedirectModal = () => {
//     setShowFailureRedirectModal(false);
//     setNewFailureRedirectURL('');
//     setFailureRedirectError('');
//   };

//   // Function to submit the Failure Redirect URL change
//   const handleSubmitFailureRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           failure_redirect_url: newFailureRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Failure Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           failureRedirectURL: newFailureRedirectURL,
//         }));
//         localStorage.setItem('failureRedirectURL', newFailureRedirectURL);
//         handleCloseFailureRedirectModal();
//       } else {
//         const result = await response.json();
//         setFailureRedirectError(result.message || 'Failed to update Failure Redirect URL');
//       }
//     } catch (error) {
//       setFailureRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Bank Details edit icon
//   const handleBankDetailsEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowBankDetailsModal(true);
//     setNewBankDetails({
//       bankName: profileData.bankName || '',
//       accountNumber: profileData.accountNumber || '',
//       ifscCode: profileData.ifscCode || '',
//       accountName: profileData.accountName || '',
//     });
//   };

//   // Function to handle closing the Bank Details modal
//   const handleCloseBankDetailsModal = () => {
//     setShowBankDetailsModal(false);
//     setNewBankDetails({
//       bankName: '',
//       accountNumber: '',
//       ifscCode: '',
//       accountName: '',
//     });
//     setBankDetailsError('');
//   };

//   // Function to submit the Bank Details change
//   const handleSubmitBankDetailsChange = async () => {
//     try {
//       const { bankName, accountNumber, ifscCode, accountName } = newBankDetails;
//       if (!bankName || !accountNumber || !ifscCode || !accountName) {
//         setBankDetailsError('All fields are required');
//         return;
//       }

//       const response = await fetch(`https://payin.payinfy.com/api/update-bank-details/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           bank_name: bankName,
//           account_number: accountNumber,
//           ifsc_code: ifscCode,
//           account_name: accountName,
//         }),
//       });

//       if (response.ok) {
//         alert('Bank details updated successfully');
//         setProfileData((prevData) => ({
//           ...prevData,
//           bankName,
//           accountNumber,
//           ifscCode,
//           accountName,
//         }));
//         localStorage.setItem('bankName', bankName);
//         localStorage.setItem('accountNumber', accountNumber);
//         localStorage.setItem('ifscCode', ifscCode);
//         localStorage.setItem('accountName', accountName);
//         handleCloseBankDetailsModal();
//       } else {
//         const result = await response.json();
//         setBankDetailsError(result.message || 'Failed to update bank details');
//       }
//     } catch (error) {
//       setBankDetailsError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Wallet Address edit icon
//   const handleWalletAddressEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowWalletAddressModal(true);
//     setNewWalletAddress(profileData.walletAddress || '');
//   };

//   // Function to handle closing the Wallet Address modal
//   const handleCloseWalletAddressModal = () => {
//     setShowWalletAddressModal(false);
//     setNewWalletAddress('');
//     setWalletAddressError('');
//   };

//   // Function to submit the Wallet Address change
//   const handleSubmitWalletAddressChange = async () => {
//     try {
//       if (!newWalletAddress) {
//         setWalletAddressError('Wallet address is required');
//         return;
//       }

//       const response = await fetch(`https://payin.payinfy.com/api/update-wallet-address/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ wallet_address: newWalletAddress }),
//       });

//       if (response.ok) {
//         alert('Wallet address updated successfully');
//         setProfileData((prevData) => ({
//           ...prevData,
//           walletAddress: newWalletAddress,
//         }));
//         localStorage.setItem('walletAddress', newWalletAddress);
//         handleCloseWalletAddressModal();
//       } else {
//         const result = await response.json();
//         setWalletAddressError(result.message || 'Failed to update wallet address');
//       }
//     } catch (error) {
//       setWalletAddressError('An error occurred');
//     }
//   };

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Bank Details Field with Edit Icon */}
//           <div className="form-group bank-details-group">
//             <label htmlFor="bankDetails">Bank Details:</label>
//             <div className="bank-details-field">
//               <input
//                 type="text"
//                 id="bankDetails"
//                 name="bankDetails"
//                 value={
//                   profileData.bankName
//                     ? `${profileData.bankName}, ${profileData.accountNumber}`
//                     : 'Not provided'
//                 }
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleBankDetailsEditClick} />
//             </div>
//           </div>

//           {/* Wallet Address Field with Edit Icon */}
//           <div className="form-group wallet-address-group">
//             <label htmlFor="walletAddress">Wallet Address:</label>
//             <div className="wallet-address-field">
//               <input
//                 type="text"
//                 id="walletAddress"
//                 name="walletAddress"
//                 value={profileData.walletAddress || 'Not provided'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleWalletAddressEditClick} />
//             </div>
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod !== '' ? `T + ${profileData.settlementPeriod} days` : 'N/A'
//               }
//               readOnly
//             />
//           </div>

//           {/* Refund Fee Field */}
//           <div className="form-group">
//             <label htmlFor="refundFee">Refund Fee:</label>
//             <input
//               type="text"
//               id="refundFee"
//               name="refundFee"
//               value={profileData.refundFee !== '' ? `${profileData.refundFee}` : 'N/A'}
//               readOnly
//             />
//           </div>

//           {/* Chargeback Fee Field */}
//           <div className="form-group">
//             <label htmlFor="chargebackFee">Chargeback Fee:</label>
//             <input
//               type="text"
//               id="chargebackFee"
//               name="chargebackFee"
//               value={profileData.chargebackFee !== '' ? `${profileData.chargebackFee}` : 'N/A'}
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayOut Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayIn Callback URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payinCallbackURL"
//                 name="payinCallbackURL"
//                 value={profileData.payinCallbackURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
//             </div>
//           </div>

//           {/* Success Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="successRedirectURL">Success Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="successRedirectURL"
//                 name="successRedirectURL"
//                 value={profileData.successRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleSuccessRedirectEditClick} />
//             </div>
//           </div>

//           {/* Failure Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="failureRedirectURL">Failure Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="failureRedirectURL"
//                 name="failureRedirectURL"
//                 value={profileData.failureRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleFailureRedirectEditClick} />
//             </div>
//           </div>
//         </form>
//       </div>

//       {/* Modals */}
//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}

//       {/* PayIn Callback URL Edit Modal */}
//       {showPayinCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
//             <h3>Edit PayIn Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayinCallbackURL"
//                 value={newPayinCallbackURL}
//                 onChange={(e) => setNewPayinCallbackURL(e.target.value)}
//               />
//             </div>
//             {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Success Redirect URL Edit Modal */}
//       {showSuccessRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseSuccessRedirectModal} />
//             <h3>Edit Success Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newSuccessRedirectURL">New Success Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newSuccessRedirectURL"
//                 value={newSuccessRedirectURL}
//                 onChange={(e) => setNewSuccessRedirectURL(e.target.value)}
//               />
//             </div>
//             {successRedirectError && <p className="error-message">{successRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitSuccessRedirectChange}>
//               Update Success Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Failure Redirect URL Edit Modal */}
//       {showFailureRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseFailureRedirectModal} />
//             <h3>Edit Failure Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newFailureRedirectURL">New Failure Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newFailureRedirectURL"
//                 value={newFailureRedirectURL}
//                 onChange={(e) => setNewFailureRedirectURL(e.target.value)}
//               />
//             </div>
//             {failureRedirectError && <p className="error-message">{failureRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitFailureRedirectChange}>
//               Update Failure Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Bank Details Edit Modal */}
//       {showBankDetailsModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseBankDetailsModal} />
//             <h3>Edit Bank Details</h3>
//             <div className="form-group">
//               <label htmlFor="bankName">Bank Name:</label>
//               <input
//                 type="text"
//                 id="bankName"
//                 value={newBankDetails.bankName}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, bankName: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="accountNumber">Account Number:</label>
//               <input
//                 type="text"
//                 id="accountNumber"
//                 value={newBankDetails.accountNumber}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, accountNumber: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="ifscCode">IFSC Code:</label>
//               <input
//                 type="text"
//                 id="ifscCode"
//                 value={newBankDetails.ifscCode}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, ifscCode: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="accountName">Account Name:</label>
//               <input
//                 type="text"
//                 id="accountName"
//                 value={newBankDetails.accountName}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, accountName: e.target.value }))}
//               />
//             </div>
//             {bankDetailsError && <p className="error-message">{bankDetailsError}</p>}
//             <button className="submit-btn" onClick={handleSubmitBankDetailsChange}>
//               Update Bank Details
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Wallet Address Edit Modal */}
//       {showWalletAddressModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseWalletAddressModal} />
//             <h3>Edit Wallet Address</h3>
//             <div className="form-group">
//               <label htmlFor="newWalletAddress">New Wallet Address:</label>
//               <input
//                 type="text"
//                 id="newWalletAddress"
//                 value={newWalletAddress}
//                 onChange={(e) => setNewWalletAddress(e.target.value)}
//               />
//             </div>
//             {walletAddressError && <p className="error-message">{walletAddressError}</p>}
//             <button className="submit-btn" onClick={handleSubmitWalletAddressChange}>
//               Update Wallet Address
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;



// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa';

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '',
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '',
//     refundFee: '',
//     chargebackFee: '',
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '',
//     payinCallbackURL: '',
//     successRedirectURL: '',
//     failureRedirectURL: '',
//     bankName: '',
//     accountNumber: '',
//     ifscCode: '',
//     accountName: '',
//     walletAddress: '',
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // State variables for PayIn Callback URL modal
//   const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
//   const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
//   const [payinCallbackError, setPayinCallbackError] = useState('');

//   // State variables for Success Redirect URL modal
//   const [showSuccessRedirectModal, setShowSuccessRedirectModal] = useState(false);
//   const [newSuccessRedirectURL, setNewSuccessRedirectURL] = useState('');
//   const [successRedirectError, setSuccessRedirectError] = useState('');

//   // State variables for Failure Redirect URL modal
//   const [showFailureRedirectModal, setShowFailureRedirectModal] = useState(false);
//   const [newFailureRedirectURL, setNewFailureRedirectURL] = useState('');
//   const [failureRedirectError, setFailureRedirectError] = useState('');

//   // State variables for Bank Details modal
//   const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
//   const [newBankDetails, setNewBankDetails] = useState({
//     bankName: '',
//     accountNumber: '',
//     ifscCode: '',
//     accountName: '',
//   });
//   const [bankDetailsError, setBankDetailsError] = useState('');

//   // State variables for Wallet Address modal
//   const [showWalletAddressModal, setShowWalletAddressModal] = useState(false);
//   const [newWalletAddress, setNewWalletAddress] = useState('');
//   const [walletAddressError, setWalletAddressError] = useState('');

//   // Fetch profile data from the server
//   useEffect(() => {
//     const fetchProfileData = async () => {
//       try {
//         const mid = localStorage.getItem('merchantId');
//         const response = await fetch(`https://payin.payinfy.com/api/merchant-profile/${mid}/`);
//         if (response.ok) {
//           const result = await response.json();
//           const settlementPeriod = result.settlement_period;
//           const updatedProfileData = {
//             name: result.merchant_name || '',
//             email: result.merchant_email || '',
//             mid: result.merchant_id || '',
//             salt: '', // Will fetch from merchants project
//             settlementPeriod: settlementPeriod !== null ? settlementPeriod : '',
//             refundFee: result.refund_fee || '',
//             chargebackFee: result.chargeback_fee || '',
//             payoutSecretKey: '', // Will fetch from merchants project
//             payoutCallbackURL: result.payout_callback_url || '',
//             payinSecretKey: result.payin_secret_key || '',
//             payinCallbackURL: result.payin_callback_url || '',
//             successRedirectURL: result.success_redirect_url || '',
//             failureRedirectURL: result.failure_redirect_url || '',
//             bankName: result.bank_name || '',
//             accountNumber: result.account_number || '',
//             ifscCode: result.ifsc_code || '',
//             accountName: result.account_name || '',
//             walletAddress: result.wallet_address || '',
//           };

//           // Fetch 'salt' and 'payoutSecretKey' from merchants project
//           const merchantResponse = await fetch(`https://merchant.payinfy.com/merchants/merchant-profile/${mid}/`);
//           if (merchantResponse.ok) {
//             const merchantResult = await merchantResponse.json();
//             updatedProfileData.payoutSecretKey = merchantResult.payout_secret_key || '';
//             updatedProfileData.salt = merchantResult.salt || '';
//           } else {
//             console.error('Failed to fetch data from merchants project');
//           }

//           setProfileData(updatedProfileData);

//           // Update localStorage
//           for (const [key, value] of Object.entries(updatedProfileData)) {
//             localStorage.setItem(key, value);
//           }
//         } else {
//           console.error('Failed to fetch profile data');
//         }
//       } catch (error) {
//         console.error('Error fetching profile data:', error);
//       }
//     };

//     fetchProfileData();
//   }, []);

//   // Function to handle clicking the password edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the password modal
//   const handleClosePasswordModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(`https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           old_password: oldPassword,
//           new_password: newPassword,
//         }),
//       });

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleClosePasswordModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   // Function to handle clicking the PayIn Callback URL edit icon
//   const handlePayinCallbackEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowPayinCallbackModal(true);
//     setNewPayinCallbackURL(profileData.payinCallbackURL); // Initialize with current value
//   };

//   // Function to handle closing the PayIn Callback URL modal
//   const handleClosePayinCallbackModal = () => {
//     setShowPayinCallbackModal(false);
//     setNewPayinCallbackURL('');
//     setPayinCallbackError('');
//   };

//   // Function to submit the PayIn Callback URL change
//   const handleSubmitPayinCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payin_callback_url: newPayinCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('PayIn Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payinCallbackURL: newPayinCallbackURL,
//         }));
//         localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
//         handleClosePayinCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
//       }
//     } catch (error) {
//       setPayinCallbackError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Success Redirect URL edit icon
//   const handleSuccessRedirectEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowSuccessRedirectModal(true);
//     setNewSuccessRedirectURL(profileData.successRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Success Redirect URL modal
//   const handleCloseSuccessRedirectModal = () => {
//     setShowSuccessRedirectModal(false);
//     setNewSuccessRedirectURL('');
//     setSuccessRedirectError('');
//   };

//   // Function to submit the Success Redirect URL change
//   const handleSubmitSuccessRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           success_redirect_url: newSuccessRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Success Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           successRedirectURL: newSuccessRedirectURL,
//         }));
//         localStorage.setItem('successRedirectURL', newSuccessRedirectURL);
//         handleCloseSuccessRedirectModal();
//       } else {
//         const result = await response.json();
//         setSuccessRedirectError(result.message || 'Failed to update Success Redirect URL');
//       }
//     } catch (error) {
//       setSuccessRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Failure Redirect URL edit icon
//   const handleFailureRedirectEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowFailureRedirectModal(true);
//     setNewFailureRedirectURL(profileData.failureRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Failure Redirect URL modal
//   const handleCloseFailureRedirectModal = () => {
//     setShowFailureRedirectModal(false);
//     setNewFailureRedirectURL('');
//     setFailureRedirectError('');
//   };

//   // Function to submit the Failure Redirect URL change
//   const handleSubmitFailureRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           failure_redirect_url: newFailureRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Failure Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           failureRedirectURL: newFailureRedirectURL,
//         }));
//         localStorage.setItem('failureRedirectURL', newFailureRedirectURL);
//         handleCloseFailureRedirectModal();
//       } else {
//         const result = await response.json();
//         setFailureRedirectError(result.message || 'Failed to update Failure Redirect URL');
//       }
//     } catch (error) {
//       setFailureRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Bank Details edit icon
//   const handleBankDetailsEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowBankDetailsModal(true);
//     setNewBankDetails({
//       bankName: profileData.bankName || '',
//       accountNumber: profileData.accountNumber || '',
//       ifscCode: profileData.ifscCode || '',
//       accountName: profileData.accountName || '',
//     });
//   };

//   // Function to handle closing the Bank Details modal
//   const handleCloseBankDetailsModal = () => {
//     setShowBankDetailsModal(false);
//     setNewBankDetails({
//       bankName: '',
//       accountNumber: '',
//       ifscCode: '',
//       accountName: '',
//     });
//     setBankDetailsError('');
//   };

//   // Function to submit the Bank Details change
//   const handleSubmitBankDetailsChange = async () => {
//     try {
//       const { bankName, accountNumber, ifscCode, accountName } = newBankDetails;
//       if (!bankName || !accountNumber || !ifscCode || !accountName) {
//         setBankDetailsError('All fields are required');
//         return;
//       }

//       const response = await fetch(`https://payin.payinfy.com/api/update-bank-details/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           bank_name: bankName,
//           account_number: accountNumber,
//           ifsc_code: ifscCode,
//           account_name: accountName,
//         }),
//       });

//       if (response.ok) {
//         alert('Bank details updated successfully');
//         setProfileData((prevData) => ({
//           ...prevData,
//           bankName,
//           accountNumber,
//           ifscCode,
//           accountName,
//         }));
//         localStorage.setItem('bankName', bankName);
//         localStorage.setItem('accountNumber', accountNumber);
//         localStorage.setItem('ifscCode', ifscCode);
//         localStorage.setItem('accountName', accountName);
//         handleCloseBankDetailsModal();
//       } else {
//         const result = await response.json();
//         setBankDetailsError(result.message || 'Failed to update bank details');
//       }
//     } catch (error) {
//       setBankDetailsError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Wallet Address edit icon
//   const handleWalletAddressEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowWalletAddressModal(true);
//     setNewWalletAddress(profileData.walletAddress || '');
//   };

//   // Function to handle closing the Wallet Address modal
//   const handleCloseWalletAddressModal = () => {
//     setShowWalletAddressModal(false);
//     setNewWalletAddress('');
//     setWalletAddressError('');
//   };

//   // Function to submit the Wallet Address change
//   const handleSubmitWalletAddressChange = async () => {
//     try {
//       if (!newWalletAddress) {
//         setWalletAddressError('Wallet address is required');
//         return;
//       }

//       const response = await fetch(`https://payin.payinfy.com/api/update-wallet-address/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ wallet_address: newWalletAddress }),
//       });

//       if (response.ok) {
//         alert('Wallet address updated successfully');
//         setProfileData((prevData) => ({
//           ...prevData,
//           walletAddress: newWalletAddress,
//         }));
//         localStorage.setItem('walletAddress', newWalletAddress);
//         handleCloseWalletAddressModal();
//       } else {
//         const result = await response.json();
//         setWalletAddressError(result.message || 'Failed to update wallet address');
//       }
//     } catch (error) {
//       setWalletAddressError('An error occurred');
//     }
//   };

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Bank Details Field with Edit Icon */}
//           <div className="form-group bank-details-group">
//             <label htmlFor="bankDetails">Bank Details:</label>
//             <div className="bank-details-field">
//               <input
//                 type="text"
//                 id="bankDetails"
//                 name="bankDetails"
//                 value={
//                   profileData.bankName
//                     ? `${profileData.bankName}, ${profileData.accountNumber}`
//                     : 'Not provided'
//                 }
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleBankDetailsEditClick} />
//             </div>
//           </div>

//           {/* Wallet Address Field with Edit Icon */}
//           <div className="form-group wallet-address-group">
//             <label htmlFor="walletAddress">Wallet Address:</label>
//             <div className="wallet-address-field">
//               <input
//                 type="text"
//                 id="walletAddress"
//                 name="walletAddress"
//                 value={profileData.walletAddress || 'Not provided'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleWalletAddressEditClick} />
//             </div>
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod !== '' ? `T + ${profileData.settlementPeriod} days` : 'N/A'
//               }
//               readOnly
//             />
//           </div>

//           {/* Refund Fee Field */}
//           <div className="form-group">
//             <label htmlFor="refundFee">Refund Fee:</label>
//             <input
//               type="text"
//               id="refundFee"
//               name="refundFee"
//               value={profileData.refundFee !== '' ? `${profileData.refundFee}` : 'N/A'}
//               readOnly
//             />
//           </div>

//           {/* Chargeback Fee Field */}
//           <div className="form-group">
//             <label htmlFor="chargebackFee">Chargeback Fee:</label>
//             <input
//               type="text"
//               id="chargebackFee"
//               name="chargebackFee"
//               value={profileData.chargebackFee !== '' ? `${profileData.chargebackFee}` : 'N/A'}
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayOut Callback URL */}
//           <div className="form-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <input
//               type="text"
//               id="payoutCallbackURL"
//               name="payoutCallbackURL"
//               value={profileData.payoutCallbackURL || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayIn Secret Key */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayIn Callback URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payinCallbackURL"
//                 name="payinCallbackURL"
//                 value={profileData.payinCallbackURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
//             </div>
//           </div>

//           {/* Success Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="successRedirectURL">Success Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="successRedirectURL"
//                 name="successRedirectURL"
//                 value={profileData.successRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleSuccessRedirectEditClick} />
//             </div>
//           </div>

//           {/* Failure Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="failureRedirectURL">Failure Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="failureRedirectURL"
//                 name="failureRedirectURL"
//                 value={profileData.failureRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleFailureRedirectEditClick} />
//             </div>
//           </div>
//         </form>
//       </div>

//       {/* Modals */}
//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}

//       {/* PayIn Callback URL Edit Modal */}
//       {showPayinCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
//             <h3>Edit PayIn Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayinCallbackURL"
//                 value={newPayinCallbackURL}
//                 onChange={(e) => setNewPayinCallbackURL(e.target.value)}
//               />
//             </div>
//             {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Success Redirect URL Edit Modal */}
//       {showSuccessRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseSuccessRedirectModal} />
//             <h3>Edit Success Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newSuccessRedirectURL">New Success Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newSuccessRedirectURL"
//                 value={newSuccessRedirectURL}
//                 onChange={(e) => setNewSuccessRedirectURL(e.target.value)}
//               />
//             </div>
//             {successRedirectError && <p className="error-message">{successRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitSuccessRedirectChange}>
//               Update Success Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Failure Redirect URL Edit Modal */}
//       {showFailureRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseFailureRedirectModal} />
//             <h3>Edit Failure Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newFailureRedirectURL">New Failure Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newFailureRedirectURL"
//                 value={newFailureRedirectURL}
//                 onChange={(e) => setNewFailureRedirectURL(e.target.value)}
//               />
//             </div>
//             {failureRedirectError && <p className="error-message">{failureRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitFailureRedirectChange}>
//               Update Failure Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Bank Details Edit Modal */}
//       {showBankDetailsModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseBankDetailsModal} />
//             <h3>Edit Bank Details</h3>
//             <div className="form-group">
//               <label htmlFor="bankName">Bank Name:</label>
//               <input
//                 type="text"
//                 id="bankName"
//                 value={newBankDetails.bankName}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, bankName: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="accountNumber">Account Number:</label>
//               <input
//                 type="text"
//                 id="accountNumber"
//                 value={newBankDetails.accountNumber}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, accountNumber: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="ifscCode">IFSC Code:</label>
//               <input
//                 type="text"
//                 id="ifscCode"
//                 value={newBankDetails.ifscCode}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, ifscCode: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="accountName">Account Name:</label>
//               <input
//                 type="text"
//                 id="accountName"
//                 value={newBankDetails.accountName}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, accountName: e.target.value }))}
//               />
//             </div>
//             {bankDetailsError && <p className="error-message">{bankDetailsError}</p>}
//             <button className="submit-btn" onClick={handleSubmitBankDetailsChange}>
//               Update Bank Details
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Wallet Address Edit Modal */}
//       {showWalletAddressModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseWalletAddressModal} />
//             <h3>Edit Wallet Address</h3>
//             <div className="form-group">
//               <label htmlFor="newWalletAddress">New Wallet Address:</label>
//               <input
//                 type="text"
//                 id="newWalletAddress"
//                 value={newWalletAddress}
//                 onChange={(e) => setNewWalletAddress(e.target.value)}
//               />
//             </div>
//             {walletAddressError && <p className="error-message">{walletAddressError}</p>}
//             <button className="submit-btn" onClick={handleSubmitWalletAddressChange}>
//               Update Wallet Address
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;


// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa';

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '',
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '',
//     refundFee: '',
//     chargebackFee: '',
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '',
//     payinCallbackURL: '',
//     successRedirectURL: '',
//     failureRedirectURL: '',
//     bankName: '',
//     accountNumber: '',
//     ifscCode: '',
//     accountName: '',
//     walletAddress: '',
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // State variables for PayIn Callback URL modal
//   const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
//   const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
//   const [payinCallbackError, setPayinCallbackError] = useState('');

//   // State variables for Success Redirect URL modal
//   const [showSuccessRedirectModal, setShowSuccessRedirectModal] = useState(false);
//   const [newSuccessRedirectURL, setNewSuccessRedirectURL] = useState('');
//   const [successRedirectError, setSuccessRedirectError] = useState('');

//   // State variables for Failure Redirect URL modal
//   const [showFailureRedirectModal, setShowFailureRedirectModal] = useState(false);
//   const [newFailureRedirectURL, setNewFailureRedirectURL] = useState('');
//   const [failureRedirectError, setFailureRedirectError] = useState('');

//   // State variables for Bank Details modal
//   const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
//   const [newBankDetails, setNewBankDetails] = useState({
//     bankName: '',
//     accountNumber: '',
//     ifscCode: '',
//     accountName: '',
//   });
//   const [bankDetailsError, setBankDetailsError] = useState('');

//   // State variables for Wallet Address modal
//   const [showWalletAddressModal, setShowWalletAddressModal] = useState(false);
//   const [newWalletAddress, setNewWalletAddress] = useState('');
//   const [walletAddressError, setWalletAddressError] = useState('');

//   // State variables for Payout Callback URL modal
//   const [showPayoutCallbackModal, setShowPayoutCallbackModal] = useState(false);
//   const [newPayoutCallbackURL, setNewPayoutCallbackURL] = useState('');
//   const [payoutCallbackError, setPayoutCallbackError] = useState('');

//   // Fetch profile data from the server
//   useEffect(() => {
//     const fetchProfileData = async () => {
//       try {
//         const mid = localStorage.getItem('merchantId');
//         const response = await fetch(`https://payin.payinfy.com/api/merchant-profile/${mid}/`);
//         if (response.ok) {
//           const result = await response.json();
//           const settlementPeriod = result.settlement_period;
//           const updatedProfileData = {
//             name: result.merchant_name || '',
//             email: result.merchant_email || '',
//             mid: result.merchant_id || '',
//             salt: '', // Will fetch from merchants project
//             settlementPeriod: settlementPeriod !== null ? settlementPeriod : '',
//             refundFee: result.refund_fee || '',
//             chargebackFee: result.chargeback_fee || '',
//             payoutSecretKey: '', // Will fetch from merchants project
//             payoutCallbackURL: result.payout_callback_url || '',
//             payinSecretKey: result.payin_secret_key || '',
//             payinCallbackURL: result.payin_callback_url || '',
//             successRedirectURL: result.success_redirect_url || '',
//             failureRedirectURL: result.failure_redirect_url || '',
//             bankName: result.bank_name || '',
//             accountNumber: result.account_number || '',
//             ifscCode: result.ifsc_code || '',
//             accountName: result.account_name || '',
//             walletAddress: result.wallet_address || '',
//           };

//           // Fetch 'salt' and 'payoutSecretKey' from merchants project
//           const merchantResponse = await fetch(`https://merchant.payinfy.com/merchants/merchant-profile/${mid}/`);
//           if (merchantResponse.ok) {
//             const merchantResult = await merchantResponse.json();
//             updatedProfileData.payoutSecretKey = merchantResult.payout_secret_key || '';
//             updatedProfileData.salt = merchantResult.salt || '';
//           } else {
//             console.error('Failed to fetch data from merchants project');
//           }

//           setProfileData(updatedProfileData);

//           // Update localStorage
//           for (const [key, value] of Object.entries(updatedProfileData)) {
//             localStorage.setItem(key, value);
//           }
//         } else {
//           console.error('Failed to fetch profile data');
//         }
//       } catch (error) {
//         console.error('Error fetching profile data:', error);
//       }
//     };

//     fetchProfileData();
//   }, []);

//   // Function to handle clicking the password edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the password modal
//   const handleClosePasswordModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(`https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           old_password: oldPassword,
//           new_password: newPassword,
//         }),
//       });

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleClosePasswordModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   // Function to handle clicking the PayIn Callback URL edit icon
//   const handlePayinCallbackEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowPayinCallbackModal(true);
//     setNewPayinCallbackURL(profileData.payinCallbackURL); // Initialize with current value
//   };

//   // Function to handle closing the PayIn Callback URL modal
//   const handleClosePayinCallbackModal = () => {
//     setShowPayinCallbackModal(false);
//     setNewPayinCallbackURL('');
//     setPayinCallbackError('');
//   };

//   // Function to submit the PayIn Callback URL change
//   const handleSubmitPayinCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payin_callback_url: newPayinCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('PayIn Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payinCallbackURL: newPayinCallbackURL,
//         }));
//         localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
//         handleClosePayinCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
//       }
//     } catch (error) {
//       setPayinCallbackError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Success Redirect URL edit icon
//   const handleSuccessRedirectEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowSuccessRedirectModal(true);
//     setNewSuccessRedirectURL(profileData.successRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Success Redirect URL modal
//   const handleCloseSuccessRedirectModal = () => {
//     setShowSuccessRedirectModal(false);
//     setNewSuccessRedirectURL('');
//     setSuccessRedirectError('');
//   };

//   // Function to submit the Success Redirect URL change
//   const handleSubmitSuccessRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           success_redirect_url: newSuccessRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Success Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           successRedirectURL: newSuccessRedirectURL,
//         }));
//         localStorage.setItem('successRedirectURL', newSuccessRedirectURL);
//         handleCloseSuccessRedirectModal();
//       } else {
//         const result = await response.json();
//         setSuccessRedirectError(result.message || 'Failed to update Success Redirect URL');
//       }
//     } catch (error) {
//       setSuccessRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Failure Redirect URL edit icon
//   const handleFailureRedirectEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowFailureRedirectModal(true);
//     setNewFailureRedirectURL(profileData.failureRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Failure Redirect URL modal
//   const handleCloseFailureRedirectModal = () => {
//     setShowFailureRedirectModal(false);
//     setNewFailureRedirectURL('');
//     setFailureRedirectError('');
//   };

//   // Function to submit the Failure Redirect URL change
//   const handleSubmitFailureRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           failure_redirect_url: newFailureRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Failure Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           failureRedirectURL: newFailureRedirectURL,
//         }));
//         localStorage.setItem('failureRedirectURL', newFailureRedirectURL);
//         handleCloseFailureRedirectModal();
//       } else {
//         const result = await response.json();
//         setFailureRedirectError(result.message || 'Failed to update Failure Redirect URL');
//       }
//     } catch (error) {
//       setFailureRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Bank Details edit icon
//   const handleBankDetailsEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowBankDetailsModal(true);
//     setNewBankDetails({
//       bankName: profileData.bankName || '',
//       accountNumber: profileData.accountNumber || '',
//       ifscCode: profileData.ifscCode || '',
//       accountName: profileData.accountName || '',
//     });
//   };

//   // Function to handle closing the Bank Details modal
//   const handleCloseBankDetailsModal = () => {
//     setShowBankDetailsModal(false);
//     setNewBankDetails({
//       bankName: '',
//       accountNumber: '',
//       ifscCode: '',
//       accountName: '',
//     });
//     setBankDetailsError('');
//   };

//   // Function to submit the Bank Details change
//   const handleSubmitBankDetailsChange = async () => {
//     try {
//       const { bankName, accountNumber, ifscCode, accountName } = newBankDetails;
//       if (!bankName || !accountNumber || !ifscCode || !accountName) {
//         setBankDetailsError('All fields are required');
//         return;
//       }

//       const response = await fetch(`https://payin.payinfy.com/api/update-bank-details/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           bank_name: bankName,
//           account_number: accountNumber,
//           ifsc_code: ifscCode,
//           account_name: accountName,
//         }),
//       });

//       if (response.ok) {
//         alert('Bank details updated successfully');
//         setProfileData((prevData) => ({
//           ...prevData,
//           bankName,
//           accountNumber,
//           ifscCode,
//           accountName,
//         }));
//         localStorage.setItem('bankName', bankName);
//         localStorage.setItem('accountNumber', accountNumber);
//         localStorage.setItem('ifscCode', ifscCode);
//         localStorage.setItem('accountName', accountName);
//         handleCloseBankDetailsModal();
//       } else {
//         const result = await response.json();
//         setBankDetailsError(result.message || 'Failed to update bank details');
//       }
//     } catch (error) {
//       setBankDetailsError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Wallet Address edit icon
//   const handleWalletAddressEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowWalletAddressModal(true);
//     setNewWalletAddress(profileData.walletAddress || '');
//   };

//   // Function to handle closing the Wallet Address modal
//   const handleCloseWalletAddressModal = () => {
//     setShowWalletAddressModal(false);
//     setNewWalletAddress('');
//     setWalletAddressError('');
//   };

//   // Function to submit the Wallet Address change
//   const handleSubmitWalletAddressChange = async () => {
//     try {
//       if (!newWalletAddress) {
//         setWalletAddressError('Wallet address is required');
//         return;
//       }

//       const response = await fetch(`https://payin.payinfy.com/api/update-wallet-address/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ wallet_address: newWalletAddress }),
//       });

//       if (response.ok) {
//         alert('Wallet address updated successfully');
//         setProfileData((prevData) => ({
//           ...prevData,
//           walletAddress: newWalletAddress,
//         }));
//         localStorage.setItem('walletAddress', newWalletAddress);
//         handleCloseWalletAddressModal();
//       } else {
//         const result = await response.json();
//         setWalletAddressError(result.message || 'Failed to update wallet address');
//       }
//     } catch (error) {
//       setWalletAddressError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Payout Callback URL edit icon
//   const handlePayoutCallbackEditClick = () => {
//     if (!profileData.payoutSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowPayoutCallbackModal(true);
//     setNewPayoutCallbackURL(profileData.payoutCallbackURL || '');
//   };

//   // Function to handle closing the Payout Callback URL modal
//   const handleClosePayoutCallbackModal = () => {
//     setShowPayoutCallbackModal(false);
//     setNewPayoutCallbackURL('');
//     setPayoutCallbackError('');
//   };

//   // Function to submit the Payout Callback URL change
//   const handleSubmitPayoutCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payout.payinfy.com/api/update-payout-callback-url/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payout_callback_url: newPayoutCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Payout Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payoutCallbackURL: newPayoutCallbackURL,
//         }));
//         localStorage.setItem('payoutCallbackURL', newPayoutCallbackURL);
//         handleClosePayoutCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayoutCallbackError(result.message || 'Failed to update Payout Callback URL');
//       }
//     } catch (error) {
//       setPayoutCallbackError('An error occurred');
//     }
//   };

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Bank Details Field with Edit Icon */}
//           <div className="form-group bank-details-group">
//             <label htmlFor="bankDetails">Bank Details:</label>
//             <div className="bank-details-field">
//               <input
//                 type="text"
//                 id="bankDetails"
//                 name="bankDetails"
//                 value={
//                   profileData.bankName
//                     ? `${profileData.bankName}, ${profileData.accountNumber}`
//                     : 'Not provided'
//                 }
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleBankDetailsEditClick} />
//             </div>
//           </div>

//           {/* Wallet Address Field with Edit Icon */}
//           <div className="form-group wallet-address-group">
//             <label htmlFor="walletAddress">Wallet Address:</label>
//             <div className="wallet-address-field">
//               <input
//                 type="text"
//                 id="walletAddress"
//                 name="walletAddress"
//                 value={profileData.walletAddress || 'Not provided'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleWalletAddressEditClick} />
//             </div>
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod !== '' ? `T + ${profileData.settlementPeriod} days` : 'N/A'
//               }
//               readOnly
//             />
//           </div>

//           {/* Refund Fee Field */}
//           <div className="form-group">
//             <label htmlFor="refundFee">Refund Fee:</label>
//             <input
//               type="text"
//               id="refundFee"
//               name="refundFee"
//               value={profileData.refundFee !== '' ? `${profileData.refundFee}` : 'N/A'}
//               readOnly
//             />
//           </div>

//           {/* Chargeback Fee Field */}
//           <div className="form-group">
//             <label htmlFor="chargebackFee">Chargeback Fee:</label>
//             <input
//               type="text"
//               id="chargebackFee"
//               name="chargebackFee"
//               value={profileData.chargebackFee !== '' ? `${profileData.chargebackFee}` : 'N/A'}
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayOut Callback URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payoutCallbackURL"
//                 name="payoutCallbackURL"
//                 value={profileData.payoutCallbackURL || 'Not Available'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayoutCallbackEditClick} />
//             </div>
//           </div>

//           {/* PayIn Secret Key */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayIn Callback URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payinCallbackURL"
//                 name="payinCallbackURL"
//                 value={profileData.payinCallbackURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
//             </div>
//           </div>

//           {/* Success Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="successRedirectURL">Success Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="successRedirectURL"
//                 name="successRedirectURL"
//                 value={profileData.successRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleSuccessRedirectEditClick} />
//             </div>
//           </div>

//           {/* Failure Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="failureRedirectURL">Failure Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="failureRedirectURL"
//                 name="failureRedirectURL"
//                 value={profileData.failureRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleFailureRedirectEditClick} />
//             </div>
//           </div>
//         </form>
//       </div>

//       {/* Modals */}
//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}

//       {/* PayIn Callback URL Edit Modal */}
//       {showPayinCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
//             <h3>Edit PayIn Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayinCallbackURL"
//                 value={newPayinCallbackURL}
//                 onChange={(e) => setNewPayinCallbackURL(e.target.value)}
//               />
//             </div>
//             {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Success Redirect URL Edit Modal */}
//       {showSuccessRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseSuccessRedirectModal} />
//             <h3>Edit Success Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newSuccessRedirectURL">New Success Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newSuccessRedirectURL"
//                 value={newSuccessRedirectURL}
//                 onChange={(e) => setNewSuccessRedirectURL(e.target.value)}
//               />
//             </div>
//             {successRedirectError && <p className="error-message">{successRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitSuccessRedirectChange}>
//               Update Success Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Failure Redirect URL Edit Modal */}
//       {showFailureRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseFailureRedirectModal} />
//             <h3>Edit Failure Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newFailureRedirectURL">New Failure Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newFailureRedirectURL"
//                 value={newFailureRedirectURL}
//                 onChange={(e) => setNewFailureRedirectURL(e.target.value)}
//               />
//             </div>
//             {failureRedirectError && <p className="error-message">{failureRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitFailureRedirectChange}>
//               Update Failure Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Bank Details Edit Modal */}
//       {showBankDetailsModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseBankDetailsModal} />
//             <h3>Edit Bank Details</h3>
//             <div className="form-group">
//               <label htmlFor="bankName">Bank Name:</label>
//               <input
//                 type="text"
//                 id="bankName"
//                 value={newBankDetails.bankName}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, bankName: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="accountNumber">Account Number:</label>
//               <input
//                 type="text"
//                 id="accountNumber"
//                 value={newBankDetails.accountNumber}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, accountNumber: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="ifscCode">IFSC Code:</label>
//               <input
//                 type="text"
//                 id="ifscCode"
//                 value={newBankDetails.ifscCode}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, ifscCode: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="accountName">Account Name:</label>
//               <input
//                 type="text"
//                 id="accountName"
//                 value={newBankDetails.accountName}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, accountName: e.target.value }))}
//               />
//             </div>
//             {bankDetailsError && <p className="error-message">{bankDetailsError}</p>}
//             <button className="submit-btn" onClick={handleSubmitBankDetailsChange}>
//               Update Bank Details
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Wallet Address Edit Modal */}
//       {showWalletAddressModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseWalletAddressModal} />
//             <h3>Edit Wallet Address</h3>
//             <div className="form-group">
//               <label htmlFor="newWalletAddress">New Wallet Address:</label>
//               <input
//                 type="text"
//                 id="newWalletAddress"
//                 value={newWalletAddress}
//                 onChange={(e) => setNewWalletAddress(e.target.value)}
//               />
//             </div>
//             {walletAddressError && <p className="error-message">{walletAddressError}</p>}
//             <button className="submit-btn" onClick={handleSubmitWalletAddressChange}>
//               Update Wallet Address
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Payout Callback URL Edit Modal */}
//       {showPayoutCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayoutCallbackModal} />
//             <h3>Edit Payout Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayoutCallbackURL">New Payout Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayoutCallbackURL"
//                 value={newPayoutCallbackURL}
//                 onChange={(e) => setNewPayoutCallbackURL(e.target.value)}
//               />
//             </div>
//             {payoutCallbackError && <p className="error-message">{payoutCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayoutCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;



// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa';

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '',
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '',
//     refundFee: '',
//     chargebackFee: '',
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '',
//     payinCallbackURL: '',
//     successRedirectURL: '',
//     failureRedirectURL: '',
//     bankName: '',
//     accountNumber: '',
//     ifscCode: '',
//     accountName: '',
//     walletAddress: '',
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // State variables for PayIn Callback URL modal
//   const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
//   const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
//   const [payinCallbackError, setPayinCallbackError] = useState('');

//   // State variables for Success Redirect URL modal
//   const [showSuccessRedirectModal, setShowSuccessRedirectModal] = useState(false);
//   const [newSuccessRedirectURL, setNewSuccessRedirectURL] = useState('');
//   const [successRedirectError, setSuccessRedirectError] = useState('');

//   // State variables for Failure Redirect URL modal
//   const [showFailureRedirectModal, setShowFailureRedirectModal] = useState(false);
//   const [newFailureRedirectURL, setNewFailureRedirectURL] = useState('');
//   const [failureRedirectError, setFailureRedirectError] = useState('');

//   // State variables for Bank Details modal
//   const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
//   const [newBankDetails, setNewBankDetails] = useState({
//     bankName: '',
//     accountNumber: '',
//     ifscCode: '',
//     accountName: '',
//   });
//   const [bankDetailsError, setBankDetailsError] = useState('');

//   // State variables for Wallet Address modal
//   const [showWalletAddressModal, setShowWalletAddressModal] = useState(false);
//   const [newWalletAddress, setNewWalletAddress] = useState('');
//   const [walletAddressError, setWalletAddressError] = useState('');

//   // State variables for Payout Callback URL modal
//   const [showPayoutCallbackModal, setShowPayoutCallbackModal] = useState(false);
//   const [newPayoutCallbackURL, setNewPayoutCallbackURL] = useState('');
//   const [payoutCallbackError, setPayoutCallbackError] = useState('');

//   // Fetch profile data from the server
//   useEffect(() => {
//     const fetchProfileData = async () => {
//       try {
//         const mid = localStorage.getItem('merchantId');
//         const response = await fetch(`https://payin.payinfy.com/api/merchant-profile/${mid}/`);
//         if (response.ok) {
//           const result = await response.json();
//           const settlementPeriod = result.settlement_period;
//           const updatedProfileData = {
//             name: result.merchant_name || '',
//             email: result.merchant_email || '',
//             mid: result.merchant_id || '',
//             salt: '', // Will fetch from merchants project
//             settlementPeriod: settlementPeriod !== null ? settlementPeriod : '',
//             refundFee: result.refund_fee || '',
//             chargebackFee: result.chargeback_fee || '',
//             payoutSecretKey: '', // Will fetch from merchants project
//             payoutCallbackURL: result.payout_callback_url || '',
//             payinSecretKey: result.payin_secret_key || '',
//             payinCallbackURL: result.payin_callback_url || '',
//             successRedirectURL: result.success_redirect_url || '',
//             failureRedirectURL: result.failure_redirect_url || '',
//             bankName: result.bank_name || '',
//             accountNumber: result.account_number || '',
//             ifscCode: result.ifsc_code || '',
//             accountName: result.account_name || '',
//             walletAddress: result.wallet_address || '',
//           };

//           // Fetch 'salt' and 'payoutSecretKey' from merchants project
//           const merchantResponse = await fetch(`https://merchant.payinfy.com/merchants/merchant-profile/${mid}/`);
//           if (merchantResponse.ok) {
//             const merchantResult = await merchantResponse.json();
//             updatedProfileData.payoutSecretKey = merchantResult.payout_secret_key || '';
//             updatedProfileData.salt = merchantResult.salt || '';
//           } else {
//             console.error('Failed to fetch data from merchants project');
//           }

//           setProfileData(updatedProfileData);

//           // Update localStorage
//           for (const [key, value] of Object.entries(updatedProfileData)) {
//             localStorage.setItem(key, value);
//           }
//         } else {
//           console.error('Failed to fetch profile data');
//         }
//       } catch (error) {
//         console.error('Error fetching profile data:', error);
//       }
//     };

//     fetchProfileData();
//   }, []);

//   // Function to handle clicking the password edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the password modal
//   const handleClosePasswordModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(`https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           old_password: oldPassword,
//           new_password: newPassword,
//         }),
//       });

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleClosePasswordModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   // Function to handle clicking the PayIn Callback URL edit icon
//   const handlePayinCallbackEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowPayinCallbackModal(true);
//     setNewPayinCallbackURL(profileData.payinCallbackURL); // Initialize with current value
//   };

//   // Function to handle closing the PayIn Callback URL modal
//   const handleClosePayinCallbackModal = () => {
//     setShowPayinCallbackModal(false);
//     setNewPayinCallbackURL('');
//     setPayinCallbackError('');
//   };

//   // Function to submit the PayIn Callback URL change
//   const handleSubmitPayinCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payin_callback_url: newPayinCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('PayIn Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payinCallbackURL: newPayinCallbackURL,
//         }));
//         localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
//         handleClosePayinCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
//       }
//     } catch (error) {
//       setPayinCallbackError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Success Redirect URL edit icon
//   const handleSuccessRedirectEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowSuccessRedirectModal(true);
//     setNewSuccessRedirectURL(profileData.successRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Success Redirect URL modal
//   const handleCloseSuccessRedirectModal = () => {
//     setShowSuccessRedirectModal(false);
//     setNewSuccessRedirectURL('');
//     setSuccessRedirectError('');
//   };

//   // Function to submit the Success Redirect URL change
//   const handleSubmitSuccessRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           success_redirect_url: newSuccessRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Success Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           successRedirectURL: newSuccessRedirectURL,
//         }));
//         localStorage.setItem('successRedirectURL', newSuccessRedirectURL);
//         handleCloseSuccessRedirectModal();
//       } else {
//         const result = await response.json();
//         setSuccessRedirectError(result.message || 'Failed to update Success Redirect URL');
//       }
//     } catch (error) {
//       setSuccessRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Failure Redirect URL edit icon
//   const handleFailureRedirectEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowFailureRedirectModal(true);
//     setNewFailureRedirectURL(profileData.failureRedirectURL); // Initialize with current value
//   };

//   // Function to handle closing the Failure Redirect URL modal
//   const handleCloseFailureRedirectModal = () => {
//     setShowFailureRedirectModal(false);
//     setNewFailureRedirectURL('');
//     setFailureRedirectError('');
//   };

//   // Function to submit the Failure Redirect URL change
//   const handleSubmitFailureRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           failure_redirect_url: newFailureRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Failure Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           failureRedirectURL: newFailureRedirectURL,
//         }));
//         localStorage.setItem('failureRedirectURL', newFailureRedirectURL);
//         handleCloseFailureRedirectModal();
//       } else {
//         const result = await response.json();
//         setFailureRedirectError(result.message || 'Failed to update Failure Redirect URL');
//       }
//     } catch (error) {
//       setFailureRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Bank Details edit icon
//   const handleBankDetailsEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowBankDetailsModal(true);
//     setNewBankDetails({
//       bankName: profileData.bankName || '',
//       accountNumber: profileData.accountNumber || '',
//       ifscCode: profileData.ifscCode || '',
//       accountName: profileData.accountName || '',
//     });
//   };

//   // Function to handle closing the Bank Details modal
//   const handleCloseBankDetailsModal = () => {
//     setShowBankDetailsModal(false);
//     setNewBankDetails({
//       bankName: '',
//       accountNumber: '',
//       ifscCode: '',
//       accountName: '',
//     });
//     setBankDetailsError('');
//   };

//   // Function to submit the Bank Details change
//   const handleSubmitBankDetailsChange = async () => {
//     try {
//       const { bankName, accountNumber, ifscCode, accountName } = newBankDetails;
//       if (!bankName || !accountNumber || !ifscCode || !accountName) {
//         setBankDetailsError('All fields are required');
//         return;
//       }

//       const response = await fetch(`https://payin.payinfy.com/api/update-bank-details/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           bank_name: bankName,
//           account_number: accountNumber,
//           ifsc_code: ifscCode,
//           account_name: accountName,
//         }),
//       });

//       if (response.ok) {
//         alert('Bank details updated successfully');
//         setProfileData((prevData) => ({
//           ...prevData,
//           bankName,
//           accountNumber,
//           ifscCode,
//           accountName,
//         }));
//         localStorage.setItem('bankName', bankName);
//         localStorage.setItem('accountNumber', accountNumber);
//         localStorage.setItem('ifscCode', ifscCode);
//         localStorage.setItem('accountName', accountName);
//         handleCloseBankDetailsModal();
//       } else {
//         const result = await response.json();
//         setBankDetailsError(result.message || 'Failed to update bank details');
//       }
//     } catch (error) {
//       setBankDetailsError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Wallet Address edit icon
//   const handleWalletAddressEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowWalletAddressModal(true);
//     setNewWalletAddress(profileData.walletAddress || '');
//   };

//   // Function to handle closing the Wallet Address modal
//   const handleCloseWalletAddressModal = () => {
//     setShowWalletAddressModal(false);
//     setNewWalletAddress('');
//     setWalletAddressError('');
//   };

//   // Function to submit the Wallet Address change
//   const handleSubmitWalletAddressChange = async () => {
//     try {
//       if (!newWalletAddress) {
//         setWalletAddressError('Wallet address is required');
//         return;
//       }

//       const response = await fetch(`https://payin.payinfy.com/api/update-wallet-address/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ wallet_address: newWalletAddress }),
//       });

//       if (response.ok) {
//         alert('Wallet address updated successfully');
//         setProfileData((prevData) => ({
//           ...prevData,
//           walletAddress: newWalletAddress,
//         }));
//         localStorage.setItem('walletAddress', newWalletAddress);
//         handleCloseWalletAddressModal();
//       } else {
//         const result = await response.json();
//         setWalletAddressError(result.message || 'Failed to update wallet address');
//       }
//     } catch (error) {
//       setWalletAddressError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Payout Callback URL edit icon
//   const handlePayoutCallbackEditClick = () => {
//     if (!profileData.payoutSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowPayoutCallbackModal(true);
//     setNewPayoutCallbackURL(profileData.payoutCallbackURL || '');
//   };

//   // Function to handle closing the Payout Callback URL modal
//   const handleClosePayoutCallbackModal = () => {
//     setShowPayoutCallbackModal(false);
//     setNewPayoutCallbackURL('');
//     setPayoutCallbackError('');
//   };

//   // Function to submit the Payout Callback URL change
//   const handleSubmitPayoutCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payouts.payinfy.com/payouts/update-payout-callback-url/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payout_callback_url: newPayoutCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Payout Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payoutCallbackURL: newPayoutCallbackURL,
//         }));
//         localStorage.setItem('payoutCallbackURL', newPayoutCallbackURL);
//         handleClosePayoutCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayoutCallbackError(result.message || 'Failed to update Payout Callback URL');
//       }
//     } catch (error) {
//       setPayoutCallbackError('An error occurred');
//     }
//   };

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Bank Details Field with Edit Icon */}
//           <div className="form-group bank-details-group">
//             <label htmlFor="bankDetails">Bank Details:</label>
//             <div className="bank-details-field">
//               <input
//                 type="text"
//                 id="bankDetails"
//                 name="bankDetails"
//                 value={
//                   profileData.bankName
//                     ? `${profileData.bankName}, ${profileData.accountNumber}`
//                     : 'Not provided'
//                 }
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleBankDetailsEditClick} />
//             </div>
//           </div>

//           {/* Wallet Address Field with Edit Icon */}
//           <div className="form-group wallet-address-group">
//             <label htmlFor="walletAddress">Wallet Address:</label>
//             <div className="wallet-address-field">
//               <input
//                 type="text"
//                 id="walletAddress"
//                 name="walletAddress"
//                 value={profileData.walletAddress || 'Not provided'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleWalletAddressEditClick} />
//             </div>
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod !== '' ? `T + ${profileData.settlementPeriod} days` : 'N/A'
//               }
//               readOnly
//             />
//           </div>

//           {/* Refund Fee Field */}
//           <div className="form-group">
//             <label htmlFor="refundFee">Refund Fee:</label>
//             <input
//               type="text"
//               id="refundFee"
//               name="refundFee"
//               value={profileData.refundFee !== '' ? `${profileData.refundFee}` : 'N/A'}
//               readOnly
//             />
//           </div>

//           {/* Chargeback Fee Field */}
//           <div className="form-group">
//             <label htmlFor="chargebackFee">Chargeback Fee:</label>
//             <input
//               type="text"
//               id="chargebackFee"
//               name="chargebackFee"
//               value={profileData.chargebackFee !== '' ? `${profileData.chargebackFee}` : 'N/A'}
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayOut Callback URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payoutCallbackURL"
//                 name="payoutCallbackURL"
//                 value={profileData.payoutCallbackURL || 'Not Available'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayoutCallbackEditClick} />
//             </div>
//           </div>

//           {/* PayIn Secret Key */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayIn Callback URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payinCallbackURL"
//                 name="payinCallbackURL"
//                 value={profileData.payinCallbackURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
//             </div>
//           </div>

//           {/* Success Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="successRedirectURL">Success Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="successRedirectURL"
//                 name="successRedirectURL"
//                 value={profileData.successRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleSuccessRedirectEditClick} />
//             </div>
//           </div>

//           {/* Failure Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="failureRedirectURL">Failure Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="failureRedirectURL"
//                 name="failureRedirectURL"
//                 value={profileData.failureRedirectURL}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleFailureRedirectEditClick} />
//             </div>
//           </div>
//         </form>
//       </div>

//       {/* Modals */}
//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}

//       {/* PayIn Callback URL Edit Modal */}
//       {showPayinCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
//             <h3>Edit PayIn Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayinCallbackURL"
//                 value={newPayinCallbackURL}
//                 onChange={(e) => setNewPayinCallbackURL(e.target.value)}
//               />
//             </div>
//             {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Success Redirect URL Edit Modal */}
//       {showSuccessRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseSuccessRedirectModal} />
//             <h3>Edit Success Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newSuccessRedirectURL">New Success Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newSuccessRedirectURL"
//                 value={newSuccessRedirectURL}
//                 onChange={(e) => setNewSuccessRedirectURL(e.target.value)}
//               />
//             </div>
//             {successRedirectError && <p className="error-message">{successRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitSuccessRedirectChange}>
//               Update Success Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Failure Redirect URL Edit Modal */}
//       {showFailureRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseFailureRedirectModal} />
//             <h3>Edit Failure Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newFailureRedirectURL">New Failure Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newFailureRedirectURL"
//                 value={newFailureRedirectURL}
//                 onChange={(e) => setNewFailureRedirectURL(e.target.value)}
//               />
//             </div>
//             {failureRedirectError && <p className="error-message">{failureRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitFailureRedirectChange}>
//               Update Failure Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Bank Details Edit Modal */}
//       {showBankDetailsModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseBankDetailsModal} />
//             <h3>Edit Bank Details</h3>
//             <div className="form-group">
//               <label htmlFor="bankName">Bank Name:</label>
//               <input
//                 type="text"
//                 id="bankName"
//                 value={newBankDetails.bankName}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, bankName: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="accountNumber">Account Number:</label>
//               <input
//                 type="text"
//                 id="accountNumber"
//                 value={newBankDetails.accountNumber}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, accountNumber: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="ifscCode">IFSC Code:</label>
//               <input
//                 type="text"
//                 id="ifscCode"
//                 value={newBankDetails.ifscCode}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, ifscCode: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="accountName">Account Name:</label>
//               <input
//                 type="text"
//                 id="accountName"
//                 value={newBankDetails.accountName}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, accountName: e.target.value }))}
//               />
//             </div>
//             {bankDetailsError && <p className="error-message">{bankDetailsError}</p>}
//             <button className="submit-btn" onClick={handleSubmitBankDetailsChange}>
//               Update Bank Details
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Wallet Address Edit Modal */}
//       {showWalletAddressModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseWalletAddressModal} />
//             <h3>Edit Wallet Address</h3>
//             <div className="form-group">
//               <label htmlFor="newWalletAddress">New Wallet Address:</label>
//               <input
//                 type="text"
//                 id="newWalletAddress"
//                 value={newWalletAddress}
//                 onChange={(e) => setNewWalletAddress(e.target.value)}
//               />
//             </div>
//             {walletAddressError && <p className="error-message">{walletAddressError}</p>}
//             <button className="submit-btn" onClick={handleSubmitWalletAddressChange}>
//               Update Wallet Address
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Payout Callback URL Edit Modal */}
//       {showPayoutCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayoutCallbackModal} />
//             <h3>Edit Payout Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayoutCallbackURL">New Payout Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayoutCallbackURL"
//                 value={newPayoutCallbackURL}
//                 onChange={(e) => setNewPayoutCallbackURL(e.target.value)}
//               />
//             </div>
//             {payoutCallbackError && <p className="error-message">{payoutCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayoutCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;



// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa';

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '',
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '',
//     refundFee: '',
//     chargebackFee: '',
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '',
//     payinCallbackURL: '',
//     successRedirectURL: '',
//     failureRedirectURL: '',
//     bankName: '',
//     accountNumber: '',
//     ifscCode: '',
//     accountName: '',
//     walletAddress: '',
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // State variables for PayIn Callback URL modal
//   const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
//   const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
//   const [payinCallbackError, setPayinCallbackError] = useState('');

//   // State variables for Success Redirect URL modal
//   const [showSuccessRedirectModal, setShowSuccessRedirectModal] = useState(false);
//   const [newSuccessRedirectURL, setNewSuccessRedirectURL] = useState('');
//   const [successRedirectError, setSuccessRedirectError] = useState('');

//   // State variables for Failure Redirect URL modal
//   const [showFailureRedirectModal, setShowFailureRedirectModal] = useState(false);
//   const [newFailureRedirectURL, setNewFailureRedirectURL] = useState('');
//   const [failureRedirectError, setFailureRedirectError] = useState('');

//   // State variables for Bank Details modal
//   const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
//   const [newBankDetails, setNewBankDetails] = useState({
//     bankName: '',
//     accountNumber: '',
//     ifscCode: '',
//     accountName: '',
//   });
//   const [bankDetailsError, setBankDetailsError] = useState('');

//   // State variables for Wallet Address modal
//   const [showWalletAddressModal, setShowWalletAddressModal] = useState(false);
//   const [newWalletAddress, setNewWalletAddress] = useState('');
//   const [walletAddressError, setWalletAddressError] = useState('');

//   // State variables for Payout Callback URL modal
//   const [showPayoutCallbackModal, setShowPayoutCallbackModal] = useState(false);
//   const [newPayoutCallbackURL, setNewPayoutCallbackURL] = useState('');
//   const [payoutCallbackError, setPayoutCallbackError] = useState('');

//   // State variables for loading and error handling
//   const [isLoading, setIsLoading] = useState(true);
//   const [fetchError, setFetchError] = useState('');

//   // Fetch profile data from the server
//   useEffect(() => {
//     const fetchProfileData = async () => {
//       try {
//         const mid = localStorage.getItem('merchantId');
//         if (!mid) {
//           setFetchError('Merchant ID not found in local storage.');
//           setIsLoading(false);
//           return;
//         }

//         // Fetch data from PayIn Project
//         const payinResponse = await fetch(`https://payin.payinfy.com/api/merchant-profile/${mid}/`);
//         if (!payinResponse.ok) {
//           throw new Error('Failed to fetch PayIn profile data.');
//         }
//         const payinData = await payinResponse.json();

//         // Fetch additional data from Merchant Project
//         const merchantResponse = await fetch(`https://merchant.payinfy.com/merchants/merchant-profile/${mid}/`);
//         if (!merchantResponse.ok) {
//           throw new Error('Failed to fetch Merchant profile data.');
//         }
//         const merchantData = await merchantResponse.json();

//         // Fetch additional data from Payouts Project (if necessary)
//         const payoutResponse = await fetch(`https://payouts.payinfy.com/payouts/payout-profile/${mid}/`);
//         let payoutData = {};
//         if (payoutResponse.ok) {
//           payoutData = await payoutResponse.json();
//         } else {
//           console.warn('Failed to fetch Payouts profile data. Proceeding without it.');
//         }

//         // Combine all fetched data
//         const updatedProfileData = {
//           name: payinData.merchant_name || '',
//           email: payinData.merchant_email || '',
//           mid: payinData.merchant_id || '',
//           salt: merchantData.salt || '',
//           settlementPeriod: payinData.settlement_period !== null ? payinData.settlement_period : '',
//           refundFee: payinData.refund_fee || '',
//           chargebackFee: payinData.chargeback_fee || '',
//           payoutSecretKey: payoutData.payout_secret_key || merchantData.payout_secret_key || '',
//           payoutCallbackURL: payoutData.payout_callback_url || payinData.payout_callback_url || '',
//           payinSecretKey: payinData.payin_secret_key || '',
//           payinCallbackURL: payinData.payin_callback_url || '',
//           successRedirectURL: payinData.success_redirect_url || '',
//           failureRedirectURL: payinData.failure_redirect_url || '',
//           bankName: payinData.bank_name || '',
//           accountNumber: payinData.account_number || '',
//           ifscCode: payinData.ifsc_code || '',
//           accountName: payinData.account_name || '',
//           walletAddress: payinData.wallet_address || '',
//         };

//         setProfileData(updatedProfileData);

//         // Update localStorage
//         for (const [key, value] of Object.entries(updatedProfileData)) {
//           localStorage.setItem(key, value);
//         }

//         setIsLoading(false);
//       } catch (error) {
//         console.error('Error fetching profile data:', error);
//         setFetchError(error.message);
//         setIsLoading(false);
//       }
//     };

//     fetchProfileData();
//   }, []);

//   // Function to handle clicking the password edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the password modal
//   const handleClosePasswordModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(`https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           old_password: oldPassword,
//           new_password: newPassword,
//         }),
//       });

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleClosePasswordModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   // Function to handle clicking the PayIn Callback URL edit icon
//   const handlePayinCallbackEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowPayinCallbackModal(true);
//     setNewPayinCallbackURL(profileData.payinCallbackURL || '');
//   };

//   // Function to handle closing the PayIn Callback URL modal
//   const handleClosePayinCallbackModal = () => {
//     setShowPayinCallbackModal(false);
//     setNewPayinCallbackURL('');
//     setPayinCallbackError('');
//   };

//   // Function to submit the PayIn Callback URL change
//   const handleSubmitPayinCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payin_callback_url: newPayinCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('PayIn Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payinCallbackURL: newPayinCallbackURL,
//         }));
//         localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
//         handleClosePayinCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
//       }
//     } catch (error) {
//       setPayinCallbackError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Success Redirect URL edit icon
//   const handleSuccessRedirectEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowSuccessRedirectModal(true);
//     setNewSuccessRedirectURL(profileData.successRedirectURL || '');
//   };

//   // Function to handle closing the Success Redirect URL modal
//   const handleCloseSuccessRedirectModal = () => {
//     setShowSuccessRedirectModal(false);
//     setNewSuccessRedirectURL('');
//     setSuccessRedirectError('');
//   };

//   // Function to submit the Success Redirect URL change
//   const handleSubmitSuccessRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           success_redirect_url: newSuccessRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Success Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           successRedirectURL: newSuccessRedirectURL,
//         }));
//         localStorage.setItem('successRedirectURL', newSuccessRedirectURL);
//         handleCloseSuccessRedirectModal();
//       } else {
//         const result = await response.json();
//         setSuccessRedirectError(result.message || 'Failed to update Success Redirect URL');
//       }
//     } catch (error) {
//       setSuccessRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Failure Redirect URL edit icon
//   const handleFailureRedirectEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowFailureRedirectModal(true);
//     setNewFailureRedirectURL(profileData.failureRedirectURL || '');
//   };

//   // Function to handle closing the Failure Redirect URL modal
//   const handleCloseFailureRedirectModal = () => {
//     setShowFailureRedirectModal(false);
//     setNewFailureRedirectURL('');
//     setFailureRedirectError('');
//   };

//   // Function to submit the Failure Redirect URL change
//   const handleSubmitFailureRedirectChange = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/update-urls/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           failure_redirect_url: newFailureRedirectURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Failure Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           failureRedirectURL: newFailureRedirectURL,
//         }));
//         localStorage.setItem('failureRedirectURL', newFailureRedirectURL);
//         handleCloseFailureRedirectModal();
//       } else {
//         const result = await response.json();
//         setFailureRedirectError(result.message || 'Failed to update Failure Redirect URL');
//       }
//     } catch (error) {
//       setFailureRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Bank Details edit icon
//   const handleBankDetailsEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowBankDetailsModal(true);
//     setNewBankDetails({
//       bankName: profileData.bankName || '',
//       accountNumber: profileData.accountNumber || '',
//       ifscCode: profileData.ifscCode || '',
//       accountName: profileData.accountName || '',
//     });
//   };

//   // Function to handle closing the Bank Details modal
//   const handleCloseBankDetailsModal = () => {
//     setShowBankDetailsModal(false);
//     setNewBankDetails({
//       bankName: '',
//       accountNumber: '',
//       ifscCode: '',
//       accountName: '',
//     });
//     setBankDetailsError('');
//   };

//   // Function to submit the Bank Details change
//   const handleSubmitBankDetailsChange = async () => {
//     try {
//       const { bankName, accountNumber, ifscCode, accountName } = newBankDetails;
//       if (!bankName || !accountNumber || !ifscCode || !accountName) {
//         setBankDetailsError('All fields are required');
//         return;
//       }

//       const response = await fetch(`https://payin.payinfy.com/api/update-bank-details/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           bank_name: bankName,
//           account_number: accountNumber,
//           ifsc_code: ifscCode,
//           account_name: accountName,
//         }),
//       });

//       if (response.ok) {
//         alert('Bank details updated successfully');
//         setProfileData((prevData) => ({
//           ...prevData,
//           bankName,
//           accountNumber,
//           ifscCode,
//           accountName,
//         }));
//         localStorage.setItem('bankName', bankName);
//         localStorage.setItem('accountNumber', accountNumber);
//         localStorage.setItem('ifscCode', ifscCode);
//         localStorage.setItem('accountName', accountName);
//         handleCloseBankDetailsModal();
//       } else {
//         const result = await response.json();
//         setBankDetailsError(result.message || 'Failed to update bank details');
//       }
//     } catch (error) {
//       setBankDetailsError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Wallet Address edit icon
//   const handleWalletAddressEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowWalletAddressModal(true);
//     setNewWalletAddress(profileData.walletAddress || '');
//   };

//   // Function to handle closing the Wallet Address modal
//   const handleCloseWalletAddressModal = () => {
//     setShowWalletAddressModal(false);
//     setNewWalletAddress('');
//     setWalletAddressError('');
//   };

//   // Function to submit the Wallet Address change
//   const handleSubmitWalletAddressChange = async () => {
//     try {
//       if (!newWalletAddress) {
//         setWalletAddressError('Wallet address is required');
//         return;
//       }

//       const response = await fetch(`https://payin.payinfy.com/api/update-wallet-address/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ wallet_address: newWalletAddress }),
//       });

//       if (response.ok) {
//         alert('Wallet address updated successfully');
//         setProfileData((prevData) => ({
//           ...prevData,
//           walletAddress: newWalletAddress,
//         }));
//         localStorage.setItem('walletAddress', newWalletAddress);
//         handleCloseWalletAddressModal();
//       } else {
//         const result = await response.json();
//         setWalletAddressError(result.message || 'Failed to update wallet address');
//       }
//     } catch (error) {
//       setWalletAddressError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Payout Callback URL edit icon
//   const handlePayoutCallbackEditClick = () => {
//     if (!profileData.payoutSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowPayoutCallbackModal(true);
//     setNewPayoutCallbackURL(profileData.payoutCallbackURL || '');
//   };

//   // Function to handle closing the Payout Callback URL modal
//   const handleClosePayoutCallbackModal = () => {
//     setShowPayoutCallbackModal(false);
//     setNewPayoutCallbackURL('');
//     setPayoutCallbackError('');
//   };

//   // Function to submit the Payout Callback URL change
//   const handleSubmitPayoutCallbackChange = async () => {
//     try {
//       const response = await fetch(`https://payouts.payinfy.com/payouts/update-payout-callback-url/${profileData.mid}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           payout_callback_url: newPayoutCallbackURL,
//         }),
//       });

//       if (response.ok) {
//         alert('Payout Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payoutCallbackURL: newPayoutCallbackURL,
//         }));
//         localStorage.setItem('payoutCallbackURL', newPayoutCallbackURL);
//         handleClosePayoutCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayoutCallbackError(result.message || 'Failed to update Payout Callback URL');
//       }
//     } catch (error) {
//       setPayoutCallbackError('An error occurred');
//     }
//   };

//   // Render loading or error states
//   if (isLoading) {
//     return <div className="profile-content"><p>Loading profile...</p></div>;
//   }

//   if (fetchError) {
//     return <div className="profile-content"><p className="error-message">Error: {fetchError}</p></div>;
//   }

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Bank Details Field with Edit Icon */}
//           <div className="form-group bank-details-group">
//             <label htmlFor="bankDetails">Bank Details:</label>
//             <div className="bank-details-field">
//               <input
//                 type="text"
//                 id="bankDetails"
//                 name="bankDetails"
//                 value={
//                   profileData.bankName && profileData.accountNumber
//                     ? `${profileData.bankName}, ${profileData.accountNumber}`
//                     : 'Not provided'
//                 }
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleBankDetailsEditClick} />
//             </div>
//           </div>

//           {/* Wallet Address Field with Edit Icon */}
//           <div className="form-group wallet-address-group">
//             <label htmlFor="walletAddress">Wallet Address:</label>
//             <div className="wallet-address-field">
//               <input
//                 type="text"
//                 id="walletAddress"
//                 name="walletAddress"
//                 value={profileData.walletAddress || 'Not provided'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleWalletAddressEditClick} />
//             </div>
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod !== '' ? `T + ${profileData.settlementPeriod} days` : 'N/A'
//               }
//               readOnly
//             />
//           </div>

//           {/* Refund Fee Field */}
//           <div className="form-group">
//             <label htmlFor="refundFee">Refund Fee:</label>
//             <input
//               type="text"
//               id="refundFee"
//               name="refundFee"
//               value={profileData.refundFee !== '' ? `${profileData.refundFee}` : 'N/A'}
//               readOnly
//             />
//           </div>

//           {/* Chargeback Fee Field */}
//           <div className="form-group">
//             <label htmlFor="chargebackFee">Chargeback Fee:</label>
//             <input
//               type="text"
//               id="chargebackFee"
//               name="chargebackFee"
//               value={profileData.chargebackFee !== '' ? `${profileData.chargebackFee}` : 'N/A'}
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayOut Callback URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payoutCallbackURL"
//                 name="payoutCallbackURL"
//                 value={profileData.payoutCallbackURL || 'Not Available'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayoutCallbackEditClick} />
//             </div>
//           </div>

//           {/* PayIn Secret Key */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayIn Callback URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payinCallbackURL"
//                 name="payinCallbackURL"
//                 value={profileData.payinCallbackURL || 'Not Available'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
//             </div>
//           </div>

//           {/* Success Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="successRedirectURL">Success Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="successRedirectURL"
//                 name="successRedirectURL"
//                 value={profileData.successRedirectURL || 'Not Available'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleSuccessRedirectEditClick} />
//             </div>
//           </div>

//           {/* Failure Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="failureRedirectURL">Failure Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="failureRedirectURL"
//                 name="failureRedirectURL"
//                 value={profileData.failureRedirectURL || 'Not Available'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleFailureRedirectEditClick} />
//             </div>
//           </div>
//         </form>
//       </div>

//       {/* Modals */}
//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}

//       {/* PayIn Callback URL Edit Modal */}
//       {showPayinCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
//             <h3>Edit PayIn Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayinCallbackURL"
//                 value={newPayinCallbackURL}
//                 onChange={(e) => setNewPayinCallbackURL(e.target.value)}
//               />
//             </div>
//             {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Success Redirect URL Edit Modal */}
//       {showSuccessRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseSuccessRedirectModal} />
//             <h3>Edit Success Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newSuccessRedirectURL">New Success Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newSuccessRedirectURL"
//                 value={newSuccessRedirectURL}
//                 onChange={(e) => setNewSuccessRedirectURL(e.target.value)}
//               />
//             </div>
//             {successRedirectError && <p className="error-message">{successRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitSuccessRedirectChange}>
//               Update Success Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Failure Redirect URL Edit Modal */}
//       {showFailureRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseFailureRedirectModal} />
//             <h3>Edit Failure Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newFailureRedirectURL">New Failure Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newFailureRedirectURL"
//                 value={newFailureRedirectURL}
//                 onChange={(e) => setNewFailureRedirectURL(e.target.value)}
//               />
//             </div>
//             {failureRedirectError && <p className="error-message">{failureRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitFailureRedirectChange}>
//               Update Failure Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Bank Details Edit Modal */}
//       {showBankDetailsModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseBankDetailsModal} />
//             <h3>Edit Bank Details</h3>
//             <div className="form-group">
//               <label htmlFor="bankName">Bank Name:</label>
//               <input
//                 type="text"
//                 id="bankName"
//                 value={newBankDetails.bankName}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, bankName: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="accountNumber">Account Number:</label>
//               <input
//                 type="text"
//                 id="accountNumber"
//                 value={newBankDetails.accountNumber}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, accountNumber: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="ifscCode">IFSC Code:</label>
//               <input
//                 type="text"
//                 id="ifscCode"
//                 value={newBankDetails.ifscCode}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, ifscCode: e.target.value }))}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="accountName">Account Name:</label>
//               <input
//                 type="text"
//                 id="accountName"
//                 value={newBankDetails.accountName}
//                 onChange={(e) => setNewBankDetails((prev) => ({ ...prev, accountName: e.target.value }))}
//               />
//             </div>
//             {bankDetailsError && <p className="error-message">{bankDetailsError}</p>}
//             <button className="submit-btn" onClick={handleSubmitBankDetailsChange}>
//               Update Bank Details
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Wallet Address Edit Modal */}
//       {showWalletAddressModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseWalletAddressModal} />
//             <h3>Edit Wallet Address</h3>
//             <div className="form-group">
//               <label htmlFor="newWalletAddress">New Wallet Address:</label>
//               <input
//                 type="text"
//                 id="newWalletAddress"
//                 value={newWalletAddress}
//                 onChange={(e) => setNewWalletAddress(e.target.value)}
//               />
//             </div>
//             {walletAddressError && <p className="error-message">{walletAddressError}</p>}
//             <button className="submit-btn" onClick={handleSubmitWalletAddressChange}>
//               Update Wallet Address
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Payout Callback URL Edit Modal */}
//       {showPayoutCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayoutCallbackModal} />
//             <h3>Edit Payout Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayoutCallbackURL">New Payout Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayoutCallbackURL"
//                 value={newPayoutCallbackURL}
//                 onChange={(e) => setNewPayoutCallbackURL(e.target.value)}
//               />
//             </div>
//             {payoutCallbackError && <p className="error-message">{payoutCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayoutCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;



// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa';

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '',
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '',
//     refundFee: '',
//     chargebackFee: '',
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '',
//     payinCallbackURL: '',
//     successRedirectURL: '',
//     failureRedirectURL: '',
//     bankName: '',
//     accountNumber: '',
//     ifscCode: '',
//     accountName: '',
//     walletAddress: '',
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // State variables for PayIn Callback URL modal
//   const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
//   const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
//   const [payinCallbackError, setPayinCallbackError] = useState('');

//   // State variables for Success Redirect URL modal
//   const [showSuccessRedirectModal, setShowSuccessRedirectModal] = useState(false);
//   const [newSuccessRedirectURL, setNewSuccessRedirectURL] = useState('');
//   const [successRedirectError, setSuccessRedirectError] = useState('');

//   // State variables for Failure Redirect URL modal
//   const [showFailureRedirectModal, setShowFailureRedirectModal] = useState(false);
//   const [newFailureRedirectURL, setNewFailureRedirectURL] = useState('');
//   const [failureRedirectError, setFailureRedirectError] = useState('');

//   // State variables for Bank Details modal
//   const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
//   const [newBankDetails, setNewBankDetails] = useState({
//     bankName: '',
//     accountNumber: '',
//     ifscCode: '',
//     accountName: '',
//   });
//   const [bankDetailsError, setBankDetailsError] = useState('');

//   // State variables for Wallet Address modal
//   const [showWalletAddressModal, setShowWalletAddressModal] = useState(false);
//   const [newWalletAddress, setNewWalletAddress] = useState('');
//   const [walletAddressError, setWalletAddressError] = useState('');

//   // State variables for Payout Callback URL modal
//   const [showPayoutCallbackModal, setShowPayoutCallbackModal] = useState(false);
//   const [newPayoutCallbackURL, setNewPayoutCallbackURL] = useState('');
//   const [payoutCallbackError, setPayoutCallbackError] = useState('');

//   // State variables for loading and error handling
//   const [isLoading, setIsLoading] = useState(true);
//   const [fetchError, setFetchError] = useState('');

//   // Fetch profile data from the server
//   useEffect(() => {
//     const fetchProfileData = async () => {
//       const mid = localStorage.getItem('merchantId');
//       if (!mid) {
//         setFetchError('Merchant ID not found in local storage.');
//         setIsLoading(false);
//         return;
//       }

//       // Prepare empty objects to hold partial data
//       let payinData = {};
//       let merchantData = {};
//       let payoutData = {};

//       // Fetch data from PayIn Project
//       try {
//         const payinResponse = await fetch(`https://payin.payinfy.com/api/merchant-profile/${mid}/`);
//         if (payinResponse.ok) {
//           payinData = await payinResponse.json();
//         } else {
//           console.warn('Failed to fetch PayIn profile data. Some data may be missing.');
//         }
//       } catch (error) {
//         console.warn('Error fetching PayIn profile data:', error);
//       }

//       // Fetch additional data from Merchant Project
//       try {
//         const merchantResponse = await fetch(`https://merchant.payinfy.com/merchants/merchant-profile/${mid}/`);
//         if (merchantResponse.ok) {
//           merchantData = await merchantResponse.json();
//         } else {
//           console.warn('Failed to fetch Merchant profile data. Some data may be missing.');
//         }
//       } catch (error) {
//         console.warn('Error fetching Merchant profile data:', error);
//       }

//       // Fetch additional data from Payouts Project (if available)
//       try {
//         const payoutResponse = await fetch(`https://payouts.payinfy.com/payouts/payout-profile/${mid}/`);
//         if (payoutResponse.ok) {
//           payoutData = await payoutResponse.json();
//         } else {
//           console.warn('Failed to fetch Payouts profile data. Some data may be missing.');
//         }
//       } catch (error) {
//         console.warn('Error fetching Payouts profile data:', error);
//       }

//       // Combine all fetched data, fallback to empty strings where data is missing
//       const updatedProfileData = {
//         name: payinData.merchant_name || '',
//         email: payinData.merchant_email || '',
//         mid: payinData.merchant_id || '',
//         salt: merchantData.salt || '',
//         settlementPeriod:
//           payinData.settlement_period !== null && payinData.settlement_period !== undefined
//             ? payinData.settlement_period
//             : '',
//         refundFee: payinData.refund_fee || '',
//         chargebackFee: payinData.chargeback_fee || '',
//         payoutSecretKey: payoutData.payout_secret_key || merchantData.payout_secret_key || '',
//         payoutCallbackURL: payoutData.payout_callback_url || payinData.payout_callback_url || '',
//         payinSecretKey: payinData.payin_secret_key || '',
//         payinCallbackURL: payinData.payin_callback_url || '',
//         successRedirectURL: payinData.success_redirect_url || '',
//         failureRedirectURL: payinData.failure_redirect_url || '',
//         bankName: payinData.bank_name || '',
//         accountNumber: payinData.account_number || '',
//         ifscCode: payinData.ifsc_code || '',
//         accountName: payinData.account_name || '',
//         walletAddress: payinData.wallet_address || '',
//       };

//       setProfileData(updatedProfileData);

//       // Update localStorage with whatever data we have
//       for (const [key, value] of Object.entries(updatedProfileData)) {
//         localStorage.setItem(key, value);
//       }

//       setIsLoading(false);
//     };

//     fetchProfileData();
//   }, []);

//   // Function to handle clicking the password edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the password modal
//   const handleClosePasswordModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(
//         `https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             old_password: oldPassword,
//             new_password: newPassword,
//           }),
//         }
//       );

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleClosePasswordModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   // Function to handle clicking the PayIn Callback URL edit icon
//   const handlePayinCallbackEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowPayinCallbackModal(true);
//     setNewPayinCallbackURL(profileData.payinCallbackURL || '');
//   };

//   // Function to handle closing the PayIn Callback URL modal
//   const handleClosePayinCallbackModal = () => {
//     setShowPayinCallbackModal(false);
//     setNewPayinCallbackURL('');
//     setPayinCallbackError('');
//   };

//   // Function to submit the PayIn Callback URL change
//   const handleSubmitPayinCallbackChange = async () => {
//     try {
//       const response = await fetch(
//         `https://payin.payinfy.com/api/update-urls/${profileData.mid}/`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             payin_callback_url: newPayinCallbackURL,
//           }),
//         }
//       );

//       if (response.ok) {
//         alert('PayIn Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payinCallbackURL: newPayinCallbackURL,
//         }));
//         localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
//         handleClosePayinCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
//       }
//     } catch (error) {
//       setPayinCallbackError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Success Redirect URL edit icon
//   const handleSuccessRedirectEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowSuccessRedirectModal(true);
//     setNewSuccessRedirectURL(profileData.successRedirectURL || '');
//   };

//   // Function to handle closing the Success Redirect URL modal
//   const handleCloseSuccessRedirectModal = () => {
//     setShowSuccessRedirectModal(false);
//     setNewSuccessRedirectURL('');
//     setSuccessRedirectError('');
//   };

//   // Function to submit the Success Redirect URL change
//   const handleSubmitSuccessRedirectChange = async () => {
//     try {
//       const response = await fetch(
//         `https://payin.payinfy.com/api/update-urls/${profileData.mid}/`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             success_redirect_url: newSuccessRedirectURL,
//           }),
//         }
//       );

//       if (response.ok) {
//         alert('Success Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           successRedirectURL: newSuccessRedirectURL,
//         }));
//         localStorage.setItem('successRedirectURL', newSuccessRedirectURL);
//         handleCloseSuccessRedirectModal();
//       } else {
//         const result = await response.json();
//         setSuccessRedirectError(result.message || 'Failed to update Success Redirect URL');
//       }
//     } catch (error) {
//       setSuccessRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Failure Redirect URL edit icon
//   const handleFailureRedirectEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowFailureRedirectModal(true);
//     setNewFailureRedirectURL(profileData.failureRedirectURL || '');
//   };

//   // Function to handle closing the Failure Redirect URL modal
//   const handleCloseFailureRedirectModal = () => {
//     setShowFailureRedirectModal(false);
//     setNewFailureRedirectURL('');
//     setFailureRedirectError('');
//   };

//   // Function to submit the Failure Redirect URL change
//   const handleSubmitFailureRedirectChange = async () => {
//     try {
//       const response = await fetch(
//         `https://payin.payinfy.com/api/update-urls/${profileData.mid}/`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             failure_redirect_url: newFailureRedirectURL,
//           }),
//         }
//       );

//       if (response.ok) {
//         alert('Failure Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           failureRedirectURL: newFailureRedirectURL,
//         }));
//         localStorage.setItem('failureRedirectURL', newFailureRedirectURL);
//         handleCloseFailureRedirectModal();
//       } else {
//         const result = await response.json();
//         setFailureRedirectError(result.message || 'Failed to update Failure Redirect URL');
//       }
//     } catch (error) {
//       setFailureRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Bank Details edit icon
//   const handleBankDetailsEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowBankDetailsModal(true);
//     setNewBankDetails({
//       bankName: profileData.bankName || '',
//       accountNumber: profileData.accountNumber || '',
//       ifscCode: profileData.ifscCode || '',
//       accountName: profileData.accountName || '',
//     });
//   };

//   // Function to handle closing the Bank Details modal
//   const handleCloseBankDetailsModal = () => {
//     setShowBankDetailsModal(false);
//     setNewBankDetails({
//       bankName: '',
//       accountNumber: '',
//       ifscCode: '',
//       accountName: '',
//     });
//     setBankDetailsError('');
//   };

//   // Function to submit the Bank Details change
//   const handleSubmitBankDetailsChange = async () => {
//     try {
//       const { bankName, accountNumber, ifscCode, accountName } = newBankDetails;
//       if (!bankName || !accountNumber || !ifscCode || !accountName) {
//         setBankDetailsError('All fields are required');
//         return;
//       }

//       const response = await fetch(
//         `https://payin.payinfy.com/api/update-bank-details/${profileData.mid}/`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             bank_name: bankName,
//             account_number: accountNumber,
//             ifsc_code: ifscCode,
//             account_name: accountName,
//           }),
//         }
//       );

//       if (response.ok) {
//         alert('Bank details updated successfully');
//         setProfileData((prevData) => ({
//           ...prevData,
//           bankName,
//           accountNumber,
//           ifscCode,
//           accountName,
//         }));
//         localStorage.setItem('bankName', bankName);
//         localStorage.setItem('accountNumber', accountNumber);
//         localStorage.setItem('ifscCode', ifscCode);
//         localStorage.setItem('accountName', accountName);
//         handleCloseBankDetailsModal();
//       } else {
//         const result = await response.json();
//         setBankDetailsError(result.message || 'Failed to update bank details');
//       }
//     } catch (error) {
//       setBankDetailsError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Wallet Address edit icon
//   const handleWalletAddressEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowWalletAddressModal(true);
//     setNewWalletAddress(profileData.walletAddress || '');
//   };

//   // Function to handle closing the Wallet Address modal
//   const handleCloseWalletAddressModal = () => {
//     setShowWalletAddressModal(false);
//     setNewWalletAddress('');
//     setWalletAddressError('');
//   };

//   // Function to submit the Wallet Address change
//   const handleSubmitWalletAddressChange = async () => {
//     try {
//       if (!newWalletAddress) {
//         setWalletAddressError('Wallet address is required');
//         return;
//       }

//       const response = await fetch(
//         `https://payin.payinfy.com/api/update-wallet-address/${profileData.mid}/`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({ wallet_address: newWalletAddress }),
//         }
//       );

//       if (response.ok) {
//         alert('Wallet address updated successfully');
//         setProfileData((prevData) => ({
//           ...prevData,
//           walletAddress: newWalletAddress,
//         }));
//         localStorage.setItem('walletAddress', newWalletAddress);
//         handleCloseWalletAddressModal();
//       } else {
//         const result = await response.json();
//         setWalletAddressError(result.message || 'Failed to update wallet address');
//       }
//     } catch (error) {
//       setWalletAddressError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Payout Callback URL edit icon
//   const handlePayoutCallbackEditClick = () => {
//     if (!profileData.payoutSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowPayoutCallbackModal(true);
//     setNewPayoutCallbackURL(profileData.payoutCallbackURL || '');
//   };

//   // Function to handle closing the Payout Callback URL modal
//   const handleClosePayoutCallbackModal = () => {
//     setShowPayoutCallbackModal(false);
//     setNewPayoutCallbackURL('');
//     setPayoutCallbackError('');
//   };

//   // Function to submit the Payout Callback URL change
//   const handleSubmitPayoutCallbackChange = async () => {
//     try {
//       const response = await fetch(
//         `https://payouts.payinfy.com/payouts/update-payout-callback-url/${profileData.mid}/`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             payout_callback_url: newPayoutCallbackURL,
//           }),
//         }
//       );

//       if (response.ok) {
//         alert('Payout Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payoutCallbackURL: newPayoutCallbackURL,
//         }));
//         localStorage.setItem('payoutCallbackURL', newPayoutCallbackURL);
//         handleClosePayoutCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayoutCallbackError(result.message || 'Failed to update Payout Callback URL');
//       }
//     } catch (error) {
//       setPayoutCallbackError('An error occurred');
//     }
//   };

//   // Render loading or error states
//   if (isLoading) {
//     return (
//       <div className="profile-content">
//         <p>Loading profile...</p>
//       </div>
//     );
//   }

//   // If there's a fatal error like missing merchantId, show it
//   if (fetchError) {
//     return (
//       <div className="profile-content">
//         <p className="error-message">Error: {fetchError}</p>
//       </div>
//     );
//   }

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Bank Details Field with Edit Icon */}
//           <div className="form-group bank-details-group">
//             <label htmlFor="bankDetails">Bank Details:</label>
//             <div className="bank-details-field">
//               <input
//                 type="text"
//                 id="bankDetails"
//                 name="bankDetails"
//                 value={
//                   profileData.bankName && profileData.accountNumber
//                     ? `${profileData.bankName}, ${profileData.accountNumber}`
//                     : 'Not provided'
//                 }
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleBankDetailsEditClick} />
//             </div>
//           </div>

//           {/* Wallet Address Field with Edit Icon */}
//           <div className="form-group wallet-address-group">
//             <label htmlFor="walletAddress">Wallet Address:</label>
//             <div className="wallet-address-field">
//               <input
//                 type="text"
//                 id="walletAddress"
//                 name="walletAddress"
//                 value={profileData.walletAddress || 'Not provided'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleWalletAddressEditClick} />
//             </div>
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod !== ''
//                   ? `T + ${profileData.settlementPeriod} days`
//                   : 'N/A'
//               }
//               readOnly
//             />
//           </div>

//           {/* Refund Fee Field */}
//           <div className="form-group">
//             <label htmlFor="refundFee">Refund Fee:</label>
//             <input
//               type="text"
//               id="refundFee"
//               name="refundFee"
//               value={profileData.refundFee !== '' ? `${profileData.refundFee}` : 'N/A'}
//               readOnly
//             />
//           </div>

//           {/* Chargeback Fee Field */}
//           <div className="form-group">
//             <label htmlFor="chargebackFee">Chargeback Fee:</label>
//             <input
//               type="text"
//               id="chargebackFee"
//               name="chargebackFee"
//               value={profileData.chargebackFee !== '' ? `${profileData.chargebackFee}` : 'N/A'}
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayOut Callback URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payoutCallbackURL"
//                 name="payoutCallbackURL"
//                 value={profileData.payoutCallbackURL || 'Not Available'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayoutCallbackEditClick} />
//             </div>
//           </div>

//           {/* PayIn Secret Key */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.payinSecretKey || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayIn Callback URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payinCallbackURL"
//                 name="payinCallbackURL"
//                 value={profileData.payinCallbackURL || 'Not Available'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
//             </div>
//           </div>

//           {/* Success Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="successRedirectURL">Success Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="successRedirectURL"
//                 name="successRedirectURL"
//                 value={profileData.successRedirectURL || 'Not Available'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleSuccessRedirectEditClick} />
//             </div>
//           </div>

//           {/* Failure Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="failureRedirectURL">Failure Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="failureRedirectURL"
//                 name="failureRedirectURL"
//                 value={profileData.failureRedirectURL || 'Not Available'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleFailureRedirectEditClick} />
//             </div>
//           </div>
//         </form>
//       </div>

//       {/* Modals */}
//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}

//       {/* PayIn Callback URL Edit Modal */}
//       {showPayinCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
//             <h3>Edit PayIn Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayinCallbackURL"
//                 value={newPayinCallbackURL}
//                 onChange={(e) => setNewPayinCallbackURL(e.target.value)}
//               />
//             </div>
//             {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Success Redirect URL Edit Modal */}
//       {showSuccessRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseSuccessRedirectModal} />
//             <h3>Edit Success Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newSuccessRedirectURL">New Success Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newSuccessRedirectURL"
//                 value={newSuccessRedirectURL}
//                 onChange={(e) => setNewSuccessRedirectURL(e.target.value)}
//               />
//             </div>
//             {successRedirectError && <p className="error-message">{successRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitSuccessRedirectChange}>
//               Update Success Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Failure Redirect URL Edit Modal */}
//       {showFailureRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseFailureRedirectModal} />
//             <h3>Edit Failure Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newFailureRedirectURL">New Failure Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newFailureRedirectURL"
//                 value={newFailureRedirectURL}
//                 onChange={(e) => setNewFailureRedirectURL(e.target.value)}
//               />
//             </div>
//             {failureRedirectError && <p className="error-message">{failureRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitFailureRedirectChange}>
//               Update Failure Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Bank Details Edit Modal */}
//       {showBankDetailsModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseBankDetailsModal} />
//             <h3>Edit Bank Details</h3>
//             <div className="form-group">
//               <label htmlFor="bankName">Bank Name:</label>
//               <input
//                 type="text"
//                 id="bankName"
//                 value={newBankDetails.bankName}
//                 onChange={(e) =>
//                   setNewBankDetails((prev) => ({ ...prev, bankName: e.target.value }))
//                 }
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="accountNumber">Account Number:</label>
//               <input
//                 type="text"
//                 id="accountNumber"
//                 value={newBankDetails.accountNumber}
//                 onChange={(e) =>
//                   setNewBankDetails((prev) => ({ ...prev, accountNumber: e.target.value }))
//                 }
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="ifscCode">IFSC Code:</label>
//               <input
//                 type="text"
//                 id="ifscCode"
//                 value={newBankDetails.ifscCode}
//                 onChange={(e) =>
//                   setNewBankDetails((prev) => ({ ...prev, ifscCode: e.target.value }))
//                 }
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="accountName">Account Name:</label>
//               <input
//                 type="text"
//                 id="accountName"
//                 value={newBankDetails.accountName}
//                 onChange={(e) =>
//                   setNewBankDetails((prev) => ({ ...prev, accountName: e.target.value }))
//                 }
//               />
//             </div>
//             {bankDetailsError && <p className="error-message">{bankDetailsError}</p>}
//             <button className="submit-btn" onClick={handleSubmitBankDetailsChange}>
//               Update Bank Details
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Wallet Address Edit Modal */}
//       {showWalletAddressModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseWalletAddressModal} />
//             <h3>Edit Wallet Address</h3>
//             <div className="form-group">
//               <label htmlFor="newWalletAddress">New Wallet Address:</label>
//               <input
//                 type="text"
//                 id="newWalletAddress"
//                 value={newWalletAddress}
//                 onChange={(e) => setNewWalletAddress(e.target.value)}
//               />
//             </div>
//             {walletAddressError && <p className="error-message">{walletAddressError}</p>}
//             <button className="submit-btn" onClick={handleSubmitWalletAddressChange}>
//               Update Wallet Address
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Payout Callback URL Edit Modal */}
//       {showPayoutCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayoutCallbackModal} />
//             <h3>Edit Payout Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayoutCallbackURL">New Payout Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayoutCallbackURL"
//                 value={newPayoutCallbackURL}
//                 onChange={(e) => setNewPayoutCallbackURL(e.target.value)}
//               />
//             </div>
//             {payoutCallbackError && <p className="error-message">{payoutCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayoutCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;




// import React, { useState, useEffect } from 'react';
// import '../styles/Profile.css';
// import { FaEdit, FaTimes } from 'react-icons/fa';

// const Profile = () => {
//   // State Variables for Profile Fields
//   const [profileData, setProfileData] = useState({
//     name: '',
//     email: '',
//     mid: '',
//     salt: '',
//     settlementPeriod: '',
//     refundFee: '',
//     chargebackFee: '',
//     payoutSecretKey: '',
//     payoutCallbackURL: '',
//     payinSecretKey: '',
//     payinCallbackURL: '',
//     successRedirectURL: '',
//     failureRedirectURL: '',
//     bankName: '',
//     accountNumber: '',
//     ifscCode: '',
//     accountName: '',
//     walletAddress: '',
//   });

//   // State variables for password change modal
//   const [showPasswordModal, setShowPasswordModal] = useState(false);
//   const [oldPassword, setOldPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');

//   // State variables for PayIn Callback URL modal
//   const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
//   const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
//   const [payinCallbackError, setPayinCallbackError] = useState('');

//   // State variables for Success Redirect URL modal
//   const [showSuccessRedirectModal, setShowSuccessRedirectModal] = useState(false);
//   const [newSuccessRedirectURL, setNewSuccessRedirectURL] = useState('');
//   const [successRedirectError, setSuccessRedirectError] = useState('');

//   // State variables for Failure Redirect URL modal
//   const [showFailureRedirectModal, setShowFailureRedirectModal] = useState(false);
//   const [newFailureRedirectURL, setNewFailureRedirectURL] = useState('');
//   const [failureRedirectError, setFailureRedirectError] = useState('');

//   // State variables for Bank Details modal
//   const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
//   const [newBankDetails, setNewBankDetails] = useState({
//     bankName: '',
//     accountNumber: '',
//     ifscCode: '',
//     accountName: '',
//   });
//   const [bankDetailsError, setBankDetailsError] = useState('');

//   // State variables for Wallet Address modal
//   const [showWalletAddressModal, setShowWalletAddressModal] = useState(false);
//   const [newWalletAddress, setNewWalletAddress] = useState('');
//   const [walletAddressError, setWalletAddressError] = useState('');

//   // State variables for Payout Callback URL modal
//   const [showPayoutCallbackModal, setShowPayoutCallbackModal] = useState(false);
//   const [newPayoutCallbackURL, setNewPayoutCallbackURL] = useState('');
//   const [payoutCallbackError, setPayoutCallbackError] = useState('');

//   // State variables for loading and error handling
//   const [isLoading, setIsLoading] = useState(true);
//   const [fetchError, setFetchError] = useState('');

//   // Fetch profile data from the server
//   useEffect(() => {
//     const fetchProfileData = async () => {
//       const mid = localStorage.getItem('merchantId');
//       if (!mid) {
//         setFetchError('Merchant ID not found in local storage.');
//         setIsLoading(false);
//         return;
//       }

//       // Prepare empty objects to hold partial data
//       let payinData = {};
//       let merchantData = {};
//       let payoutData = {};

//       // Fetch data from Merchant Project (for name, email, mid, salt, payinSecretKey, etc.)
//       try {
//         const merchantResponse = await fetch(
//           `https://merchant.payinfy.com/merchants/merchant-profile/${mid}/`
//         );
//         if (merchantResponse.ok) {
//           merchantData = await merchantResponse.json();
//         } else {
//           console.warn('Failed to fetch Merchant profile data. Some data may be missing.');
//         }
//       } catch (error) {
//         console.warn('Error fetching Merchant profile data:', error);
//       }

//       // Fetch data from PayIn Project (settlementPeriod, refundFee, etc.)
//       try {
//         const payinResponse = await fetch(`https://payin.payinfy.com/api/merchant-profile/${mid}/`);
//         if (payinResponse.ok) {
//           payinData = await payinResponse.json();
//         } else {
//           console.warn('Failed to fetch PayIn profile data. Some data may be missing.');
//         }
//       } catch (error) {
//         console.warn('Error fetching PayIn profile data:', error);
//       }

//       // Fetch additional data from Payouts Project (payoutSecretKey, payoutCallbackURL)
//       try {
//         const payoutResponse = await fetch(
//           `https://payouts.payinfy.com/payouts/payout-profile/${mid}/`
//         );
//         if (payoutResponse.ok) {
//           payoutData = await payoutResponse.json();
//         } else {
//           console.warn('Failed to fetch Payouts profile data. Some data may be missing.');
//         }
//       } catch (error) {
//         console.warn('Error fetching Payouts profile data:', error);
//       }

//       // Combine all fetched data, fallback to empty strings or undefined checks where data is missing
//       const updatedProfileData = {
//         // From Merchant Project (primary data)
//         name: merchantData.merchant_name || '',
//         email: merchantData.merchant_email || '',
//         mid: merchantData.merchant_id || '',
//         salt: merchantData.salt || '',

//         // settlement info from PayIn or defaults
//         settlementPeriod:
//           payinData.settlement_period !== null && payinData.settlement_period !== undefined
//             ? payinData.settlement_period
//             : '',
//         refundFee: payinData.refund_fee || '',
//         chargebackFee: payinData.chargeback_fee || '',

//         // payout info from Payout or fallback to merchant
//         payoutSecretKey: payoutData.payout_secret_key || merchantData.payout_secret_key || '',
//         payoutCallbackURL: payoutData.payout_callback_url || '',

//         // payin secret key now from merchant (since that's also in merchant data)
//         payinSecretKey: merchantData.payin_secret_key || '',

//         // other payin details
//         payinCallbackURL: payinData.payin_callback_url || '',
//         successRedirectURL: payinData.success_redirect_url || '',
//         failureRedirectURL: payinData.failure_redirect_url || '',
//         bankName: payinData.bank_name || '',
//         accountNumber: payinData.account_number || '',
//         ifscCode: payinData.ifsc_code || '',
//         accountName: payinData.account_name || '',
//         walletAddress: payinData.wallet_address || '',
//       };

//       setProfileData(updatedProfileData);

//       // Update localStorage with whatever data we have
//       for (const [key, value] of Object.entries(updatedProfileData)) {
//         localStorage.setItem(key, value);
//       }

//       setIsLoading(false);
//     };

//     fetchProfileData();
//   }, []);

//   // Function to handle clicking the password edit icon
//   const handlePasswordChangeClick = () => {
//     setShowPasswordModal(true);
//   };

//   // Function to handle closing the password modal
//   const handleClosePasswordModal = () => {
//     setShowPasswordModal(false);
//     setOldPassword('');
//     setNewPassword('');
//     setConfirmNewPassword('');
//     setPasswordError('');
//   };

//   // Function to submit the password change
//   const handleSubmitPasswordChange = async () => {
//     if (newPassword !== confirmNewPassword) {
//       setPasswordError('New passwords do not match');
//       return;
//     }

//     try {
//       const response = await fetch(
//         `https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             old_password: oldPassword,
//             new_password: newPassword,
//           }),
//         }
//       );

//       if (response.ok) {
//         alert('Password changed successfully');
//         handleClosePasswordModal();
//       } else {
//         const result = await response.json();
//         setPasswordError(result.message || 'Failed to change password');
//       }
//     } catch (error) {
//       setPasswordError('An error occurred');
//     }
//   };

//   // Function to handle clicking the PayIn Callback URL edit icon
//   const handlePayinCallbackEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowPayinCallbackModal(true);
//     setNewPayinCallbackURL(profileData.payinCallbackURL || '');
//   };

//   // Function to handle closing the PayIn Callback URL modal
//   const handleClosePayinCallbackModal = () => {
//     setShowPayinCallbackModal(false);
//     setNewPayinCallbackURL('');
//     setPayinCallbackError('');
//   };

//   // Function to submit the PayIn Callback URL change
//   const handleSubmitPayinCallbackChange = async () => {
//     try {
//       const response = await fetch(
//         `https://payin.payinfy.com/api/update-urls/${profileData.mid}/`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             payin_callback_url: newPayinCallbackURL,
//           }),
//         }
//       );

//       if (response.ok) {
//         alert('PayIn Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payinCallbackURL: newPayinCallbackURL,
//         }));
//         localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
//         handleClosePayinCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
//       }
//     } catch (error) {
//       setPayinCallbackError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Success Redirect URL edit icon
//   const handleSuccessRedirectEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowSuccessRedirectModal(true);
//     setNewSuccessRedirectURL(profileData.successRedirectURL || '');
//   };

//   // Function to handle closing the Success Redirect URL modal
//   const handleCloseSuccessRedirectModal = () => {
//     setShowSuccessRedirectModal(false);
//     setNewSuccessRedirectURL('');
//     setSuccessRedirectError('');
//   };

//   // Function to submit the Success Redirect URL change
//   const handleSubmitSuccessRedirectChange = async () => {
//     try {
//       const response = await fetch(
//         `https://payin.payinfy.com/api/update-urls/${profileData.mid}/`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             success_redirect_url: newSuccessRedirectURL,
//           }),
//         }
//       );

//       if (response.ok) {
//         alert('Success Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           successRedirectURL: newSuccessRedirectURL,
//         }));
//         localStorage.setItem('successRedirectURL', newSuccessRedirectURL);
//         handleCloseSuccessRedirectModal();
//       } else {
//         const result = await response.json();
//         setSuccessRedirectError(result.message || 'Failed to update Success Redirect URL');
//       }
//     } catch (error) {
//       setSuccessRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Failure Redirect URL edit icon
//   const handleFailureRedirectEditClick = () => {
//     if (!profileData.payinSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowFailureRedirectModal(true);
//     setNewFailureRedirectURL(profileData.failureRedirectURL || '');
//   };

//   // Function to handle closing the Failure Redirect URL modal
//   const handleCloseFailureRedirectModal = () => {
//     setShowFailureRedirectModal(false);
//     setNewFailureRedirectURL('');
//     setFailureRedirectError('');
//   };

//   // Function to submit the Failure Redirect URL change
//   const handleSubmitFailureRedirectChange = async () => {
//     try {
//       const response = await fetch(
//         `https://payin.payinfy.com/api/update-urls/${profileData.mid}/`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             failure_redirect_url: newFailureRedirectURL,
//           }),
//         }
//       );

//       if (response.ok) {
//         alert('Failure Redirect URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           failureRedirectURL: newFailureRedirectURL,
//         }));
//         localStorage.setItem('failureRedirectURL', newFailureRedirectURL);
//         handleCloseFailureRedirectModal();
//       } else {
//         const result = await response.json();
//         setFailureRedirectError(result.message || 'Failed to update Failure Redirect URL');
//       }
//     } catch (error) {
//       setFailureRedirectError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Bank Details edit icon
//   const handleBankDetailsEditClick = () => {
//     // We only allow editing bank details if payinSecretKey is generated
//     if (!profileData.SecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowBankDetailsModal(true);
//     setNewBankDetails({
//       bankName: profileData.bankName || '',
//       accountNumber: profileData.accountNumber || '',
//       ifscCode: profileData.ifscCode || '',
//       accountName: profileData.accountName || '',
//     });
//   };

//   // Function to handle closing the Bank Details modal
//   const handleCloseBankDetailsModal = () => {
//     setShowBankDetailsModal(false);
//     setNewBankDetails({
//       bankName: '',
//       accountNumber: '',
//       ifscCode: '',
//       accountName: '',
//     });
//     setBankDetailsError('');
//   };

//   // Function to submit the Bank Details change
//   const handleSubmitBankDetailsChange = async () => {
//     try {
//       const { bankName, accountNumber, ifscCode, accountName } = newBankDetails;
//       if (!bankName || !accountNumber || !ifscCode || !accountName) {
//         setBankDetailsError('All fields are required');
//         return;
//       }

//       const response = await fetch(
//         `https://payin.payinfy.com/api/update-bank-details/${profileData.mid}/`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             bank_name: bankName,
//             account_number: accountNumber,
//             ifsc_code: ifscCode,
//             account_name: accountName,
//           }),
//         }
//       );

//       if (response.ok) {
//         alert('Bank details updated successfully');
//         setProfileData((prevData) => ({
//           ...prevData,
//           bankName,
//           accountNumber,
//           ifscCode,
//           accountName,
//         }));
//         localStorage.setItem('bankName', bankName);
//         localStorage.setItem('accountNumber', accountNumber);
//         localStorage.setItem('ifscCode', ifscCode);
//         localStorage.setItem('accountName', accountName);
//         handleCloseBankDetailsModal();
//       } else {
//         const result = await response.json();
//         setBankDetailsError(result.message || 'Failed to update bank details');
//       }
//     } catch (error) {
//       setBankDetailsError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Wallet Address edit icon
//   const handleWalletAddressEditClick = () => {
//     if (!profileData.SecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowWalletAddressModal(true);
//     setNewWalletAddress(profileData.walletAddress || '');
//   };

//   // Function to handle closing the Wallet Address modal
//   const handleCloseWalletAddressModal = () => {
//     setShowWalletAddressModal(false);
//     setNewWalletAddress('');
//     setWalletAddressError('');
//   };

//   // Function to submit the Wallet Address change
//   const handleSubmitWalletAddressChange = async () => {
//     try {
//       if (!newWalletAddress) {
//         setWalletAddressError('Wallet address is required');
//         return;
//       }

//       const response = await fetch(
//         `https://payin.payinfy.com/api/update-wallet-address/${profileData.mid}/`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({ wallet_address: newWalletAddress }),
//         }
//       );

//       if (response.ok) {
//         alert('Wallet address updated successfully');
//         setProfileData((prevData) => ({
//           ...prevData,
//           walletAddress: newWalletAddress,
//         }));
//         localStorage.setItem('walletAddress', newWalletAddress);
//         handleCloseWalletAddressModal();
//       } else {
//         const result = await response.json();
//         setWalletAddressError(result.message || 'Failed to update wallet address');
//       }
//     } catch (error) {
//       setWalletAddressError('An error occurred');
//     }
//   };

//   // Function to handle clicking the Payout Callback URL edit icon
//   const handlePayoutCallbackEditClick = () => {
//     if (!profileData.payoutSecretKey) {
//       alert('Token not generated yet');
//       return;
//     }
//     setShowPayoutCallbackModal(true);
//     setNewPayoutCallbackURL(profileData.payoutCallbackURL || '');
//   };

//   // Function to handle closing the Payout Callback URL modal
//   const handleClosePayoutCallbackModal = () => {
//     setShowPayoutCallbackModal(false);
//     setNewPayoutCallbackURL('');
//     setPayoutCallbackError('');
//   };

//   // Function to submit the Payout Callback URL change
//   const handleSubmitPayoutCallbackChange = async () => {
//     try {
//       const response = await fetch(
//         `https://payouts.payinfy.com/payouts/update-payout-callback-url/${profileData.mid}/`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             payout_callback_url: newPayoutCallbackURL,
//           }),
//         }
//       );

//       if (response.ok) {
//         alert('Payout Callback URL updated successfully');
//         // Update the local state and localStorage
//         setProfileData((prevData) => ({
//           ...prevData,
//           payoutCallbackURL: newPayoutCallbackURL,
//         }));
//         localStorage.setItem('payoutCallbackURL', newPayoutCallbackURL);
//         handleClosePayoutCallbackModal();
//       } else {
//         const result = await response.json();
//         setPayoutCallbackError(result.message || 'Failed to update Payout Callback URL');
//       }
//     } catch (error) {
//       setPayoutCallbackError('An error occurred');
//     }
//   };

//   // Render loading or error states
//   if (isLoading) {
//     return (
//       <div className="profile-content">
//         <p>Loading profile...</p>
//       </div>
//     );
//   }

//   // If there's a fatal error like missing merchantId, show it
//   if (fetchError) {
//     return (
//       <div className="profile-content">
//         <p className="error-message">Error: {fetchError}</p>
//       </div>
//     );
//   }

//   return (
//     <div className="profile-content">
//       {/* Header Section */}
//       <header className="profile-header">
//         <div className="profile-title">
//           <h2>Profile</h2>
//         </div>
//       </header>

//       {/* Profile Information */}
//       <div className="profile-info-container">
//         <form className="profile-form">
//           {/* Personal Information Section */}
//           <h3 className="section-heading">Personal Information</h3>
//           <div className="form-group">
//             <label htmlFor="name">Name:</label>
//             <input type="text" id="name" name="name" value={profileData.name} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email">Email:</label>
//             <input type="email" id="email" name="email" value={profileData.email} readOnly />
//           </div>

//           {/* Bank Details Field with Edit Icon */}
//           <div className="form-group bank-details-group">
//             <label htmlFor="bankDetails">Bank Details:</label>
//             <div className="bank-details-field">
//               <input
//                 type="text"
//                 id="bankDetails"
//                 name="bankDetails"
//                 value={
//                   profileData.bankName && profileData.accountNumber
//                     ? `${profileData.bankName}, ${profileData.accountNumber}`
//                     : 'Not provided'
//                 }
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleBankDetailsEditClick} />
//             </div>
//           </div>

//           {/* Wallet Address Field with Edit Icon */}
//           <div className="form-group wallet-address-group">
//             <label htmlFor="walletAddress">Wallet Address:</label>
//             <div className="wallet-address-field">
//               <input
//                 type="text"
//                 id="walletAddress"
//                 name="walletAddress"
//                 value={profileData.walletAddress || 'Not provided'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleWalletAddressEditClick} />
//             </div>
//           </div>

//           {/* Merchant Information Section */}
//           <h3 className="section-heading">Merchant Information</h3>
//           <div className="form-group">
//             <label htmlFor="mid">MID:</label>
//             <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
//           </div>

//           <div className="form-group">
//             <label htmlFor="salt">Salt:</label>
//             <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
//           </div>

//           {/* Settlement Period Section */}
//           <div className="form-group">
//             <label htmlFor="settlement">Settlement Period:</label>
//             <input
//               type="text"
//               id="settlement"
//               name="settlement"
//               value={
//                 profileData.settlementPeriod !== ''
//                   ? `T + ${profileData.settlementPeriod} days`
//                   : 'N/A'
//               }
//               readOnly
//             />
//           </div>

//           {/* Refund Fee Field */}
//           <div className="form-group">
//             <label htmlFor="refundFee">Refund Fee:</label>
//             <input
//               type="text"
//               id="refundFee"
//               name="refundFee"
//               value={profileData.refundFee !== '' ? `${profileData.refundFee}` : 'N/A'}
//               readOnly
//             />
//           </div>

//           {/* Chargeback Fee Field */}
//           <div className="form-group">
//             <label htmlFor="chargebackFee">Chargeback Fee:</label>
//             <input
//               type="text"
//               id="chargebackFee"
//               name="chargebackFee"
//               value={profileData.chargebackFee !== '' ? `${profileData.chargebackFee}` : 'N/A'}
//               readOnly
//             />
//           </div>

//           {/* Password Field with Edit Icon */}
//           <div className="form-group password-group">
//             <label htmlFor="password">Password:</label>
//             <div className="password-field">
//               <input type="password" id="password" name="password" value="********" readOnly />
//               <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
//             </div>
//           </div>

//           {/* API Credentials Section */}
//           <h3 className="section-heading">API Credentials</h3>
//           {/* PayOut Secret Key */}
//           <div className="form-group">
//             <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
//             <input
//               type="text"
//               id="payoutSecretKey"
//               name="payoutSecretKey"
//               value={profileData.payoutSecretKey || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayOut Callback URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payoutCallbackURL"
//                 name="payoutCallbackURL"
//                 value={profileData.payoutCallbackURL || 'Not Available'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayoutCallbackEditClick} />
//             </div>
//           </div>

//           {/* PayIn Secret Key (from Merchant) */}
//           <div className="form-group">
//             <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
//             <input
//               type="text"
//               id="payinSecretKey"
//               name="payinSecretKey"
//               value={profileData.SecretKey || 'Not Available'}
//               readOnly
//             />
//           </div>

//           {/* PayIn Callback URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="payinCallbackURL"
//                 name="payinCallbackURL"
//                 value={profileData.payinCallbackURL || 'Not Available'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
//             </div>
//           </div>

//           {/* Success Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="successRedirectURL">Success Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="successRedirectURL"
//                 name="successRedirectURL"
//                 value={profileData.successRedirectURL || 'Not Available'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleSuccessRedirectEditClick} />
//             </div>
//           </div>

//           {/* Failure Redirect URL Field with Edit Icon */}
//           <div className="form-group callback-url-group">
//             <label htmlFor="failureRedirectURL">Failure Redirect URL:</label>
//             <div className="callback-url-field">
//               <input
//                 type="text"
//                 id="failureRedirectURL"
//                 name="failureRedirectURL"
//                 value={profileData.failureRedirectURL || 'Not Available'}
//                 readOnly
//               />
//               <FaEdit className="edit-icon" onClick={handleFailureRedirectEditClick} />
//             </div>
//           </div>
//         </form>
//       </div>

//       {/* Modals */}
//       {/* Password Change Modal */}
//       {showPasswordModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
//             <h3>Change Password</h3>
//             <div className="form-group">
//               <label htmlFor="oldPassword">Old Password:</label>
//               <input
//                 type="password"
//                 id="oldPassword"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="newPassword">New Password:</label>
//               <input
//                 type="password"
//                 id="newPassword"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="confirmNewPassword">Confirm New Password:</label>
//               <input
//                 type="password"
//                 id="confirmNewPassword"
//                 value={confirmNewPassword}
//                 onChange={(e) => setConfirmNewPassword(e.target.value)}
//               />
//             </div>
//             {passwordError && <p className="error-message">{passwordError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPasswordChange}>
//               Change Password
//             </button>
//           </div>
//         </div>
//       )}

//       {/* PayIn Callback URL Edit Modal */}
//       {showPayinCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
//             <h3>Edit PayIn Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayinCallbackURL"
//                 value={newPayinCallbackURL}
//                 onChange={(e) => setNewPayinCallbackURL(e.target.value)}
//               />
//             </div>
//             {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Success Redirect URL Edit Modal */}
//       {showSuccessRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseSuccessRedirectModal} />
//             <h3>Edit Success Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newSuccessRedirectURL">New Success Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newSuccessRedirectURL"
//                 value={newSuccessRedirectURL}
//                 onChange={(e) => setNewSuccessRedirectURL(e.target.value)}
//               />
//             </div>
//             {successRedirectError && <p className="error-message">{successRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitSuccessRedirectChange}>
//               Update Success Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Failure Redirect URL Edit Modal */}
//       {showFailureRedirectModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseFailureRedirectModal} />
//             <h3>Edit Failure Redirect URL</h3>
//             <div className="form-group">
//               <label htmlFor="newFailureRedirectURL">New Failure Redirect URL:</label>
//               <input
//                 type="text"
//                 id="newFailureRedirectURL"
//                 value={newFailureRedirectURL}
//                 onChange={(e) => setNewFailureRedirectURL(e.target.value)}
//               />
//             </div>
//             {failureRedirectError && <p className="error-message">{failureRedirectError}</p>}
//             <button className="submit-btn" onClick={handleSubmitFailureRedirectChange}>
//               Update Failure Redirect URL
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Bank Details Edit Modal */}
//       {showBankDetailsModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseBankDetailsModal} />
//             <h3>Edit Bank Details</h3>
//             <div className="form-group">
//               <label htmlFor="bankName">Bank Name:</label>
//               <input
//                 type="text"
//                 id="bankName"
//                 value={newBankDetails.bankName}
//                 onChange={(e) =>
//                   setNewBankDetails((prev) => ({ ...prev, bankName: e.target.value }))
//                 }
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="accountNumber">Account Number:</label>
//               <input
//                 type="text"
//                 id="accountNumber"
//                 value={newBankDetails.accountNumber}
//                 onChange={(e) =>
//                   setNewBankDetails((prev) => ({ ...prev, accountNumber: e.target.value }))
//                 }
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="ifscCode">IFSC Code:</label>
//               <input
//                 type="text"
//                 id="ifscCode"
//                 value={newBankDetails.ifscCode}
//                 onChange={(e) =>
//                   setNewBankDetails((prev) => ({ ...prev, ifscCode: e.target.value }))
//                 }
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="accountName">Account Name:</label>
//               <input
//                 type="text"
//                 id="accountName"
//                 value={newBankDetails.accountName}
//                 onChange={(e) =>
//                   setNewBankDetails((prev) => ({ ...prev, accountName: e.target.value }))
//                 }
//               />
//             </div>
//             {bankDetailsError && <p className="error-message">{bankDetailsError}</p>}
//             <button className="submit-btn" onClick={handleSubmitBankDetailsChange}>
//               Update Bank Details
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Wallet Address Edit Modal */}
//       {showWalletAddressModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleCloseWalletAddressModal} />
//             <h3>Edit Wallet Address</h3>
//             <div className="form-group">
//               <label htmlFor="newWalletAddress">New Wallet Address:</label>
//               <input
//                 type="text"
//                 id="newWalletAddress"
//                 value={newWalletAddress}
//                 onChange={(e) => setNewWalletAddress(e.target.value)}
//               />
//             </div>
//             {walletAddressError && <p className="error-message">{walletAddressError}</p>}
//             <button className="submit-btn" onClick={handleSubmitWalletAddressChange}>
//               Update Wallet Address
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Payout Callback URL Edit Modal */}
//       {showPayoutCallbackModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <FaTimes className="close-icon" onClick={handleClosePayoutCallbackModal} />
//             <h3>Edit Payout Callback URL</h3>
//             <div className="form-group">
//               <label htmlFor="newPayoutCallbackURL">New Payout Callback URL:</label>
//               <input
//                 type="text"
//                 id="newPayoutCallbackURL"
//                 value={newPayoutCallbackURL}
//                 onChange={(e) => setNewPayoutCallbackURL(e.target.value)}
//               />
//             </div>
//             {payoutCallbackError && <p className="error-message">{payoutCallbackError}</p>}
//             <button className="submit-btn" onClick={handleSubmitPayoutCallbackChange}>
//               Update Callback URL
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Profile;




import React, { useState, useEffect } from 'react';
import '../styles/Profile.css';
import { FaEdit, FaTimes } from 'react-icons/fa';

const Profile = () => {
  const [profileData, setProfileData] = useState({
    name: '',
    email: '',
    mid: '',
    salt: '',
    settlementPeriod: '',
    refundFee: '',
    chargebackFee: '',
    payoutSecretKey: '',
    payoutCallbackURL: '',
    payinSecretKey: '',        // We'll store 'merchantData.secret_key' here
    payinCallbackURL: '',
    successRedirectURL: '',
    failureRedirectURL: '',
    bankName: '',
    accountNumber: '',
    ifscCode: '',
    accountName: '',
    walletAddress: '',
  });

  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [showPayinCallbackModal, setShowPayinCallbackModal] = useState(false);
  const [newPayinCallbackURL, setNewPayinCallbackURL] = useState('');
  const [payinCallbackError, setPayinCallbackError] = useState('');

  const [showSuccessRedirectModal, setShowSuccessRedirectModal] = useState(false);
  const [newSuccessRedirectURL, setNewSuccessRedirectURL] = useState('');
  const [successRedirectError, setSuccessRedirectError] = useState('');

  const [showFailureRedirectModal, setShowFailureRedirectModal] = useState(false);
  const [newFailureRedirectURL, setNewFailureRedirectURL] = useState('');
  const [failureRedirectError, setFailureRedirectError] = useState('');

  const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
  const [newBankDetails, setNewBankDetails] = useState({
    bankName: '',
    accountNumber: '',
    ifscCode: '',
    accountName: '',
  });
  const [bankDetailsError, setBankDetailsError] = useState('');

  const [showWalletAddressModal, setShowWalletAddressModal] = useState(false);
  const [newWalletAddress, setNewWalletAddress] = useState('');
  const [walletAddressError, setWalletAddressError] = useState('');

  const [showPayoutCallbackModal, setShowPayoutCallbackModal] = useState(false);
  const [newPayoutCallbackURL, setNewPayoutCallbackURL] = useState('');
  const [payoutCallbackError, setPayoutCallbackError] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState('');

  useEffect(() => {
    const fetchProfileData = async () => {
      const mid = localStorage.getItem('merchantId');
      if (!mid) {
        setFetchError('Merchant ID not found in local storage.');
        setIsLoading(false);
        return;
      }

      let merchantData = {};
      let payinData = {};
      let payoutData = {};

      // 1) Fetch from MERCHANT (the main source for name, email, mid, salt, secret_key)
      try {
        const merchantResponse = await fetch(
          `https://merchant.payinfy.com/merchants/merchant-profile/${mid}/`
        );
        if (merchantResponse.ok) {
          merchantData = await merchantResponse.json();
        } else {
          console.warn('Failed to fetch Merchant profile data. Some data may be missing.');
        }
      } catch (error) {
        console.warn('Error fetching Merchant profile data:', error);
      }

      // 2) Fetch from PAYIN (settlementPeriod, refundFee, bank details, etc.)
      try {
        const payinResponse = await fetch(`https://payin.payinfy.com/api/merchant-profile/${mid}/`);
        if (payinResponse.ok) {
          payinData = await payinResponse.json();
        } else {
          console.warn('Failed to fetch PayIn profile data. Some data may be missing.');
        }
      } catch (error) {
        console.warn('Error fetching PayIn profile data:', error);
      }

      // 3) Fetch from PAYOUT (payoutSecretKey, payoutCallbackURL)
      try {
        const payoutResponse = await fetch(
          `https://payouts.payinfy.com/payouts/payout-profile/${mid}/`
        );
        if (payoutResponse.ok) {
          payoutData = await payoutResponse.json();
        } else {
          console.warn('Failed to fetch Payouts profile data. Some data may be missing.');
        }
      } catch (error) {
        console.warn('Error fetching Payouts profile data:', error);
      }

      // Consolidate all data
      const updatedProfileData = {
        // From Merchant
        name: merchantData.merchant_name || '',
        email: merchantData.merchant_email || '',
        mid: merchantData.merchant_id || '',
        salt: merchantData.salt || '',

        // The "secret_key" from merchant is the PayIn secret key
        payinSecretKey: merchantData.secret_key || '',

        // From PayIn
        settlementPeriod:
          payinData.settlement_period !== null && payinData.settlement_period !== undefined
            ? payinData.settlement_period
            : '',
        refundFee: payinData.refund_fee || '',
        chargebackFee: payinData.chargeback_fee || '',
        payinCallbackURL: payinData.payin_callback_url || '',
        successRedirectURL: payinData.success_redirect_url || '',
        failureRedirectURL: payinData.failure_redirect_url || '',
        bankName: payinData.bank_name || '',
        accountNumber: payinData.account_number || '',
        ifscCode: payinData.ifsc_code || '',
        accountName: payinData.account_name || '',
        walletAddress: payinData.wallet_address || '',

        // From Payout
        payoutSecretKey: payoutData.payout_secret_key || merchantData.payout_secret_key || '',
        payoutCallbackURL: payoutData.payout_callback_url || '',
      };

      setProfileData(updatedProfileData);

      // Update localStorage
      for (const [key, value] of Object.entries(updatedProfileData)) {
        localStorage.setItem(key, value);
      }

      setIsLoading(false);
    };

    fetchProfileData();
  }, []);

  // Password Modal
  const handlePasswordChangeClick = () => {
    setShowPasswordModal(true);
  };

  const handleClosePasswordModal = () => {
    setShowPasswordModal(false);
    setOldPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    setPasswordError('');
  };

  const handleSubmitPasswordChange = async () => {
    if (newPassword !== confirmNewPassword) {
      setPasswordError('New passwords do not match');
      return;
    }

    try {
      const response = await fetch(
        `https://merchant.payinfy.com/merchants/change-password/${profileData.mid}/`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            old_password: oldPassword,
            new_password: newPassword,
          }),
        }
      );

      if (response.ok) {
        alert('Password changed successfully');
        handleClosePasswordModal();
      } else {
        const result = await response.json();
        setPasswordError(result.message || 'Failed to change password');
      }
    } catch (error) {
      setPasswordError('An error occurred');
    }
  };

  // PayIn Callback
  const handlePayinCallbackEditClick = () => {
    if (!profileData.payinSecretKey) {
      alert('Token not generated yet');
      return;
    }
    setShowPayinCallbackModal(true);
    setNewPayinCallbackURL(profileData.payinCallbackURL || '');
  };

  const handleClosePayinCallbackModal = () => {
    setShowPayinCallbackModal(false);
    setNewPayinCallbackURL('');
    setPayinCallbackError('');
  };

  const handleSubmitPayinCallbackChange = async () => {
    try {
      const response = await fetch(
        `https://payin.payinfy.com/api/update-urls/${profileData.mid}/`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ payin_callback_url: newPayinCallbackURL }),
        }
      );

      if (response.ok) {
        alert('PayIn Callback URL updated successfully');
        setProfileData((prevData) => ({
          ...prevData,
          payinCallbackURL: newPayinCallbackURL,
        }));
        localStorage.setItem('payinCallbackURL', newPayinCallbackURL);
        handleClosePayinCallbackModal();
      } else {
        const result = await response.json();
        setPayinCallbackError(result.message || 'Failed to update PayIn Callback URL');
      }
    } catch (error) {
      setPayinCallbackError('An error occurred');
    }
  };

  // Success Redirect
  const handleSuccessRedirectEditClick = () => {
    if (!profileData.payinSecretKey) {
      alert('Token not generated yet');
      return;
    }
    setShowSuccessRedirectModal(true);
    setNewSuccessRedirectURL(profileData.successRedirectURL || '');
  };

  const handleCloseSuccessRedirectModal = () => {
    setShowSuccessRedirectModal(false);
    setNewSuccessRedirectURL('');
    setSuccessRedirectError('');
  };

  const handleSubmitSuccessRedirectChange = async () => {
    try {
      const response = await fetch(
        `https://payin.payinfy.com/api/update-urls/${profileData.mid}/`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ success_redirect_url: newSuccessRedirectURL }),
        }
      );

      if (response.ok) {
        alert('Success Redirect URL updated successfully');
        setProfileData((prevData) => ({
          ...prevData,
          successRedirectURL: newSuccessRedirectURL,
        }));
        localStorage.setItem('successRedirectURL', newSuccessRedirectURL);
        handleCloseSuccessRedirectModal();
      } else {
        const result = await response.json();
        setSuccessRedirectError(result.message || 'Failed to update Success Redirect URL');
      }
    } catch (error) {
      setSuccessRedirectError('An error occurred');
    }
  };

  // Failure Redirect
  const handleFailureRedirectEditClick = () => {
    if (!profileData.payinSecretKey) {
      alert('Token not generated yet');
      return;
    }
    setShowFailureRedirectModal(true);
    setNewFailureRedirectURL(profileData.failureRedirectURL || '');
  };

  const handleCloseFailureRedirectModal = () => {
    setShowFailureRedirectModal(false);
    setNewFailureRedirectURL('');
    setFailureRedirectError('');
  };

  const handleSubmitFailureRedirectChange = async () => {
    try {
      const response = await fetch(
        `https://payin.payinfy.com/api/update-urls/${profileData.mid}/`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ failure_redirect_url: newFailureRedirectURL }),
        }
      );

      if (response.ok) {
        alert('Failure Redirect URL updated successfully');
        setProfileData((prevData) => ({
          ...prevData,
          failureRedirectURL: newFailureRedirectURL,
        }));
        localStorage.setItem('failureRedirectURL', newFailureRedirectURL);
        handleCloseFailureRedirectModal();
      } else {
        const result = await response.json();
        setFailureRedirectError(result.message || 'Failed to update Failure Redirect URL');
      }
    } catch (error) {
      setFailureRedirectError('An error occurred');
    }
  };

  // Bank Details
  const handleBankDetailsEditClick = () => {
    if (!profileData.payinSecretKey) {
      alert('Token not generated yet');
      return;
    }
    setShowBankDetailsModal(true);
    setNewBankDetails({
      bankName: profileData.bankName || '',
      accountNumber: profileData.accountNumber || '',
      ifscCode: profileData.ifscCode || '',
      accountName: profileData.accountName || '',
    });
  };

  const handleCloseBankDetailsModal = () => {
    setShowBankDetailsModal(false);
    setNewBankDetails({ bankName: '', accountNumber: '', ifscCode: '', accountName: '' });
    setBankDetailsError('');
  };

  const handleSubmitBankDetailsChange = async () => {
    try {
      const { bankName, accountNumber, ifscCode, accountName } = newBankDetails;
      if (!bankName || !accountNumber || !ifscCode || !accountName) {
        setBankDetailsError('All fields are required');
        return;
      }

      const response = await fetch(
        `https://payin.payinfy.com/api/update-bank-details/${profileData.mid}/`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            bank_name: bankName,
            account_number: accountNumber,
            ifsc_code: ifscCode,
            account_name: accountName,
          }),
        }
      );

      if (response.ok) {
        alert('Bank details updated successfully');
        setProfileData((prevData) => ({
          ...prevData,
          bankName,
          accountNumber,
          ifscCode,
          accountName,
        }));
        localStorage.setItem('bankName', bankName);
        localStorage.setItem('accountNumber', accountNumber);
        localStorage.setItem('ifscCode', ifscCode);
        localStorage.setItem('accountName', accountName);
        handleCloseBankDetailsModal();
      } else {
        const result = await response.json();
        setBankDetailsError(result.message || 'Failed to update bank details');
      }
    } catch (error) {
      setBankDetailsError('An error occurred');
    }
  };

  // Wallet Address
  const handleWalletAddressEditClick = () => {
    if (!profileData.payinSecretKey) {
      alert('Token not generated yet');
      return;
    }
    setShowWalletAddressModal(true);
    setNewWalletAddress(profileData.walletAddress || '');
  };

  const handleCloseWalletAddressModal = () => {
    setShowWalletAddressModal(false);
    setNewWalletAddress('');
    setWalletAddressError('');
  };

  const handleSubmitWalletAddressChange = async () => {
    try {
      if (!newWalletAddress) {
        setWalletAddressError('Wallet address is required');
        return;
      }

      const response = await fetch(
        `https://payin.payinfy.com/api/update-wallet-address/${profileData.mid}/`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ wallet_address: newWalletAddress }),
        }
      );

      if (response.ok) {
        alert('Wallet address updated successfully');
        setProfileData((prevData) => ({
          ...prevData,
          walletAddress: newWalletAddress,
        }));
        localStorage.setItem('walletAddress', newWalletAddress);
        handleCloseWalletAddressModal();
      } else {
        const result = await response.json();
        setWalletAddressError(result.message || 'Failed to update wallet address');
      }
    } catch (error) {
      setWalletAddressError('An error occurred');
    }
  };

  // Payout Callback
  const handlePayoutCallbackEditClick = () => {
    if (!profileData.payoutSecretKey) {
      alert('Token not generated yet');
      return;
    }
    setShowPayoutCallbackModal(true);
    setNewPayoutCallbackURL(profileData.payoutCallbackURL || '');
  };

  const handleClosePayoutCallbackModal = () => {
    setShowPayoutCallbackModal(false);
    setNewPayoutCallbackURL('');
    setPayoutCallbackError('');
  };

  const handleSubmitPayoutCallbackChange = async () => {
    try {
      const response = await fetch(
        `https://payouts.payinfy.com/payouts/update-payout-callback-url/${profileData.mid}/`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ payout_callback_url: newPayoutCallbackURL }),
        }
      );

      if (response.ok) {
        alert('Payout Callback URL updated successfully');
        setProfileData((prevData) => ({
          ...prevData,
          payoutCallbackURL: newPayoutCallbackURL,
        }));
        localStorage.setItem('payoutCallbackURL', newPayoutCallbackURL);
        handleClosePayoutCallbackModal();
      } else {
        const result = await response.json();
        setPayoutCallbackError(result.message || 'Failed to update Payout Callback URL');
      }
    } catch (error) {
      setPayoutCallbackError('An error occurred');
    }
  };

  if (isLoading) {
    return (
      <div className="profile-content">
        <p>Loading profile...</p>
      </div>
    );
  }

  if (fetchError) {
    return (
      <div className="profile-content">
        <p className="error-message">Error: {fetchError}</p>
      </div>
    );
  }

  return (
    <div className="profile-content">
      <header className="profile-header">
        <div className="profile-title">
          <h2>Profile</h2>
        </div>
      </header>

      <div className="profile-info-container">
        <form className="profile-form">
          <h3 className="section-heading">Personal Information</h3>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" value={profileData.name} readOnly />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" value={profileData.email} readOnly />
          </div>

          <div className="form-group bank-details-group">
            <label htmlFor="bankDetails">Bank Details:</label>
            <div className="bank-details-field">
              <input
                type="text"
                id="bankDetails"
                name="bankDetails"
                value={
                  profileData.bankName && profileData.accountNumber
                    ? `${profileData.bankName}, ${profileData.accountNumber}`
                    : 'Not provided'
                }
                readOnly
              />
              <FaEdit className="edit-icon" onClick={handleBankDetailsEditClick} />
            </div>
          </div>

          <div className="form-group wallet-address-group">
            <label htmlFor="walletAddress">Wallet Address:</label>
            <div className="wallet-address-field">
              <input
                type="text"
                id="walletAddress"
                name="walletAddress"
                value={profileData.walletAddress || 'Not provided'}
                readOnly
              />
              <FaEdit className="edit-icon" onClick={handleWalletAddressEditClick} />
            </div>
          </div>

          <h3 className="section-heading">Merchant Information</h3>
          <div className="form-group">
            <label htmlFor="mid">MID:</label>
            <input type="text" id="mid" name="mid" value={profileData.mid} readOnly />
          </div>

          <div className="form-group">
            <label htmlFor="salt">Salt:</label>
            <input type="text" id="salt" name="salt" value={profileData.salt} readOnly />
          </div>

          <div className="form-group">
            <label htmlFor="settlement">Settlement Period:</label>
            <input
              type="text"
              id="settlement"
              name="settlement"
              value={
                profileData.settlementPeriod !== ''
                  ? `T + ${profileData.settlementPeriod} days`
                  : 'N/A'
              }
              readOnly
            />
          </div>

          <div className="form-group">
            <label htmlFor="refundFee">Refund Fee:</label>
            <input
              type="text"
              id="refundFee"
              name="refundFee"
              value={profileData.refundFee || 'N/A'}
              readOnly
            />
          </div>

          <div className="form-group">
            <label htmlFor="chargebackFee">Chargeback Fee:</label>
            <input
              type="text"
              id="chargebackFee"
              name="chargebackFee"
              value={profileData.chargebackFee || 'N/A'}
              readOnly
            />
          </div>

          <div className="form-group password-group">
            <label htmlFor="password">Password:</label>
            <div className="password-field">
              <input type="password" id="password" name="password" value="********" readOnly />
              <FaEdit className="edit-icon" onClick={handlePasswordChangeClick} />
            </div>
          </div>

          <h3 className="section-heading">API Credentials</h3>
          <div className="form-group">
            <label htmlFor="payoutSecretKey">PayOut Secret Key:</label>
            <input
              type="text"
              id="payoutSecretKey"
              name="payoutSecretKey"
              value={profileData.payoutSecretKey || 'Not Available'}
              readOnly
            />
          </div>

          <div className="form-group callback-url-group">
            <label htmlFor="payoutCallbackURL">PayOut Callback URL:</label>
            <div className="callback-url-field">
              <input
                type="text"
                id="payoutCallbackURL"
                name="payoutCallbackURL"
                value={profileData.payoutCallbackURL || 'Not Available'}
                readOnly
              />
              <FaEdit className="edit-icon" onClick={handlePayoutCallbackEditClick} />
            </div>
          </div>

          {/* Here we show "PayIn Secret Key," but we actually fetch from merchantData.secret_key */}
          <div className="form-group">
            <label htmlFor="payinSecretKey">PayIn Secret Key:</label>
            <input
              type="text"
              id="payinSecretKey"
              name="payinSecretKey"
              value={profileData.payinSecretKey || 'Not Available'}
              readOnly
            />
          </div>

          <div className="form-group callback-url-group">
            <label htmlFor="payinCallbackURL">PayIn Callback URL:</label>
            <div className="callback-url-field">
              <input
                type="text"
                id="payinCallbackURL"
                name="payinCallbackURL"
                value={profileData.payinCallbackURL || 'Not Available'}
                readOnly
              />
              <FaEdit className="edit-icon" onClick={handlePayinCallbackEditClick} />
            </div>
          </div>

          <div className="form-group callback-url-group">
            <label htmlFor="successRedirectURL">Success Redirect URL:</label>
            <div className="callback-url-field">
              <input
                type="text"
                id="successRedirectURL"
                name="successRedirectURL"
                value={profileData.successRedirectURL || 'Not Available'}
                readOnly
              />
              <FaEdit className="edit-icon" onClick={handleSuccessRedirectEditClick} />
            </div>
          </div>

          <div className="form-group callback-url-group">
            <label htmlFor="failureRedirectURL">Failure Redirect URL:</label>
            <div className="callback-url-field">
              <input
                type="text"
                id="failureRedirectURL"
                name="failureRedirectURL"
                value={profileData.failureRedirectURL || 'Not Available'}
                readOnly
              />
              <FaEdit className="edit-icon" onClick={handleFailureRedirectEditClick} />
            </div>
          </div>
        </form>
      </div>

      {/* ======================== Modals ======================== */}
      {/* Password Change Modal */}
      {showPasswordModal && (
        <div className="modal">
          <div className="modal-content">
            <FaTimes className="close-icon" onClick={handleClosePasswordModal} />
            <h3>Change Password</h3>
            <div className="form-group">
              <label htmlFor="oldPassword">Old Password:</label>
              <input
                type="password"
                id="oldPassword"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="newPassword">New Password:</label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmNewPassword">Confirm New Password:</label>
              <input
                type="password"
                id="confirmNewPassword"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </div>
            {passwordError && <p className="error-message">{passwordError}</p>}
            <button className="submit-btn" onClick={handleSubmitPasswordChange}>
              Change Password
            </button>
          </div>
        </div>
      )}

      {/* PayIn Callback URL Modal */}
      {showPayinCallbackModal && (
        <div className="modal">
          <div className="modal-content">
            <FaTimes className="close-icon" onClick={handleClosePayinCallbackModal} />
            <h3>Edit PayIn Callback URL</h3>
            <div className="form-group">
              <label htmlFor="newPayinCallbackURL">New PayIn Callback URL:</label>
              <input
                type="text"
                id="newPayinCallbackURL"
                value={newPayinCallbackURL}
                onChange={(e) => setNewPayinCallbackURL(e.target.value)}
              />
            </div>
            {payinCallbackError && <p className="error-message">{payinCallbackError}</p>}
            <button className="submit-btn" onClick={handleSubmitPayinCallbackChange}>
              Update Callback URL
            </button>
          </div>
        </div>
      )}

      {/* Success Redirect URL Modal */}
      {showSuccessRedirectModal && (
        <div className="modal">
          <div className="modal-content">
            <FaTimes className="close-icon" onClick={handleCloseSuccessRedirectModal} />
            <h3>Edit Success Redirect URL</h3>
            <div className="form-group">
              <label htmlFor="newSuccessRedirectURL">New Success Redirect URL:</label>
              <input
                type="text"
                id="newSuccessRedirectURL"
                value={newSuccessRedirectURL}
                onChange={(e) => setNewSuccessRedirectURL(e.target.value)}
              />
            </div>
            {successRedirectError && <p className="error-message">{successRedirectError}</p>}
            <button className="submit-btn" onClick={handleSubmitSuccessRedirectChange}>
              Update Success Redirect URL
            </button>
          </div>
        </div>
      )}

      {/* Failure Redirect URL Modal */}
      {showFailureRedirectModal && (
        <div className="modal">
          <div className="modal-content">
            <FaTimes className="close-icon" onClick={handleCloseFailureRedirectModal} />
            <h3>Edit Failure Redirect URL</h3>
            <div className="form-group">
              <label htmlFor="newFailureRedirectURL">New Failure Redirect URL:</label>
              <input
                type="text"
                id="newFailureRedirectURL"
                value={newFailureRedirectURL}
                onChange={(e) => setNewFailureRedirectURL(e.target.value)}
              />
            </div>
            {failureRedirectError && <p className="error-message">{failureRedirectError}</p>}
            <button className="submit-btn" onClick={handleSubmitFailureRedirectChange}>
              Update Failure Redirect URL
            </button>
          </div>
        </div>
      )}

      {/* Bank Details Modal */}
      {showBankDetailsModal && (
        <div className="modal">
          <div className="modal-content">
            <FaTimes className="close-icon" onClick={handleCloseBankDetailsModal} />
            <h3>Edit Bank Details</h3>
            <div className="form-group">
              <label htmlFor="bankName">Bank Name:</label>
              <input
                type="text"
                id="bankName"
                value={newBankDetails.bankName}
                onChange={(e) =>
                  setNewBankDetails((prev) => ({ ...prev, bankName: e.target.value }))
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="accountNumber">Account Number:</label>
              <input
                type="text"
                id="accountNumber"
                value={newBankDetails.accountNumber}
                onChange={(e) =>
                  setNewBankDetails((prev) => ({ ...prev, accountNumber: e.target.value }))
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="ifscCode">IFSC Code:</label>
              <input
                type="text"
                id="ifscCode"
                value={newBankDetails.ifscCode}
                onChange={(e) =>
                  setNewBankDetails((prev) => ({ ...prev, ifscCode: e.target.value }))
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="accountName">Account Name:</label>
              <input
                type="text"
                id="accountName"
                value={newBankDetails.accountName}
                onChange={(e) =>
                  setNewBankDetails((prev) => ({ ...prev, accountName: e.target.value }))
                }
              />
            </div>
            {bankDetailsError && <p className="error-message">{bankDetailsError}</p>}
            <button className="submit-btn" onClick={handleSubmitBankDetailsChange}>
              Update Bank Details
            </button>
          </div>
        </div>
      )}

      {/* Wallet Address Modal */}
      {showWalletAddressModal && (
        <div className="modal">
          <div className="modal-content">
            <FaTimes className="close-icon" onClick={handleCloseWalletAddressModal} />
            <h3>Edit Wallet Address</h3>
            <div className="form-group">
              <label htmlFor="newWalletAddress">New Wallet Address:</label>
              <input
                type="text"
                id="newWalletAddress"
                value={newWalletAddress}
                onChange={(e) => setNewWalletAddress(e.target.value)}
              />
            </div>
            {walletAddressError && <p className="error-message">{walletAddressError}</p>}
            <button className="submit-btn" onClick={handleSubmitWalletAddressChange}>
              Update Wallet Address
            </button>
          </div>
        </div>
      )}

      {/* Payout Callback URL Modal */}
      {showPayoutCallbackModal && (
        <div className="modal">
          <div className="modal-content">
            <FaTimes className="close-icon" onClick={handleClosePayoutCallbackModal} />
            <h3>Edit Payout Callback URL</h3>
            <div className="form-group">
              <label htmlFor="newPayoutCallbackURL">New Payout Callback URL:</label>
              <input
                type="text"
                id="newPayoutCallbackURL"
                value={newPayoutCallbackURL}
                onChange={(e) => setNewPayoutCallbackURL(e.target.value)}
              />
            </div>
            {payoutCallbackError && <p className="error-message">{payoutCallbackError}</p>}
            <button className="submit-btn" onClick={handleSubmitPayoutCallbackChange}>
              Update Callback URL
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
