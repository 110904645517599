// import React, { useState, useEffect } from 'react';
// import '../styles/RollingReserve.css'; // or whichever .css file
// import { FiDownload, FiSearch } from 'react-icons/fi';
// import { CSVLink } from 'react-csv';

// // Replace with your actual fetch endpoint 
// // e.g. https://payin.payinfy.com/api/payout-transfer-history/<merchantId>
// const TRANSFER_HISTORY_ENDPOINT = 'https://payin.payinfy.com/api/transfer-history';  

// const TransferHistory = () => {
//   const [transfers, setTransfers] = useState([]);
//   const [filteredTransfers, setFilteredTransfers] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [error, setError] = useState('');

//   const transfersPerPage = 10;
//   const merchantId = localStorage.getItem('merchantId') //|| 'TESTMERCHANT123'; // fallback for testing

//   // On mount, fetch data
//   useEffect(() => {
//     fetchTransferHistory();
//     const intervalId = setInterval(fetchTransferHistory, 5000); // auto refresh
//     return () => clearInterval(intervalId);
//   }, []);

//   // 1. Fetch the transfer history from payin (or your relevant endpoint)
//   const fetchTransferHistory = async () => {
//     try {
//       // Example: GET https://payin.payinfy.com/api/transfer-history/<merchantId>
//       const response = await fetch(`${TRANSFER_HISTORY_ENDPOINT}/${merchantId}/`);
//       const data = await response.json();

//       if (data.status === 'Success') {
//         // Sort by dateTime in descending order or whichever field
//         // The field below is hypothetical; adapt to your actual JSON keys
//         const sorted = data.transfers.sort((a, b) => new Date(b.transfer_date) - new Date(a.transfer_date));
//         setTransfers(sorted);
//         setFilteredTransfers(sorted);
//       } else {
//         console.error('Error fetching transfer history:', data.message);
//       }
//     } catch (error) {
//       console.error('Error fetching transfer history:', error);
//       setError('Could not load transfer history.');
//     }
//   };

//   // 2. Searching & Filtering
//   const handleSearchChange = (e) => {
//     const term = e.target.value;
//     setSearchTerm(term);
//     filterTransfers(term, startDate, endDate);
//   };

//   const handleDateChange = (start, end) => {
//     setStartDate(start);
//     setEndDate(end);
//     filterTransfers(searchTerm, start, end);
//   };

//   const filterTransfers = (term, start, end) => {
//     let filtered = [...transfers];

//     // Simple text match on e.g. amount, status, or anything else
//     if (term) {
//       filtered = filtered.filter((t) =>
//         t.status.toLowerCase().includes(term.toLowerCase()) ||
//         t.amount.toString().includes(term) ||
//         (t.fee_amount && t.fee_amount.toString().includes(term)) ||
//         (t.net_amount && t.net_amount.toString().includes(term))
//       );
//     }

//     if (start && end) {
//       filtered = filtered.filter((t) => {
//         const dt = new Date(t.transfer_date);
//         return dt >= new Date(start) && dt <= new Date(end);
//       });
//     }

//     setFilteredTransfers(filtered);
//     setCurrentPage(1);
//   };

//   // 3. Pagination
//   const indexOfLastTransfer = currentPage * transfersPerPage;
//   const indexOfFirstTransfer = indexOfLastTransfer - transfersPerPage;
//   const currentTransfers = filteredTransfers.slice(indexOfFirstTransfer, indexOfLastTransfer);
//   const totalPages = Math.ceil(filteredTransfers.length / transfersPerPage);

//   const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

//   // CSV Setup
//   const csvHeaders = [
//     { label: 'Date & Time', key: 'transfer_date' },
//     { label: 'Amount', key: 'amount' },
//     { label: 'Fee', key: 'fee_amount' },
//     { label: 'Net Amount', key: 'net_amount' },
//     { label: 'Status', key: 'status' },
//   ];

//   return (
//     <div className="rolling-reserve-content">
//       {/* Header Section */}
//       <header className="rolling-reserve-header">
//         <div className="rolling-reserve-title">
//           <h2>Payin to Payout Transfers</h2>
//         </div>
//         <div className="balance-widgets">
//           {/* If you want to show a special widget, you can do it here */}
//           {/* Or you could show total transferred, etc. */}
//           <div className="balance-widget">
//             <div className="balance-info">
//               <span className="balance-label">Your Transfer History</span>
//             </div>
//           </div>
//           <div className="date-selector">
//             <label htmlFor="start-date" className="visually-hidden">
//               Start Date
//             </label>
//             <input
//               type="date"
//               id="start-date"
//               aria-label="Start Date"
//               value={startDate}
//               onChange={(e) => handleDateChange(e.target.value, endDate)}
//             />
//             <span> to </span>
//             <label htmlFor="end-date" className="visually-hidden">
//               End Date
//             </label>
//             <input
//               type="date"
//               id="end-date"
//               aria-label="End Date"
//               value={endDate}
//               onChange={(e) => handleDateChange(startDate, e.target.value)}
//             />
//           </div>
//         </div>
//       </header>

//       {/* Action Bar */}
//       <div className="rolling-reserve-action-bar">
//         <div className="rolling-reserve-tabs" style={{ visibility: 'hidden' }}></div>

//         {/* Search and Download */}
//         <div className="rolling-reserve-actions">
//           <div className="search-bar">
//             <input
//               type="text"
//               placeholder="Search transfers"
//               value={searchTerm}
//               onChange={handleSearchChange}
//             />
//             <FiSearch className="search-icon" />
//           </div>
//           <CSVLink data={filteredTransfers} headers={csvHeaders} filename="transfer-history.csv">
//             <button className="download-button">
//               <FiDownload className="download-icon" />
//               Download
//             </button>
//           </CSVLink>
//         </div>
//       </div>

//       {/* Transfers Table */}
//       <div className="rolling-reserve-table-container">
//         <div className="rolling-reserve-table-wrapper">
//           <table className="rolling-reserve-table">
//             <thead>
//               <tr>
//                 <th>Date & Time</th>
//                 <th>Amount</th>
//                 <th>Fee</th>
//                 <th>Net</th>
//                 <th>Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentTransfers.length > 0 ? (
//                 currentTransfers.map((transfer, index) => (
//                   <tr key={index}>
//                     <td>
//                       {transfer.transfer_date
//                         ? new Date(transfer.transfer_date).toLocaleString()
//                         : 'N/A'}
//                     </td>
//                     <td>₹{parseFloat(transfer.amount).toFixed(2)}</td>
//                     <td>₹{parseFloat(transfer.fee_amount).toFixed(2)}</td>
//                     <td>₹{parseFloat(transfer.net_amount).toFixed(2)}</td>
//                     <td>
//                       <span className={`status ${transfer.status.toLowerCase()}`}>
//                         {transfer.status}
//                       </span>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="5" className="no-reserves">
//                     <div className="no-reserves-message">
//                       <p>No transfers found.</p>
//                     </div>
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>

//         {/* Pagination */}
//         <div className="pagination">
//           {[...Array(totalPages || 1)].map((_, idx) => (
//             <button
//               key={idx + 1}
//               className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
//               onClick={() => handlePageChange(idx + 1)}
//               disabled={filteredTransfers.length === 0}
//             >
//               {idx + 1}
//             </button>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TransferHistory;




// import React, { useState, useEffect } from 'react';
// import '../styles/RollingReserve.css'; // or whichever .css file
// import { FiDownload, FiSearch } from 'react-icons/fi';
// import { CSVLink } from 'react-csv';

// const TRANSFER_HISTORY_ENDPOINT = 'https://payin.payinfy.com/api/transfer-history'; 
// // (no trailing slash here)

// const TransferHistory = () => {
//   const [transfers, setTransfers] = useState([]);
//   const [filteredTransfers, setFilteredTransfers] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [error, setError] = useState('');

//   const transfersPerPage = 10;
//   const merchantId = localStorage.getItem('merchantId') || 'TESTMERCHANT123'; // fallback for testing

//   useEffect(() => {
//     fetchTransferHistory();
//     const intervalId = setInterval(fetchTransferHistory, 5000); // auto refresh
//     return () => clearInterval(intervalId);
//   }, []);

//   const fetchTransferHistory = async () => {
//     try {
//       // For Django path: transfer-history/<str:merchant_id>/ => need the trailing slash after merchantId
//       const url = `${TRANSFER_HISTORY_ENDPOINT}/${merchantId}/`;
//       const response = await fetch(url);

//       if (!response.ok) {
//         // 4xx or 5xx => read text, likely HTML
//         const text = await response.text();
//         console.error('Server responded with error:', text);
//         setError('Server error loading transfer history.');
//         return;
//       }

//       // Parse JSON if 2xx
//       const data = await response.json();

//       if (data.status === 'Success') {
//         // Sort by date in descending order
//         const sorted = data.transfers.sort(
//           (a, b) => new Date(b.transfer_date) - new Date(a.transfer_date)
//         );
//         setTransfers(sorted);
//         setFilteredTransfers(sorted);
//       } else {
//         console.error('Error fetching transfer history:', data.message);
//         setError(data.message || 'Could not load transfer history.');
//       }
//     } catch (err) {
//       console.error('Error fetching transfer history:', err);
//       setError('Could not load transfer history (network/parse error).');
//     }
//   };

//   // Searching & Filtering
//   const handleSearchChange = (e) => {
//     const term = e.target.value;
//     setSearchTerm(term);
//     filterTransfers(term, startDate, endDate);
//   };

//   const handleDateChange = (start, end) => {
//     setStartDate(start);
//     setEndDate(end);
//     filterTransfers(searchTerm, start, end);
//   };

//   const filterTransfers = (term, start, end) => {
//     let filtered = [...transfers];

//     // Simple text matching on amount, fee, net, or status
//     if (term) {
//       filtered = filtered.filter((t) =>
//         t.status.toLowerCase().includes(term.toLowerCase()) ||
//         t.amount.toString().includes(term) ||
//         (t.fee_amount && t.fee_amount.toString().includes(term)) ||
//         (t.net_amount && t.net_amount.toString().includes(term))
//       );
//     }

//     // Filter by date range
//     if (start && end) {
//       filtered = filtered.filter((t) => {
//         const dt = new Date(t.transfer_date);
//         return dt >= new Date(start) && dt <= new Date(end);
//       });
//     }

//     setFilteredTransfers(filtered);
//     setCurrentPage(1);
//   };

//   // Pagination
//   const indexOfLastTransfer = currentPage * transfersPerPage;
//   const indexOfFirstTransfer = indexOfLastTransfer - transfersPerPage;
//   const currentTransfers = filteredTransfers.slice(indexOfFirstTransfer, indexOfLastTransfer);
//   const totalPages = Math.ceil(filteredTransfers.length / transfersPerPage);

//   const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

//   // CSV Setup
//   const csvHeaders = [
//     { label: 'Date & Time', key: 'transfer_date' },
//     { label: 'Amount', key: 'amount' },
//     { label: 'Fee', key: 'fee_amount' },
//     { label: 'Net Amount', key: 'net_amount' },
//     { label: 'Status', key: 'status' },
//   ];

//   return (
//     <div className="rolling-reserve-content">
//       {/* Header Section */}
//       <header className="rolling-reserve-header">
//         <div className="rolling-reserve-title">
//           <h2>Payin to Payout Transfers</h2>
//         </div>
//         <div className="balance-widgets">
//           {/* Example widget, optional */}
//           {/* <div className="balance-widget">
//             <div className="balance-info">
//               <span className="balance-label">Your Transfer History</span>
//               {error && <p style={{ color: 'red', fontSize: '12px' }}>{error}</p>}
//             </div>
//           </div> */}
//           <div className="date-selector">
//             <label htmlFor="start-date" className="visually-hidden">
//               Start Date
//             </label>
//             <input
//               type="date"
//               id="start-date"
//               aria-label="Start Date"
//               value={startDate}
//               onChange={(e) => handleDateChange(e.target.value, endDate)}
//             />
//             <span> to </span>
//             <label htmlFor="end-date" className="visually-hidden">
//               End Date
//             </label>
//             <input
//               type="date"
//               id="end-date"
//               aria-label="End Date"
//               value={endDate}
//               onChange={(e) => handleDateChange(startDate, e.target.value)}
//             />
//           </div>
//         </div>
//       </header>

//       {/* Action Bar */}
//       <div className="rolling-reserve-action-bar">
//         <div className="rolling-reserve-tabs" style={{ visibility: 'hidden' }}></div>

//         {/* Search and Download */}
//         <div className="rolling-reserve-actions">
//           <div className="search-bar">
//             <input
//               type="text"
//               placeholder="Search transfers"
//               value={searchTerm}
//               onChange={handleSearchChange}
//             />
//             <FiSearch className="search-icon" />
//           </div>
//           <CSVLink data={filteredTransfers} headers={csvHeaders} filename="transfer-history.csv">
//             <button className="download-button">
//               <FiDownload className="download-icon" />
//               Download
//             </button>
//           </CSVLink>
//         </div>
//       </div>

//       {/* Transfers Table */}
//       <div className="rolling-reserve-table-container">
//         <div className="rolling-reserve-table-wrapper">
//           <table className="rolling-reserve-table">
//             <thead>
//               <tr>
//                 <th>Date & Time</th>
//                 <th>Amount</th>
//                 <th>Fee</th>
//                 <th>Net</th>
//                 <th>Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentTransfers.length > 0 ? (
//                 currentTransfers.map((transfer, index) => (
//                   <tr key={index}>
//                     <td>
//                       {transfer.transfer_date
//                         ? new Date(transfer.transfer_date).toLocaleString()
//                         : 'N/A'}
//                     </td>
//                     <td>₹{parseFloat(transfer.amount).toFixed(2)}</td>
//                     <td>₹{parseFloat(transfer.fee_amount).toFixed(2)}</td>
//                     <td>₹{parseFloat(transfer.net_amount).toFixed(2)}</td>
//                     <td>
//                       <span className={`status ${transfer.status.toLowerCase()}`}>
//                         {transfer.status}
//                       </span>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="5" className="no-reserves">
//                     <div className="no-reserves-message">
//                       <p>No transfers found.</p>
//                     </div>
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>

//         {/* Pagination */}
//         <div className="pagination">
//           {[...Array(totalPages || 1)].map((_, idx) => (
//             <button
//               key={idx + 1}
//               className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
//               onClick={() => handlePageChange(idx + 1)}
//               disabled={filteredTransfers.length === 0}
//             >
//               {idx + 1}
//             </button>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TransferHistory;



// import React, { useState, useEffect } from 'react';
// import '../styles/RollingReserve.css';
// import { FiDownload, FiSearch } from 'react-icons/fi';
// import { CSVLink } from 'react-csv';

// const TRANSFER_HISTORY_ENDPOINT = 'https://payin.payinfy.com/api/transfer-history';

// const TransferHistory = () => {
//   const [transfers, setTransfers] = useState([]);
//   const [filteredTransfers, setFilteredTransfers] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [error, setError] = useState('');

//   const transfersPerPage = 10;
//   const merchantId = localStorage.getItem('merchantId') || 'TESTMERCHANT123';

//   useEffect(() => {
//     fetchTransferHistory();
//     const intervalId = setInterval(fetchTransferHistory, 5000);
//     return () => clearInterval(intervalId);
//   }, []);

//   const fetchTransferHistory = async () => {
//     try {
//       const url = `${TRANSFER_HISTORY_ENDPOINT}/${merchantId}/`;
//       const response = await fetch(url);

//       if (!response.ok) {
//         const text = await response.text();
//         console.error('Server responded with error:', text);
//         setError('Server error loading transfer history.');
//         return;
//       }

//       const data = await response.json();
//       if (data.status === 'Success') {
//         const sorted = data.transfers.sort(
//           (a, b) => new Date(b.transfer_date) - new Date(a.transfer_date)
//         );
//         setTransfers(sorted);
//         setFilteredTransfers(sorted);
//       } else {
//         console.error('Error fetching transfer history:', data.message);
//         setError(data.message || 'Could not load transfer history.');
//       }
//     } catch (err) {
//       console.error('Error fetching transfer history:', err);
//       setError('Could not load transfer history (network/parse error).');
//     }
//   };

//   const handleSearchChange = (e) => {
//     const term = e.target.value;
//     setSearchTerm(term);
//     filterTransfers(term, startDate, endDate);
//   };

//   const handleDateChange = (start, end) => {
//     setStartDate(start);
//     setEndDate(end);
//     filterTransfers(searchTerm, start, end);
//   };

//   const filterTransfers = (term, start, end) => {
//     let filtered = [...transfers];

//     if (term) {
//       filtered = filtered.filter((t) =>
//         t.status.toLowerCase().includes(term.toLowerCase()) ||
//         t.amount.toString().includes(term) ||
//         (t.fee_amount && t.fee_amount.toString().includes(term)) ||
//         (t.net_amount && t.net_amount.toString().includes(term))
//       );
//     }

//     if (start && end) {
//       filtered = filtered.filter((t) => {
//         const dt = new Date(t.transfer_date);
//         return dt >= new Date(start) && dt <= new Date(end);
//       });
//     }

//     setFilteredTransfers(filtered);
//     setCurrentPage(1);
//   };

//   const indexOfLastTransfer = currentPage * transfersPerPage;
//   const indexOfFirstTransfer = indexOfLastTransfer - transfersPerPage;
//   const currentTransfers = filteredTransfers.slice(indexOfFirstTransfer, indexOfLastTransfer);
//   const totalPages = Math.ceil(filteredTransfers.length / transfersPerPage);

//   const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

//   const csvHeaders = [
//     { label: 'Date & Time', key: 'transfer_date' },
//     { label: 'Amount', key: 'amount' },
//     { label: 'Fee', key: 'fee_amount' },
//     { label: 'Net Amount', key: 'net_amount' },
//     { label: 'Status', key: 'status' },
//   ];

//   const renderStatus = (status) => {
//     if (!status) return 'N/A';
//     // You can customize if you have "APPROVED" or "SUCCESS"
//     // but let's handle the statuses we might see
//     switch (status.toUpperCase()) {
//       case 'PENDING':
//         return <span className="status pending">Pending</span>;
//       case 'APPROVED':
//         return <span className="status approved">Approved</span>;
//       case 'FAILED':
//         return <span className="status failed">Failed</span>;
//       default:
//         return status; // or <span>{status}</span>
//     }
//   };

//   return (
//     <div className="rolling-reserve-content">
//       <header className="rolling-reserve-header">
//         <div className="rolling-reserve-title">
//           <h2>Payin to Payout Transfers</h2>
//         </div>
//         <div className="balance-widgets">
//           <div className="date-selector">
//             <label htmlFor="start-date" className="visually-hidden">
//               Start Date
//             </label>
//             <input
//               type="date"
//               id="start-date"
//               aria-label="Start Date"
//               value={startDate}
//               onChange={(e) => handleDateChange(e.target.value, endDate)}
//             />
//             <span> to </span>
//             <label htmlFor="end-date" className="visually-hidden">
//               End Date
//             </label>
//             <input
//               type="date"
//               id="end-date"
//               aria-label="End Date"
//               value={endDate}
//               onChange={(e) => handleDateChange(startDate, e.target.value)}
//             />
//           </div>
//         </div>
//       </header>

//       <div className="rolling-reserve-action-bar">
//         <div className="rolling-reserve-tabs" style={{ visibility: 'hidden' }}></div>
//         <div className="rolling-reserve-actions">
//           <div className="search-bar">
//             <input
//               type="text"
//               placeholder="Search transfers"
//               value={searchTerm}
//               onChange={handleSearchChange}
//             />
//             <FiSearch className="search-icon" />
//           </div>
//           <CSVLink data={filteredTransfers} headers={csvHeaders} filename="transfer-history.csv">
//             <button className="download-button">
//               <FiDownload className="download-icon" />
//               Download
//             </button>
//           </CSVLink>
//         </div>
//       </div>

//       <div className="rolling-reserve-table-container">
//         <div className="rolling-reserve-table-wrapper">
//           <table className="rolling-reserve-table">
//             <thead>
//               <tr>
//                 <th>Date & Time</th>
//                 <th>Amount</th>
//                 <th>Fee</th>
//                 <th>Net</th>
//                 <th>Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentTransfers.length > 0 ? (
//                 currentTransfers.map((transfer, index) => (
//                   <tr key={index}>
//                     <td>
//                       {transfer.transfer_date
//                         ? new Date(transfer.transfer_date).toLocaleString()
//                         : 'N/A'}
//                     </td>
//                     <td>₹{parseFloat(transfer.amount).toFixed(2)}</td>
//                     <td>₹{parseFloat(transfer.fee_amount).toFixed(2)}</td>
//                     <td>₹{parseFloat(transfer.net_amount).toFixed(2)}</td>
//                     <td>{renderStatus(transfer.status)}</td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="5" className="no-reserves">
//                     <div className="no-reserves-message">
//                       <p>No transfers found.</p>
//                     </div>
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>

//         <div className="pagination">
//           {[...Array(totalPages || 1)].map((_, idx) => (
//             <button
//               key={idx + 1}
//               className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
//               onClick={() => handlePageChange(idx + 1)}
//               disabled={filteredTransfers.length === 0}
//             >
//               {idx + 1}
//             </button>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TransferHistory;



import React, { useState, useEffect } from 'react';
import '../styles/RollingReserve.css'; // or whichever .css
import { FiDownload, FiSearch } from 'react-icons/fi';
import { CSVLink } from 'react-csv';

const TRANSFER_HISTORY_ENDPOINT = 'https://payin.payinfy.com/api/transfer-history';

const TransferHistory = () => {
  const [transfers, setTransfers] = useState([]);
  const [filteredTransfers, setFilteredTransfers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState('');

  const transfersPerPage = 10;
  const merchantId = localStorage.getItem('merchantId') || 'TESTMERCHANT123';

  useEffect(() => {
    fetchTransferHistory();
    const intervalId = setInterval(fetchTransferHistory, 5000); // auto-refresh every 5s
    return () => clearInterval(intervalId);
  }, []);

  const fetchTransferHistory = async () => {
    try {
      const url = `${TRANSFER_HISTORY_ENDPOINT}/${merchantId}/`;
      const response = await fetch(url);

      if (!response.ok) {
        const text = await response.text();
        console.error('Server responded with error:', text);
        setError('Server error loading transfer history.');
        return;
      }

      const data = await response.json();
      if (data.status === 'Success') {
        // Sort descending by date
        const sorted = data.transfers.sort(
          (a, b) => new Date(b.transfer_date) - new Date(a.transfer_date)
        );
        setTransfers(sorted);
        setFilteredTransfers(sorted);
      } else {
        console.error('Error fetching transfer history:', data.message);
        setError(data.message || 'Could not load transfer history.');
      }
    } catch (err) {
      console.error('Error fetching transfer history:', err);
      setError('Could not load transfer history (network/parse error).');
    }
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    filterTransfers(term, startDate, endDate);
  };

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    filterTransfers(searchTerm, start, end);
  };

  const filterTransfers = (term, start, end) => {
    let filtered = [...transfers];

    // Text matching
    if (term) {
      filtered = filtered.filter((t) =>
        t.status.toLowerCase().includes(term.toLowerCase()) ||
        t.amount.toString().includes(term) ||
        t.fee_amount.toString().includes(term) ||
        t.net_amount.toString().includes(term)
      );
    }

    // Date range
    if (start && end) {
      filtered = filtered.filter((t) => {
        const dt = new Date(t.transfer_date);
        return dt >= new Date(start) && dt <= new Date(end);
      });
    }

    setFilteredTransfers(filtered);
    setCurrentPage(1);
  };

  const indexOfLastTransfer = currentPage * transfersPerPage;
  const indexOfFirstTransfer = indexOfLastTransfer - transfersPerPage;
  const currentTransfers = filteredTransfers.slice(indexOfFirstTransfer, indexOfLastTransfer);
  const totalPages = Math.ceil(filteredTransfers.length / transfersPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const csvHeaders = [
    { label: 'Date & Time', key: 'transfer_date' },
    { label: 'Amount', key: 'amount' },
    { label: 'Fee', key: 'fee_amount' },
    { label: 'Net Amount', key: 'net_amount' },
    { label: 'Status', key: 'status' },
  ];

  const renderStatus = (status) => {
    if (!status) return 'N/A';
    const normalized = status.toUpperCase();

    if (normalized === 'PENDING') {
      return <span className="status pending">Pending</span>;
    } else if (normalized === 'APPROVED') {
      // Show green (similar to "SUCCESS")
      return <span className="status success">Approved</span>;
    } else if (normalized === 'FAILED') {
      return <span className="status failed">Failed</span>;
    }
    return status; // fallback
  };

  return (
    <div className="rolling-reserve-content">
      <header className="rolling-reserve-header">
        <div className="rolling-reserve-title">
          <h2>Payin to Payout Transfers</h2>
        </div>
        <div className="balance-widgets">
          <div className="date-selector">
            <label htmlFor="start-date" className="visually-hidden">Start Date</label>
            <input
              type="date"
              id="start-date"
              aria-label="Start Date"
              value={startDate}
              onChange={(e) => handleDateChange(e.target.value, endDate)}
            />
            <span> to </span>
            <label htmlFor="end-date" className="visually-hidden">End Date</label>
            <input
              type="date"
              id="end-date"
              aria-label="End Date"
              value={endDate}
              onChange={(e) => handleDateChange(startDate, e.target.value)}
            />
          </div>
        </div>
      </header>

      <div className="rolling-reserve-action-bar">
        <div className="rolling-reserve-tabs" style={{ visibility: 'hidden' }}></div>
        <div className="rolling-reserve-actions">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search transfers"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <FiSearch className="search-icon" />
          </div>
          <CSVLink data={filteredTransfers} headers={csvHeaders} filename="transfer-history.csv">
            <button className="download-button">
              <FiDownload className="download-icon" />
              Download
            </button>
          </CSVLink>
        </div>
      </div>

      <div className="rolling-reserve-table-container">
        <div className="rolling-reserve-table-wrapper">
          <table className="rolling-reserve-table">
            <thead>
              <tr>
                <th>Date & Time</th>
                <th>Amount</th>
                <th>Fee</th>
                <th>Net</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {currentTransfers.length > 0 ? (
                currentTransfers.map((transfer, index) => (
                  <tr key={index}>
                    <td>
                      {transfer.transfer_date
                        ? new Date(transfer.transfer_date).toLocaleString()
                        : 'N/A'}
                    </td>
                    <td>₹{parseFloat(transfer.amount).toFixed(2)}</td>
                    <td>₹{parseFloat(transfer.fee_amount).toFixed(2)}</td>
                    <td>₹{parseFloat(transfer.net_amount).toFixed(2)}</td>
                    <td>{renderStatus(transfer.status)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="no-reserves">
                    <div className="no-reserves-message">
                      <p>No transfers found.</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="pagination">
          {[...Array(totalPages || 1)].map((_, idx) => (
            <button
              key={idx + 1}
              className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
              onClick={() => handlePageChange(idx + 1)}
              disabled={filteredTransfers.length === 0}
            >
              {idx + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TransferHistory;
